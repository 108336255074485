import { GraphQLClient } from 'graphql-request';
import React from 'react';

export const API_URL =
  process.env.REACT_APP_API_URL || 'http://localhost:8000/graphql';

const RequestContext = React.createContext({
  client: new GraphQLClient(API_URL),
  auth: null,
  setAuth: () => null,
});

export const RequestContextProvider = (props) => {
  const [auth, setAuth] = React.useState(
    JSON.parse(window.sessionStorage.getItem('auth'))
  );
  React.useEffect(() => {
    window.sessionStorage.setItem('auth', JSON.stringify(auth));
  }, [auth]);

  const client = React.useMemo(
    () =>
      new GraphQLClient(API_URL, {
        headers: { authorization: auth?.token && `jwt ${auth.token}` },
      }),
    [auth?.token]
  );

  return (
    <RequestContext.Provider value={{ client, auth, setAuth }} {...props} />
  );
};

export default RequestContext;
