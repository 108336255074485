/**
 * Bottom Navigation
 */
import {
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  Link,
} from '@material-ui/core';
import BrushIcon from '@material-ui/icons/Brush';
import EventIcon from '@material-ui/icons/Event';
import FolderIcon from '@material-ui/icons/Folder';
import LanguageIcon from '@material-ui/icons/Language';
import MailIcon from '@material-ui/icons/Mail';
import useProperty from 'api/useProperty';
import React from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import routes from '../../../../../routes';

const Navigation = ({ unreads }) => {
  const history = useHistory();
  const { slug, entity } = useParams();
  const { data } = useProperty(slug);
  return (
    <BottomNavigation showLabels onChange={(evt, val) => history.push(val)}>
      <BottomNavigationAction
        component={Link}
        value={generatePath(routes.entity.inboxList, {
          entity: entity,
          slug: slug,
          inboxType: 'messages',
        })}
        label="Messages"
        icon={
          <Badge badgeContent={unreads} color="secondary">
            <MailIcon />
          </Badge>
        }
        style={{ minWidth: 60, paddingLeft: 0, paddingRight: 0 }}
      />
      <BottomNavigationAction
        component={Link}
        value={generatePath(routes.entity.eventList, {
          entity: entity,
          slug: slug,
          inboxType: 'events',
        })}
        label="Events"
        icon={<EventIcon />}
        style={{ minWidth: 60, paddingLeft: 0, paddingRight: 0 }}
      />
      <BottomNavigationAction
        component={Link}
        value={generatePath(routes.entity.pdfs, {
          entity: entity,
          slug: slug,
          inboxType: 'documents',
        })}
        label="Files"
        icon={<FolderIcon />}
        style={{ minWidth: 60, paddingLeft: 0, paddingRight: 0 }}
      />

      {data?.projectVirtualStudio && (
        <BottomNavigationAction
          component={Link}
          href={data?.interactiveAbodeUrl}
          label="Virtual Design Studio"
          icon={<BrushIcon />}
          style={{ minWidth: 60, paddingLeft: 0, paddingRight: 0 }}
        />
      )}

      <BottomNavigationAction
        component={Link}
        href="https://www.tarion.com/"
        label="Tarion"
        icon={<LanguageIcon />}
        style={{ minWidth: 60, paddingLeft: 0, paddingRight: 0 }}
      />
    </BottomNavigation>
  );
};

export default Navigation;
