import RequestContext from 'contexts/request';
import { gql } from 'graphql-request';
import { useContext } from 'react';
import { useMutation } from 'react-query';

const MUTATION = gql`
  mutation (
    $projectSlug: String!
    $eventId: String!
    $start: String!
    $end: String!
    $summary: String
    $description: String
    $location: String
    $attendees: [String]
  ) {
    updateGoogleCalendarEvent(
      projectSlug: $projectSlug
      eventId: $eventId
      start: $start
      end: $end
      summary: $summary
      description: $description
      location: $location
      attendees: $attendees
    ) {
      success
    }
  }
`;

const requestUpdateGoogleCalendarEvent = (client) => async (variables) => {
  const data = await client.request(MUTATION, variables);
  return data.success;
};

const useUpdateGoogleCalendarEvent = () => {
  const { client } = useContext(RequestContext);
  const { mutate } = useMutation(requestUpdateGoogleCalendarEvent(client));
  return mutate;
};

export default useUpdateGoogleCalendarEvent;
