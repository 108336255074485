/**
 * Inbox Emails
 */
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import useProperty from 'api/useProperty';
import SelectedPropertyContext from 'contexts/selectedProperty';
import React, { useContext } from 'react';
import ReactPlayer from 'react-player';
import { Route, Switch } from 'react-router-dom';
// Components
import Listing from './videoListing';

const EmailPreview = (props) => {
  const [slug] = useContext(SelectedPropertyContext);
  const property = useProperty(slug);
  const documents = property?.documents || [];
  const video = documents.find((d) => d.id === props?.match?.params?.id);
  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Listing />
      </Grid>
      <Grid item xs={12} md={6}>
        <VideoPlayer url={video?.url} />
      </Grid>
    </Grid>
  );
};

const VideoPlayer = ({ url }) => (
  <Box
    style={{
      paddingLeft: '5%',
      height: '100vh',
      paddingTop: '30vh',
      backgroundColor: '#f1f5f9',
    }}
  >
    <div className="player-wrapper">
      <ReactPlayer
        url={url}
        className="react-player"
        width="90%"
        height="320px"
        controls={true}
      />
    </div>
  </Box>
);

function Inbox(props) {
  const { match } = props;
  return (
    <Switch>
      <Route exact path={match.url}>
        <Listing type="VIDEO" />
      </Route>
      <Route path={`${match.url}/:id`} component={EmailPreview} />
    </Switch>
  );
}

export default Inbox;
