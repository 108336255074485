import RequestContext from 'contexts/request';
import { gql } from 'graphql-request';
import { useContext } from 'react';
import { useMutation } from 'react-query';

const MUTATION = gql`
  mutation ($firstName: String!, $lastName: String!, $phone: String) {
    updateUser(firstName: $firstName, lastName: $lastName, phone: $phone) {
      success
    }
  }
`;

const requestUpdateUser = (client) => async (variables) => {
  const data = await client.request(MUTATION, variables);
  return data.succeses;
};

const useUpdateUser = () => {
  const { client } = useContext(RequestContext);
  const { mutate } = useMutation(requestUpdateUser(client));
  return mutate;
};

export default useUpdateUser;
