import {
  Box,
  Button,
  Container,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useUser from 'api/useUser';
import SelectedPropertyContext from 'contexts/selectedProperty';
import React, { useContext, useState } from 'react';
import {
  generatePath,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import routes from '../../routes';
// components
import TypeSelector from './components/SelectType';
import Composer from './Composer';
import Confirmer from './Confirmer';
import Sender from './Sender';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Compose', 'Confirm', 'Send'];
}
export function HorizontalLinearStepper() {
  const [slug] = useContext(SelectedPropertyContext);
  const history = useHistory();
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const steps = getSteps();
  const [formData, setFormData] = useState({});

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  const { fileType } = useParams();

  const user = useUser();
  const typeToPath = (type) =>
    ({
      message: generatePath(routes.composer.uploads, {
        fileType: 'messages',
      }),
      photo: generatePath(routes.composer.uploads, {
        fileType: 'photos',
      }),
      video: generatePath(routes.composer.uploads, {
        fileType: 'videos',
      }),
      file: generatePath(routes.composer.uploads, {
        fileType: 'files',
      }),
      events: generatePath(routes.composer.uploads, {
        fileType: 'events',
      }),
      events: generatePath(routes.composer.uploads, {
        fileType: 'rsvps',
      }),
    }[type]);

  const handleComposeNext = (data) => {
    setFormData(data);
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleConfirmNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleSenderNext = () => {
    const goto = generatePath(routes.entity.inboxList, {
      entity: 'properties',
      slug: user?.properties?.[0]?.slug,
      inboxType: 'messages',
    });
    history.push(goto);
  };

  const handleBack = () => {
    const path = typeToPath(fileType);
    if (activeStep === 0) {
      history.push(path);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box px={{ xs: '12px', lg: 0 }}>
      <Grid justify="center" alignItems="center" container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Container maxWidth="lg">
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Container>
        </Grid>
      </Grid>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </div>
        ) : (
          <Container maxWidth="lg">
            <Box className="stepper-wrapper">
              {activeStep === 0 && (
                <div
                  className={`stepper-box ${
                    isMobile ? 'stepper-box-mobile' : ''
                  }`}
                >
                  <div className="stepper-inner">
                    <Grid container>
                      <Grid item xs={12}>
                        <Composer
                          data={formData}
                          onNext={handleComposeNext}
                          onBack={handleBack}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
              )}
              {activeStep === 1 && (
                <div
                  className={`stepper-box ${
                    isMobile ? 'stepper-box-mobile' : ''
                  }`}
                >
                  <div className="stepper-inner">
                    <Confirmer
                      data={formData}
                      onNext={handleConfirmNext}
                      onBack={handleBack}
                    />
                  </div>
                </div>
              )}
              {activeStep === 2 && (
                <div
                  className={`stepper-box ${
                    isMobile ? 'stepper-box-mobile' : ''
                  }`}
                >
                  <div className="stepper-inner last-level">
                    <Sender data={formData} onNext={handleSenderNext} />
                  </div>
                </div>
              )}
            </Box>
          </Container>
        )}
      </div>
    </Box>
  );
}

const ComposeMessage = () => {
  const match = useRouteMatch();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div className={classes.root}>
      <div className="page-space horizontal-stepper">
        <Container disableGutters={isMobile}>
          <Switch>
            <Route exact path={routes.composer.uploads}>
              <HorizontalLinearStepper />
            </Route>
            <Route exact path={routes.composer.base}>
              <TypeSelector />
            </Route>
          </Switch>
        </Container>
      </div>
    </div>
  );
};

export default ComposeMessage;
