import RequestContext from 'contexts/request';
import { gql } from 'graphql-request';
import { useContext } from 'react';
import { useMutation } from 'react-query';

const CALENDLY_MUTATION = gql`
  mutation ($properties: [String]!, $calendlyUrl: String!, $message: String) {
    createCalendlyInvite(
      properties: $properties
      calendlyUrl: $calendlyUrl
      message: $message
    ) {
      success
    }
  }
`;

const requestCreateCalendlyEvent = (client) => async (variables) => {
  const data = await client.request(CALENDLY_MUTATION, variables);
  return data.succeses;
};

const useCreateCalendlyEvent = () => {
  const { client } = useContext(RequestContext);
  const { mutate } = useMutation(requestCreateCalendlyEvent(client));
  return mutate;
};

export default useCreateCalendlyEvent;
