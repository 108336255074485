/**
 * Email Page
 */
import { Box, Drawer, Hidden, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import React, { useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Messages from './components/email';
import Documents from './components/pdfs';
import Photos from './components/photos';
// new structure
import EmailSidebar from './components/sidebar';
import Videos from './components/videos';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 'auto',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%',
  },
  drawer: {
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  menuButton: {
    marginLeft: '7px  !important',
    position: 'absolute',
    top: 25,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  drawerPaper: {
    width: drawerWidth,
    borderRight: 0,
    [theme.breakpoints.up('md')]: {
      position: 'relative',
    },
  },
  content: {
    flexGrow: 1,
    borderLeft: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
  },
  contentWrap: {
    position: 'relative',
  },
}));

const drawer = (
  <div>
    <EmailSidebar />
  </div>
);

function Files(props) {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const { match } = props;
  return (
    <Box className="hk-mail-wrapper">
      <div className={classes.root}>
        <nav className={classes.drawer} aria-label="mailbox folders">
          <Hidden mdUp implementation="css">
            <Drawer
              variant="temporary"
              anchor="left"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <div>
                <EmailSidebar />
              </div>
            </Drawer>
          </Hidden>
          <Hidden smDown implementation="css">
            <Drawer
              variant="permanent"
              open
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <Box height="100%" className={classes.contentWrap}>
            <IconButton
              size="small"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={`email-btn ${classes.menuButton}`}
            >
              <SettingsIcon />
            </IconButton>
            <div className="overview-section">
              <Switch>
                <Route
                  path={[
                    `/properties/${match?.params?.slug}/files/messages`,
                    `/projects/${match?.params?.slug}/files/messages`,
                  ]}
                  component={Messages}
                />
                <Route
                  path={`/properties/${match?.params?.slug}/files/videos`}
                  component={Videos}
                />
                <Route
                  path={`/properties/${match?.params?.slug}/files/photos`}
                  component={Photos}
                />
                <Route
                  path={`/properties/${match?.params?.slug}/files/documents`}
                  component={Documents}
                />
                <Route exact path={`/properties/${match?.params?.slug}/`}>
                  <Redirect
                    to={`/properties/${match?.params?.slug}/messages`}
                  />
                </Route>
              </Switch>
            </div>
          </Box>
        </main>
      </div>
    </Box>
  );
}

export default Files;
