/**
 * Inbox Emails
 */
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import useProperty from 'api/useProperty';
import SelectedPropertyContext from 'contexts/selectedProperty';
import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
// Components
import EmailListing from './photoListing';

const EmailPreview = (props) => (
  <Grid container>
    <Grid item xs={12} md={6}>
      <EmailListing />
    </Grid>
    <Grid item xs={12} md={6}>
      <ImagePreview {...props} />
    </Grid>
  </Grid>
);

const ImagePreview = ({ match }) => {
  const [slug] = useContext(SelectedPropertyContext);
  const property = useProperty(slug);
  const photos = property?.gallery || [];
  const photo = photos.find((p) => p.id === match.params.id);
  return (
    <Box
      style={{
        paddingLeft: '5%',
        height: '100vh',
        paddingTop: '30vh',
        backgroundColor: '#f1f5f9',
      }}
    >
      {photo && <img width="90%" alt="preview" src={photo.url} />}
    </Box>
  );
};

function Inbox(props) {
  const { match } = props;
  return (
    <Switch>
      <Route exact path={match.url} component={EmailListing} />
      <Route path={`${match.url}/:id`} component={EmailPreview} />
    </Switch>
  );
}

export default Inbox;
