import { Box, Divider, Typography } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import useDocuments from 'api/useDocuments';
import 'moment-timezone';
import React from 'react';
import { AiOutlineCloudDownload } from 'react-icons/ai';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import { FiEye } from 'react-icons/fi';
import Moment from 'react-moment';
import { generatePath } from 'react-router';
import { Link, useHistory, useParams } from 'react-router-dom';
import routes from '../../../routes';

const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiDataGrid-row': {
      border: '1px solid lightgrey',
    },
  },
}));

const PdfTable = ({ type }) => {
  const { slug, entity } = useParams();
  const history = useHistory();
  const classes = useStyles();

  const { data, isLoading, isError } = useDocuments(
    'documents',
    entity === 'projects' ? slug : null,
    entity === 'properties' ? slug : null
  );

  const processedData = data?.flatMap((msg) =>
    msg.allFiles.map((file) => ({
      id: file.uuid,
      mimeType: file.mimeType,
      name: file.originalFilename || 'No filename',
      secureUrl: file.secureUrl,
      downloadUrl: file.downloadUrl,
      msg: msg,
    }))
  );

  const columns = [
    { field: 'id', headerName: 'ID', hide: true },
    {
      flex: 2,
      field: 'msg.lotNumber',
      headerName: `#`,
      renderCell: (params) => {
        return (
          <Box display="flex" flexDirection="column">
            <Typography
              variant="subtitle1"
              style={!params.row.msg.readAt ? { color: blue[600] } : ''}
            >
              {params.row.msg.lotNumber}
            </Typography>
          </Box>
        );
      },
    },
    {
      flex: 4,
      field: 'name',
      headerName: 'Filename',
      renderCell: (params) => {
        return (
          <Box display="flex">
            <Box mb={1} display="flex">
              <Typography variant="subtitle1">{params.row.name}</Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      flex: 2,
      field: 'downloadUrl',
      headerName: 'Actions',
      renderCell: (params) => {
        return (
          <Box display="flex">
            <Box m={1}>
              <a href={params.row.secureUrl} target="blank">
                <FiEye size="2em" />
              </a>
            </Box>
            <Box m={1}>
              <a href={params.row.downloadUrl} target="blank">
                <AiOutlineCloudDownload size="2em" />
              </a>
            </Box>
          </Box>
        );
      },
    },
    {
      flex: 2,
      field: 'sentAt',
      headerName: 'Sent At',
      renderCell: (params) => {
        return (
          <Box display="flex" flexDirection="column">
            <Box mb={1}>
              <Typography variant="subtitle1">
                <Moment
                  style={!params.row.msg.readAt ? { color: blue[600] } : ''}
                  date={params.row.msg.sentAt || params.row.msg.created}
                  format="MMM DD YYYY"
                />
              </Typography>
            </Box>
            <Typography variant="caption">
              <Moment
                style={!params.row.msg.readAt ? { color: blue[600] } : ''}
                date={params.row.msg.sentAt || params.row.msg.created}
                format="hh:mm A"
                style={{ fontWeight: 500, color: 'grey' }}
              />
            </Typography>
          </Box>
        );
      },
    },
    {
      flex: 2,
      field: 'msg',
      headerName: 'Go To Message',
      renderCell: (params) => {
        const goto = generatePath(routes.entity.inboxDetail, {
          entity: entity,
          slug: slug,
          inboxType: 'messages',
          id: params.row.msg.id,
        });

        return (
          <Box mb={1}>
            <Link to={goto}>
              <BsBoxArrowUpRight size={'1.3em'} />
            </Link>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <Box m={2} ml={0}>
        <Box m={2}>
          <Typography variant="h1" style={{ textTransform: 'uppercase' }}>
            Documents
          </Typography>
        </Box>
        <Divider />
        <Box my={2} display="flex">
          {!isLoading && (
            <DataGrid
              className={classes.table}
              rows={processedData}
              columns={columns}
              autoPageSize
              rowHeight={60}
              autoHeight
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default PdfTable;
