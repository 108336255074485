import RequestContext from 'contexts/request';
import { gql } from 'graphql-request';
import { useContext } from 'react';
import { useQuery } from 'react-query';

const QUERY = gql`
  query ($projectSlug: String, $propertySlug: String) {
    allMessages(projectSlug: $projectSlug, propertySlug: $propertySlug) {
      id
      lotNumber
      block
      subject
      cc
      bcc
      body
      cleanedBody
      bodyColumn
      rootId
      sender {
        id
        email
        name
      }
      sentAt
      created
      modified
      readAt
      parent {
        id
      }
      staff {
        id
      }
      latestMessage {
        subject
        body
        created
        sentAt
        readAt
        modified
        bodyColumn
        sender {
          email
          name
        }
      }
    }
  }
`;

const requestMessages = (client) => (variables) => async () => {
  const { allMessages } = await client.request(QUERY, variables);
  return allMessages;
};

const useMessages = (
  projectSlug = null,
  propertySlug = null,
  shouldFetch = true
) => {
  const { client } = useContext(RequestContext);
  return useQuery(
    ['allMessages', projectSlug, propertySlug],
    requestMessages(client)({ projectSlug, propertySlug }),
    {
      enabled: shouldFetch,
    }
  );
};

export default useMessages;
