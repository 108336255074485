/**
 * Main APP
 */
// Auth0
import { Auth0Provider } from '@auth0/auth0-react';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { RequestContextProvider } from 'contexts/request';
import { SelectedPropertyProvider } from 'contexts/selectedProperty';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
// App Conatiner
import App from './container/App';
// Firebase
// import './firebase';
// Hulk CSS
import './lib/hulkCss';
// Store
import { store } from './store';

// configureFakeBackend();
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// GraphQL
const queryClient = new QueryClient();

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID || 'od3ykwhd';

function MainApp() {
  return (
    <Auth0Provider
      domain="brickbybrick.us.auth0.com"
      clientId="0OJUgNEVUwPuZy2heDlU4clWVF1ybJjq"
      redirectUri={window.location.origin}
    >
      <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Router>
                <RequestContextProvider>
                  <SelectedPropertyProvider>
                    <Switch>
                      <Route path="/" component={App} />
                    </Switch>
                  </SelectedPropertyProvider>
                </RequestContextProvider>
              </Router>
            </MuiPickersUtilsProvider>
          </Provider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </IntercomProvider>
    </Auth0Provider>
  );
}

export default MainApp;
