import { combineReducers } from 'redux';
import AppSettingsReducer from './AppSettingsReducer';
import AuthUserReducer from './AuthUserReducer';
import ChatAppReducer from './ChatAppReducer';
import ContactReducer from './ContactReducer';
import EcommerceReducer from './EcommerceReducer';
import EmailAppReducer from './EmailAppReducer';
import MenuListReducer from './MenuListReducer';
import UserSettingsReducer from './UserSettingsReducer';

const reducers = combineReducers({
  authUser: AuthUserReducer,
  chatAppReducer: ChatAppReducer,
  settings: AppSettingsReducer,
  ecommerce: EcommerceReducer,
  emailApp: EmailAppReducer,
  ContactReducer,
  UserSettingsReducer,
  menuListReducer: MenuListReducer,
});

export default reducers;
