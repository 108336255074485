/**
 * Email Detail
 */
import {
  Box,
  Divider,
  Fade,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';
import useArchiveMessage from 'api/useArchiveMessage';
import useMarkAsRead from 'api/useMarkAsRead';
import useMessageThread from 'api/useMessageThread';
import useReplyToMessage from 'api/useReplyToMessage';
import useUser from 'api/useUser';
// Global Component
import { CustomCard } from 'components/GlobalComponents';
import ChipInput from 'material-ui-chip-input';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { generatePath, Link, useParams } from 'react-router-dom';
import SanitizedHTML from 'react-sanitized-html';
import RingLoader from 'react-spinners/RingLoader';
import routes from 'routes';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    '@media (max-width:1560px)': {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
  },
  editor: {
    '& >div': {
      '&:after, &:before': {
        display: 'none',
      },
    },
  },
  pad0: {
    padding: 0,
  },
  pad10: {
    padding: 10,
  },
  sendBtn: {
    border: '3px solid',
    borderColor: theme.palette.primary.main,
    padding: 10,
  },
  authorThumb: {
    width: 90,
    '@media (max-width:1560px)': {
      width: 70,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  authorDesc: {
    width: 'calc(100%)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

const EmailDetail = () => {
  const { entity, slug: routeSlug, inboxType, id } = useParams();
  const classes = useStyles();
  const [, setTouched] = useState({});
  const [errors] = useState({});

  const { data, refetch, isFetching, isLoading } = useMessageThread(id);
  const unsortedMessages = data || [];
  const messages = unsortedMessages.sort(
    (a, b) => new Date(a.created) - new Date(b.created)
  );
  const latest = messages?.slice(-1)?.[0];

  const markAsRead = useMarkAsRead();

  const replyToMessage = useReplyToMessage();

  const user = useUser();

  const [sending, setSending] = useState(false);
  const [reply, setReply] = useState('');
  const [CC, setCC] = useState([]);
  const [BCC, setBCC] = useState([]);
  const sendReply = () => {
    if (messages?.length > 0 && reply) {
      setSending(true);
      replyToMessage(
        { body: reply, replyTo: latest.id, cc: CC, bcc: BCC },
        {
          onSettled: () => {
            setReply('');
            setSending(false);
            refetch();
          },
        }
      );
    }
  };

  const { mutate } = useArchiveMessage();

  useEffect(() => {
    const unread = messages.filter((m) => !m.readAt);
    if (unread.length > 0) {
      markAsRead({ messageIds: unread.map((m) => m.id) });
    }
  }, [messages, markAsRead]);

  if (isLoading) {
    return (
      <Grid
        spacing={0}
        alignItems="center"
        justify="center"
        style={{
          paddingTop: 60,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <RingLoader
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        />
        <Typography variant="h3" color="secondary">
          Loading
        </Typography>
        ;
      </Grid>
    );
  }

  return (
    <Fade in>
      <Box className={classes.root}>
        <Box pl={{ xs: 6, md: 3 }} pr={3} py={2} display="inline-flex">
          <Box mr={1}>
            <Typography></Typography>
            <Link
              to={generatePath(routes.entity.inboxList, {
                entity: entity,
                slug: routeSlug,
                inboxType: inboxType,
              })}
            >
              <IconButton>
                <Box component="span" className="material-icons">
                  arrow_back
                </Box>
              </IconButton>
            </Link>
          </Box>
          <Box mr={1}>
            <IconButton
              onClick={() => {
                console.log('delete');
              }}
            >
              <Box component="span" className="material-icons-outlined">
                delete
              </Box>
            </IconButton>
          </Box>
          <Box mr={1}>
            <IconButton>
              <Box component="span" className="material-icons">
                error_outline
              </Box>
            </IconButton>
          </Box>
        </Box>
        <Divider />
        <Scrollbars
          className="rct-scroll"
          autoHide
          style={{ height: 'calc(100vh - 150px)' }}
        >
          {messages?.length > 0 && (
            <Box p={{ xs: 2, md: 3 }}>
              {messages.map((message, idx) => (
                <>
                  {idx === 0 && (
                    <Typography variant="h5">{message?.subject}</Typography>
                  )}
                  <Box
                    display={{ xs: 'block', md: 'flex' }}
                    alignItems="center"
                    mb="10px"
                    mt={2}
                  >
                    <Box
                      pr={2}
                      mb={{ xs: 2, md: 0 }}
                      className={`author-thumb ${classes.authorThumb}`}
                    >
                      <Avatar>
                        {message?.sender?.firstName?.charAt(0)}
                        {message?.sender?.lastName?.charAt(0)}
                      </Avatar>
                    </Box>
                    <Box
                      display={{ xs: 'block', sm: 'flex' }}
                      alignItems="center"
                      justifyContent="space-between"
                      className={classes.authorDesc}
                    >
                      <Box>
                        <Box>{message?.sender?.name}</Box>
                        <Box
                          fontSize="subtitle2.fontSize"
                          color="text.secondary"
                        >
                          {message?.sender?.email}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box margin={2}>
                    <SanitizedHTML html={message?.body} />
                    <Typography align="right" variant="caption">
                      {moment(message?.created).format(
                        'MMMM Do YYYY, h:mm:ss a'
                      )}
                    </Typography>
                  </Box>
                </>
              ))}
              <Box>
                <Box
                  display={{ xs: 'block', md: 'flex' }}
                  alignItems="start"
                  mb={4}
                >
                  <Hidden lgDown>
                    <Box
                      pr={3}
                      mb={{ xs: 3, md: 0 }}
                      className={`author-thumb ${classes.authorThumb}`}
                    >
                      {user?.avatar ? (
                        <Avatar
                          className={classes.large}
                          src={user?.avatar}
                          alt="user profile"
                        />
                      ) : (
                        <Avatar className={classes.large} alt="user profile">
                          {user?.name?.charAt(0)}
                        </Avatar>
                      )}
                    </Box>
                  </Hidden>
                </Box>
              </Box>
              <Box className={classes.authorDesc}>
                <CustomCard cardClasses={classes.pad0}>
                  <Box py={2} px={2}>
                    <Box display="flex" alignItems="center">
                      <Box
                        style={{ width: '65px' }}
                        display="flex"
                        alignItems="center"
                      >
                        <IconButton
                          size="small"
                          disabled={isFetching || !reply || sending}
                          onClick={sendReply}
                        >
                          <Box
                            component="span"
                            color="text.primary"
                            fontSize="22px"
                            className="material-icons"
                          >
                            reply
                          </Box>
                        </IconButton>
                        <Box
                          pl="12px"
                          component="span"
                          fontSize="subtitle2.fontSize"
                          color="text.primary"
                        >
                          To
                        </Box>
                      </Box>
                      <Box style={{ width: 'calc(100% - 65px)' }}>
                        <TextField
                          fullWidth
                          disabled
                          id="standard-basic"
                          value={latest?.sender?.email}
                        />
                      </Box>
                    </Box>

                    <Box display="flex" alignItems="center">
                      <Box
                        style={{ width: '65px' }}
                        display="flex"
                        alignItems="center"
                      >
                        <IconButton
                          size="small"
                          disabled={isFetching || !reply || sending}
                        >
                          <Box
                            component="span"
                            color="white"
                            fontSize="22px"
                            className="material-icons"
                          >
                            reply
                          </Box>
                        </IconButton>
                        <Box
                          pl="12px"
                          component="span"
                          fontSize="subtitle2.fontSize"
                          color="text.primary"
                        >
                          cc
                        </Box>
                      </Box>
                      <Box style={{ width: 'calc(100% - 65px)' }}>
                        <ChipInput
                          fullWidth
                          newChipKeys={['Enter', 'Spacebar', ' ', ',', 'Tab']}
                          value={CC}
                          onAdd={(val) => setCC([...CC, val])}
                          onDelete={(chip) =>
                            setCC(CC.filter((val) => val !== chip))
                          }
                          onBlur={() => setTouched((t) => ({ ...t, CC: true }))}
                          error={errors?.CC?.[0]}
                          helperText={
                            errors?.CC?.[0] && 'Addresses must be valid emails'
                          }
                        />
                      </Box>
                    </Box>

                    <Box display="flex" alignItems="center">
                      <Box
                        style={{ width: '65px' }}
                        display="flex"
                        alignItems="center"
                      >
                        <IconButton
                          size="small"
                          disabled={isFetching || !reply || sending}
                        >
                          <Box
                            component="span"
                            color="white"
                            fontSize="22px"
                            className="material-icons"
                          >
                            reply
                          </Box>
                        </IconButton>
                        <Box
                          pl="12px"
                          component="span"
                          fontSize="subtitle2.fontSize"
                          color="text.primary"
                        >
                          bcc
                        </Box>
                      </Box>
                      <Box style={{ width: 'calc(100% - 65px)' }}>
                        <ChipInput
                          fullWidth
                          newChipKeys={['Enter', 'Spacebar', ' ', ',', 'Tab']}
                          value={BCC}
                          onAdd={(val) => setBCC([...BCC, val])}
                          onDelete={(chip) =>
                            setBCC(BCC.filter((val) => val !== chip))
                          }
                          onBlur={() =>
                            setTouched((t) => ({ ...t, BCC: true }))
                          }
                          error={errors?.BCC?.[0]}
                          helperText={
                            errors?.BCC?.[0] && 'Addresses must be valid emails'
                          }
                        />
                      </Box>
                    </Box>

                    <Box pt={1}>
                      <TextField
                        className={classes.editor}
                        id="standard-multiline-static"
                        style={{ background: '#f5f1f1', padding: '10px' }}
                        disabled={isFetching || sending}
                        value={reply}
                        onChange={(evt) => setReply(evt.target.value)}
                        multiline
                        fullWidth
                        rows="7"
                        defaultValue=""
                      />
                    </Box>
                  </Box>
                  <Divider />
                  <Box
                    py={1}
                    pl={2}
                    pr={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box display="flex" alignItems="center">
                      <Tooltip title="Send Mail" placement="bottom">
                        {sending ? (
                          <RingLoader />
                        ) : (
                          <IconButton
                            className={classes.sendBtn}
                            disabled={isFetching || !reply || sending}
                            onClick={sendReply}
                          >
                            <Box
                              component="span"
                              color="primary.main"
                              fontSize="18px"
                              className="fas fa-paper-plane"
                            ></Box>
                          </IconButton>
                        )}
                      </Tooltip>
                    </Box>
                    <Box borderLeft={1} pl="5px" className="border-left">
                      <Tooltip title="Delete" placement="bottom">
                        <IconButton
                          className={classes.pad10}
                          disabled={isFetching || sending}
                          onClick={() => {
                            // debugger;
                            mutate({ id });
                          }}
                        >
                          <Box
                            component="span"
                            fontSize="18px"
                            color="text.secondary"
                            className="far fa-trash-alt"
                          ></Box>
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </CustomCard>
              </Box>
            </Box>
          )}
        </Scrollbars>
      </Box>
    </Fade>
  );
};

export default EmailDetail;
