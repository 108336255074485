import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import useCreateCalendlyEvent from 'api/useCreateCalendlyEvent';
import useCreateEvent from 'api/useCreateEvent';
import useSendMessage from 'api/useSendMessage';
import {
  useUploadDocument,
  useUploadPdf,
  useUploadVideo,
} from 'api/useUploadDocument';
// mutations
import useUploadPhoto from 'api/useUploadPhoto';
import ChipInput from 'material-ui-chip-input';
import React, { useEffect, useMemo, useState } from 'react';
import {
  generatePath,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import RingLoader from 'react-spinners/RingLoader';
import routes from '../../routes';

const BaseSender = ({ type, children, onNext, onBack, data, sending }) => {
  const { to, cc, bcc, subject, fileGroupId } = data;
  const history = useHistory();

  // XXX: this memo is probably unnecessary, as `to` should not be changing at this point
  const owners = useMemo(
    () =>
      Object.values(
        to
          ?.flatMap((p) => p.owners || [])
          .reduce(
            (acc, owner) => Object.assign({}, acc, { [owner.id]: owner }),
            {}
          )
      ),
    [to.length]
  );

  return (
    <div>
      <DialogContent>
        <Box mt={3} mb={3}>
          <ChipInput
            disabled
            fullWidth
            label={type === 'message' ? 'To' : 'Properties'}
            inputValue=""
            value={to.map((p) => `${p?.project}: Lot ${p?.lotNumber} ${p?.block ? p?.block : ''}`)}
          />
        </Box>
        {type === 'message' && (
          <>
            <Box mb={1}>
              <TextField disabled fullWidth label="CC" value={cc} />
            </Box>
            <Box mb={1}>
              <TextField disabled fullWidth label="BCC" value={bcc} />
            </Box>
            <Box mb={3}>
              <TextField disabled fullWidth label="Subject" value={subject} />
            </Box>
          </>
        )}
        {/* {!owners.length ? null : (
          <Box mb={3} bgcolor="#F0F0F0" padding={1}>
            <Grid container spacing={1} direction="row">
              <Grid item>
                <InfoIcon />
              </Grid>
              <Grid item>
                <Typography
                  style={{ marginBottom: "8px" }}
                  display="block"
                  variant="body2"
                >
                  This message will be sent to the following{" "}
                  <strong>{owners.length}</strong>{" "}
                  {owners.length > 1 ? "people" : "person"}:
                </Typography>
                <Grid container direction="row">
                  <Grid item component="ul">
                    {owners.map((owner) => (
                      <li
                        key={owner.email}
                      >{`${owner.name} (${owner.email})`}</li>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )} */}
        <Box height="100%">{children}</Box>
      </DialogContent>
      <DialogActions>
        <Box py={1} px={2}>
          <Button
            disabled={sending}
            variant="outlined"
            className="primary-bg-btn"
            color="primary"
            autoFocus
            onClick={() => {
              history.push(routes.composer.base);
            }}
          >
            Done
          </Button>
        </Box>
      </DialogActions>
    </div>
  );
};

const Status = ({ sending, error }) => (
  <Box mb={3} textAlign="center">
    {sending ? (
      <RingLoader />
    ) : (
      <Fade in={!sending} timeout={1000}>
        {error ? <ErrorIcon fontSize="large" /> : <DoneIcon fontSize="large" />}
      </Fade>
    )}
    <Typography variant="h6">
      {sending ? 'Sending...' : error ? 'Error' : 'Sent'}
    </Typography>
  </Box>
);

const PDFSender = ({ data, onSent, onError }) => {
  const sendPdf = useUploadPdf();
  useEffect(() => {
    sendPdf(
      {
        bytes: data?.fileInfo?.size,
        url: data?.fileInfo?.cdnUrl,
        title: data?.fileInfo?.name,
        properties: data.to.map((p) => p.slug),
      },
      { onSuccess: onSent, onError }
    );
  }, [data]);
  return null;
};
const PhotoSender = ({ data, onSent, onError }) => {
  const sendPhoto = useUploadPhoto();
  useEffect(() => {
    sendPhoto(
      {
        url: data?.fileInfo?.cdnUrl,
        title: data?.fileInfo?.name,
        properties: data.to.map((p) => p.slug),
      },
      { onSuccess: onSent, onError }
    );
  }, [data]);
  return null;
};
const VideoSender = ({ data, onSent, onError }) => {
  const sendVideo = useUploadVideo();
  useEffect(() => {
    sendVideo(
      {
        bytes: data?.fileInfo?.size,
        url: data?.fileInfo?.cdnUrl,
        title: data?.fileInfo?.name,
        properties: data.to.map((p) => p.slug),
      },
      { onSuccess: onSent, onError }
    );
  }, [data]);
  return null;
};
const OtherSender = ({ data, onSent, onError }) => {
  const sendPdf = useUploadPdf();
  const sendDocument = useUploadDocument();
  useEffect(() => {
    if (data?.fileInfo?.mimeType?.toLowerCase() === 'application/pdf') {
      sendPdf(
        {
          bytes: data?.fileInfo?.size,
          url: data?.fileInfo?.cdnUrl,
          title: data?.fileInfo?.name,
          properties: data.to.map((p) => p.slug),
        },
        { onSuccess: onSent, onError }
      );
    } else {
      sendDocument(
        {
          bytes: data?.fileInfo?.size,
          url: data?.fileInfo?.cdnUrl,
          title: data?.fileInfo?.name,
          properties: data.to.map((p) => p.slug),
        },
        { onSuccess: onSent, onError }
      );
    }
  }, [data]);
  return null;
};
const MessageSender = ({ data, onSent, onError }) => {
  const sendMessage = useSendMessage();
  useEffect(() => {
    sendMessage(
      {
        body: data?.message,
        properties: data.to.map((p) => p.slug),
        cc: data?.cc,
        bcc: data?.bcc,
        subject: data?.subject,
        fileGroupId: data?.fileGroupId,
      },
      { onSuccess: onSent, onError }
    );
  }, [data]);
  return null;
};
const RSVPSender = ({ data, onSent, onError }) => {
  const createEvent = useCreateEvent();
  useEffect(() => {
    createEvent(
      {
        properties: data.to.map((p) => p.slug),
        cc: data.cc,
        ...data.eventInfo, // other fields should be direct from event form
      },
      { onSuccess: onSent, onError }
    );
  }, [data]);
  return null;
};

const CalendlySender = ({ data, onSent, onError }) => {
  const createCalendlyEvent = useCreateCalendlyEvent();

  useEffect(() => {
    createCalendlyEvent(
      {
        properties: data.to.map((p) => p.slug),
        calendlyUrl: data.calendlyEvent,
        message: data.message,
      },
      { onSuccess: onSent, onError }
    );
  }, [data]);
  return null;
};

const Sender = ({ data, ...props }) => {
  const [sending, setSending] = useState(true);
  const [error, setError] = useState(false);
  const match = useRouteMatch();
  const { fileType } = useParams();
  return (
    <div>
      <Status sending={sending} error={error} />
      <Switch>
        <Route
          path={generatePath(routes.composer.uploads, { fileType: 'messages' })}
        >
          <BaseSender data={data} {...props} sending={sending} type="messages">
            <MessageSender
              data={data}
              onSent={() => setSending(false)}
              onError={() => {
                setSending(false);
                setError(true);
              }}
            />
          </BaseSender>
        </Route>
        <Route
          path={generatePath(routes.composer.uploads, { fileType: 'events' })}
        >
          <BaseSender data={data} {...props} sending={sending}>
            <RSVPSender
              data={data}
              onSent={() => setSending(false)}
              onError={() => {
                setSending(false);
                setError(true);
              }}
            />
          </BaseSender>
        </Route>
        <Route
          path={generatePath(routes.composer.uploads, { fileType: 'rsvps' })}
        >
          <BaseSender data={data} {...props} sending={sending}>
            <CalendlySender
              data={data}
              onSent={() => setSending(false)}
              onError={() => {
                setSending(false);
                setError(true);
              }}
            />
          </BaseSender>
        </Route>
        <Route
          path={generatePath(routes.composer.uploads, { fileType: 'photos' })}
        >
          <BaseSender data={data} {...props} sending={sending}>
            <PhotoSender
              data={data}
              onSent={() => setSending(false)}
              onError={() => {
                setSending(false);
                setError(true);
              }}
            />
          </BaseSender>
        </Route>
        <Route
          path={generatePath(routes.composer.uploads, { fileType: 'videos' })}
        >
          <BaseSender data={data} {...props} sending={sending}>
            <VideoSender
              data={data}
              onSent={() => setSending(false)}
              onError={() => {
                setSending(false);
                setError(true);
              }}
            />
          </BaseSender>
        </Route>
        <Route
          path={generatePath(routes.composer.uploads, { fileType: 'files' })}
        >
          <BaseSender data={data} {...props} sending={sending}>
            <OtherSender
              data={data}
              onSent={() => setSending(false)}
              onError={() => {
                setSending(false);
                setError(true);
              }}
            />
          </BaseSender>
        </Route>
      </Switch>
    </div>
  );
};

export default Sender;
