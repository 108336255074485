import RequestContext from 'contexts/request';
import { gql } from 'graphql-request';
import { useContext } from 'react';
import { useQuery } from 'react-query';

const QUERY = gql`
  query ($documentType: String!, $projectSlug: String, $propertySlug: String) {
    allPhotos(
      documentType: $documentType
      projectSlug: $projectSlug
      propertySlug: $propertySlug
    ) {
      uuid
      isImage
      originalFilename
      secureUrl
      downloadUrl
    }
  }
`;

const requestPhotos = (client) => (variables) => async () => {
  const { allPhotos } = await client.request(QUERY, variables);
  return allPhotos;
};

const usePhotos = (documentType, projectSlug = null, propertySlug = null) => {
  const { client } = useContext(RequestContext);
  return useQuery(
    ['allPhotos', documentType, projectSlug, propertySlug],
    requestPhotos(client)({ documentType, projectSlug, propertySlug })
  );
};

export default usePhotos;
