/**
 * Recent Followers
 */
import { Avatar, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
//Component
import { CustomCard } from 'components/GlobalComponents';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    '@media (max-width:1560px)': {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
  },
}));

export default function RecentFollower({ owners }) {
  const classes = useStyles();

  return (
    <div>
      <CustomCard
        cardClasses="recent-followers-wrap"
        title="Owners"
        showDivider={true}
      >
        <Box pt={3}>
          {owners?.map((owner) => (
            <Box key={owner?.email} display="flex" alignItems="start" mb={2}>
              <Box>
                <Avatar
                  className={classes.large}
                  alt="img"
                  src={owner.avatar}
                />
              </Box>
              <Box pl={2} className="content-wrap">
                <Box>{owner.name}</Box>
                <Typography variant="subtitle2">{owner.email}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </CustomCard>
    </div>
  );
}
