import RequestContext from 'contexts/request';
import { gql } from 'graphql-request';
import { useContext } from 'react';
import { useQuery } from 'react-query';

const QUERY = gql`
  query {
    allStaff {
      id
      email
      firstName
      lastName
    }
  }
`;

const requestAllStaff = (client) => () => async () => {
  const { allStaff } = await client.request(QUERY);
  return allStaff;
};

const useAllStaff = () => {
  const { client } = useContext(RequestContext);
  return useQuery(['allStaff'], requestAllStaff(client)());
};

export default useAllStaff;
