import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

const original = (x) => {
  const url = new URL(x);
  url.pathname += '/-/preview/1200x900/-/crop/1200x900/center/';
  return url.toString();
};

const thumbnail = (x) => {
  const url = new URL(x);
  url.pathname += '/-/preview/90x90/';
  return url.toString();
};

// https://github.com/xiaolin/react-image-gallery
// https://uploadcare.com/docs/transformations/resize-crop/#size
// XXX: assumes urls are uploadcare images
const ImageViewer = ({ images }) => {
  const items = images.map((img) => ({
    original: original(img.url),
    thumbnail: thumbnail(img.cdnUrl),
  }));
  return (
    <ImageGallery
      showPlayButton={false}
      showFullscreenButton={false}
      items={items}
    />
  );
};

export default ImageViewer;
