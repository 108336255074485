/**
 * Inbox Emails
 */
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import EmailDetail from './emailDetail';
// Components
import Listing from './MessageListing';

const EmailPreview = (props) => (
  <>
    <Hidden lgUp>
      <EmailDetail {...props} />
    </Hidden>
    <Hidden mdDown>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Listing />
        </Grid>
        <Grid item xs={12} md={6}>
          <EmailDetail {...props} />
        </Grid>
      </Grid>
    </Hidden>
  </>
);

const Inbox = (props) => {
  const { match } = props;
  return (
    <Switch>
      <Route exact path={match.url} component={Listing} />
      <Route path={`${match.url}/:id`} component={EmailPreview} />
    </Switch>
  );
};

export default Inbox;
