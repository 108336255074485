/**
 * User Block Section
 */
import {
  Avatar,
  Button,
  IconButton,
  List,
  ListItem,
  ListSubheader,
  Popover,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useUser from 'api/useUser';
import RequestContext from 'contexts/request';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import routes from '../../../routes';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minWidth: 300,
    padding: 0,
    '& >a': {
      color: theme.palette.text.primary,
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.05)',
      },
    },
    '& .top-dropdown-menu--item': {
      padding: '20px 12px',
      borderTop: `1px solid ${theme.palette.divider}`,
    },
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  avatar: {
    '@media (max-width:1560px)': {
      width: 35,
      height: 35,
    },
  },
}));

function HeaderUserBlock() {
  const { setAuth } = useContext(RequestContext);
  const history = useHistory();
  const user = useUser();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  // Define function for open dropdown
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Define function for close dropdown
  const handleClose = () => {
    setAnchorEl(null);
  };
  /*
   * Logout User
   */
  const logoutUser = () => {
    setAnchorEl(null);
    setAuth(null);
    history.push(routes.auth.login);
  };

  const open = Boolean(anchorEl);
  return (
    <div>
      <Tooltip title="User Profile" placement="bottom">
        <IconButton
          aria-describedby={open ? 'simple-popper' : null}
          variant="contained"
          color="primary"
          style={{ padding: '6px' }}
          onClick={handleClick}
        >
          <Avatar
            alt="user-thumb"
            className={classes.avatar}
            src={user.avatar}
          />
        </IconButton>
      </Tooltip>
      <Popover
        id="simple-popper"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <>
          <List
            className={`${classes.root} top-dropdown-menu`}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                <div className="dropdown-header user-info  text-center">
                  <Avatar
                    alt="user-thumb"
                    className={classes.large}
                    src={user.avatar}
                  />
                  <Typography variant="body2">{user.name}</Typography>
                  <Typography variant="subtitle2">{user.email}</Typography>
                  <Link to="/settings">
                    <Button variant="contained" color="primary">
                      Edit Profile
                    </Button>
                  </Link>
                </div>
              </ListSubheader>
            }
          >
            <ListItem
              component="div"
              className="top-dropdown-menu--item d-block text-center"
            >
              <Button variant="contained" color="primary" onClick={logoutUser}>
                Sign out
              </Button>
            </ListItem>
          </List>
        </>
      </Popover>
    </div>
  );
}

export default HeaderUserBlock;
