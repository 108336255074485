/**
 * Emails Listing
 */
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMessages from 'api/useMessages';
import clsx from 'clsx';
import { CustomCard } from 'components/GlobalComponents';
import moment from 'moment';
import React from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import routes from '../../../../routes';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: 0,
    backgroundColor: theme.palette.background.paper,
  },
  searchBox: {
    padding: '24px 48px 32px',
    [theme.breakpoints.down('lg')]: {
      padding: '5px 16px 12px 48px',
    },
    [theme.breakpoints.down('md')]: {
      '& .pagination-wrap': {
        marginTop: '-10px',
      },
    },
  },
  selectWrap: {
    padding: '22px 16px',
    '& label > span:nth-child(2)': {
      fontSize: 16,
    },
    [theme.breakpoints.down('lg')]: {
      padding: '6px 16px 5px 48px',
      '& label': {
        marginRight: 0,
        '& > span:nth-child(2)': {
          display: 'none',
        },
      },
    },
  },
  inline: {
    display: 'inline',
  },
  formItem: {
    fontSize: 24,
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
  mailItem: {
    '&:not(:first-child)': {
      borderTop: '1px solid #e0e0e0',
    },
    paddingLeft: 0,
    cursor: 'pointer',
    transition: 'opacity 0.2s ease-out',
    '& >div:first-child': {
      transition: 'opacity 0.2s ease-out',
    },
    '&:hover': {
      '& >div:first-child': {
        opacity: 1,
      },
    },
    [theme.breakpoints.down('lg')]: {
      '& >div:first-child': {
        opacity: 1,
        '& >span': {
          marginLeft: 'auto',
          marginRight: 'auto',
        },
      },
    },
  },
  active: {
    backgroundColor: theme.palette.background.paper,
    '& >div:first-child': {
      opacity: 1,
    },
  },
  check: {
    marginTop: 2,
    opacity: 0,
    minWidth: 50,
    [theme.breakpoints.down('lg')]: {
      minWidth: 42,
      marginTop: 1,
      '& svg': {
        width: 22,
        height: 22,
      },
    },
  },
  secBtn: {
    color: theme.palette.text.secondary,
    fontSize: '1rem',
    fontWeight: 400,
    [theme.breakpoints.down('lg')]: {
      minWidth: 'auto',
      '& span > span:nth-child(2)': {
        display: 'none',
      },
    },
  },
  mailIcon: {
    '@media (max-width:1560px)': {
      minWidth: 50,
      '& .material-icons-outlined': {
        fontSize: 27,
      },
    },
    [theme.breakpoints.down('lg')]: {
      minWidth: 45,
    },
  },
  desc: {
    width: '100%',
  },
  detail: {
    width: '150px',
    [theme.breakpoints.down('md')]: {
      width: '55px',
      fontSize: 12,
    },
  },
  rctScroll: {
    height: 'calc(100vh - 178px) !important',
    [theme.breakpoints.down('lg')]: {
      height: 'calc(100vh - 139px) !important',
    },
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 165px) !important',
    },
  },
}));

function EmailListing() {
  const history = useHistory();
  const classes = useStyles();
  const { slug } = useParams();

  const { data } = useMessages(null, slug);

  const recentMessages = data
    ?.filter((m) => !m.parent)
    .sort((a, b) => a.created - b.created)
    .slice(0, 3);

  return (
    <CustomCard title="Recent Messages" showDivider>
      {recentMessages && recentMessages.length > 0 ? (
        <List className={`email-list-wrap ${classes.root}`}>
          {recentMessages.map((msg, index) => (
            <ListItem
              className={clsx(classes.mailItem, {
                [classes.active]: false,
              })}
              alignItems="flex-start"
              key={index}
              onClick={() =>
                history.push(
                  generatePath(routes.entity.inboxDetail, {
                    entity: 'properties',
                    slug: slug,
                    inboxType: 'messages',
                    id: msg.id,
                  })
                )
              }
            >
              <ListItemIcon className={classes.mailIcon}>
                {msg?.readAt ? (
                  <Box
                    component="span"
                    fontSize={{ xs: '25px', md: '32px' }}
                    mr={1}
                    className="material-icons-outlined"
                  >
                    drafts
                  </Box>
                ) : (
                  <Box
                    component="span"
                    color="primary.main"
                    fontSize={{ xs: '25px', md: '32px' }}
                    mr={1}
                    className="material-icons-outlined"
                  >
                    mail
                  </Box>
                )}
              </ListItemIcon>
              <ListItemText>
                <Box display="flex" alignItems="center">
                  <Box className={`content-desc ${classes.desc}`}>
                    <Box
                      className="list-desc"
                      fontSize="subtitle1.fontSize"
                      color="primary.main"
                      style={{ cursor: 'pointer' }}
                    >
                      {msg?.subject}
                    </Box>
                  </Box>
                </Box>
                <Box fontSize="subtitle2.fontSize" color="text.primary">
                  {moment(msg?.created).format('DD/MM/YYYY HH:MM')}
                </Box>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      ) : (
        <Box
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box fontSize="h5.fontSize" color="text.secondary" marginTop="16px">
            No Messages Found
          </Box>
        </Box>
      )}
    </CustomCard>
  );
}

export default EmailListing;
