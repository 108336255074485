/* eslint-disable no-use-before-define */

/**
 * Functional component to toggle the full screen functionality of the application
 */
import { Icon, IconButton, Tooltip } from '@material-ui/core';
import React from 'react';
import screenfull from 'screenfull';

export default function FullScreen(props) {
  return (
    <Tooltip title="Full Screen" placement="bottom">
      <IconButton
        aria-label="settings"
        style={{ padding: '6px' }}
        onClick={() => toggleScreenFull()}
      >
        <Icon className={props.iconColor}>fullscreen</Icon>
      </IconButton>
    </Tooltip>
  );
}

/**
 * Function to toggle the full screen functionality
 */
function toggleScreenFull() {
  screenfull.toggle();
}
