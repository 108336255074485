import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EmailIcon from '@material-ui/icons/Email';
import EventIcon from '@material-ui/icons/Event';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import useUser from 'api/useUser';
import React from 'react';
import {
  generatePath,
  Link,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import routes from '../../../routes';

const useDocTypeButtonStyles = makeStyles((theme) => ({
  root: {
    minWidth: '120px',
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  label: {
    flexDirection: 'column',
  },
}));

export const TypeSelector = () => {
  const match = useRouteMatch();
  const location = useLocation();
  const selection = location.pathname.split('/').slice(-1)[0];
  const buttonClasses = useDocTypeButtonStyles();
  const user = useUser();
  const TypeButton = (props) => (
    <Button
      variant="outlined"
      className="primary-bg-btn"
      color="primary"
      classes={buttonClasses}
      {...props}
    />
  );
  return (
    <div>
      <Typography variant="h6" align="center">
        What type of communication would you like to send?
      </Typography>
      <Grid container justify="center" spacing={3} style={{ padding: '16px' }}>
        <Grid item>
          <Link
            to={`${generatePath(routes.composer.uploads, {
              fileType: 'messages',
            })}${location.search}`}
          >
            <TypeButton
              disabled={selection === 'messaages'}
              classes={buttonClasses}
            >
              <EmailIcon />
              Message
            </TypeButton>
          </Link>
        </Grid>
        {user.isStaff && (
          <>
            <Grid item>
              <Link
                to={`${generatePath(routes.composer.uploads, {
                  fileType: 'events',
                })}${location.search}`}
              >
                <TypeButton
                  disabled={selection === 'events'}
                  classes={buttonClasses}
                >
                  <EventIcon />
                  Event
                </TypeButton>
              </Link>
            </Grid>

            <Grid item>
              <Link
                to={`${generatePath(routes.composer.uploads, {
                  fileType: 'rsvps',
                })}${location.search}`}
              >
                <TypeButton
                  disabled={selection === 'rsvps'}
                  classes={buttonClasses}
                >
                  <GroupAddIcon />
                  RSVP
                </TypeButton>
              </Link>
            </Grid>
          </>
        )}
        {/* <Grid item>
          <Link
            to={`${generatePath(routes.composer.uploads, {
              fileType: 'photos',
            })}${location.search}`}
          >
            <TypeButton
              disabled={selection === 'photos'}
              classes={buttonClasses}
            >
              <PhotoIcon />
              Photo
            </TypeButton>
          </Link>
        </Grid>
        <Grid item>
          <Link
            to={`${generatePath(routes.composer.uploads, {
              fileType: 'videos',
            })}${location.search}`}
          >
            <TypeButton
              disabled={selection === 'videos'}
              classes={buttonClasses}
            >
              <VideocamIcon />
              Video
            </TypeButton>
          </Link>
        </Grid>
        <Grid item>
          <Link
            to={`${generatePath(routes.composer.uploads, {
              fileType: 'files',
            })}${location.search}`}
          >
            <TypeButton
              disabled={selection === 'files'}
              classes={buttonClasses}
            >
              <DescriptionIcon />
              Files
            </TypeButton>
          </Link>
        </Grid> */}
      </Grid>
    </div>
  );
};

export default TypeSelector;
