import RequestContext from 'contexts/request';
import { gql } from 'graphql-request';
import { useContext } from 'react';
import { useQuery } from 'react-query';

const QUERY = gql`
  query ($documentType: String!, $projectSlug: String, $propertySlug: String) {
    allDocuments(
      documentType: $documentType
      projectSlug: $projectSlug
      propertySlug: $propertySlug
    ) {
      id
      lotNumber
      subject
      cc
      bcc
      body
      bodyColumn
      sender {
        id
        email
        name
      }
      sentAt
      created
      modified
      readAt
      parent {
        id
      }
      allFiles {
        uuid
        mimeType
        isImage
        originalFilename
        secureUrl
        downloadUrl
      }
    }
  }
`;

const requestDocuments = (client) => (variables) => async () => {
  const { allDocuments } = await client.request(QUERY, variables);
  return allDocuments;
};

const useDocuments = (
  documentType,
  projectSlug = null,
  propertySlug = null
) => {
  const { client } = useContext(RequestContext);
  return useQuery(
    ['allDocuments', documentType, projectSlug, propertySlug],
    requestDocuments(client)({ documentType, projectSlug, propertySlug })
  );
};

export default useDocuments;
