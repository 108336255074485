/**
 * General Settings
 */
import { Box, Button, FormControl, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useUpdateUser from 'api/useUpdateUser';
import useUser from 'api/useUser';
// Component
import { ContentLayout } from 'components/Layouts';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import 'yup-phone';

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  profileThumb: {
    '& >div': {
      '& >div': {
        '& >div:first-child': {
          alignSelf: 'center',
        },
      },
    },
  },
  fileUpload: {
    '& input': {
      height: 'auto',
    },
  },
}));

export default function GeneralSettings() {
  const classes = useStyles();
  const user = useUser();
  const updateUser = useUpdateUser();
  const [avatar, setAvatar] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState('');

  const [touched, setTouched] = useState({});
  const [errors, setErrors] = useState({});

  const validateUserSettings = () => {
    return Promise.all([
      yup
        .object()
        .shape({ firstName: yup.string().required() })
        .validate({ firstName })
        .then(() => ({
          /* no errors */
        }))
        .catch((err) => ({
          firstName: err.errors.map((e) =>
            e.replace('firstName', 'First Name')
          ),
        })),
      yup
        .object()
        .shape({ lastName: yup.string().required() })
        .validate({ lastName })
        .then(() => ({
          /* no errors */
        }))
        .catch((err) => ({
          lastName: err.errors.map((e) => e.replace('lastName', 'Last Name')),
        })),
      ...[
        phone
          ? yup
              .object()
              .shape({ phone: yup.string().phone('CA') })
              .validate({ phone })
              .then(() => ({
                /* no errors */
              }))
              .catch((err) => ({
                phone: err.errors.map((e) =>
                  e.replace('phone', 'Phone Number')
                ),
              }))
          : [],
      ],
    ])
      .then((errors) => Object.assign({}, ...errors))
      .then(setErrors);
  };

  // init effect (because user can be undefined on first render)
  useEffect(() => {
    setAvatar(user?.avatar);
    setFirstName(user?.firstName || '');
    setLastName(user?.lastName || '');
    setEmail(user?.email || '');
    setPhone(user?.phone || '');
  }, [user]);

  // validation effect
  useEffect(() => {
    validateUserSettings();
  }, [firstName, lastName, email, phone]);

  // determine if update is required
  const [noChanges, setNoChanges] = useState(true);
  useEffect(() => {
    setNoChanges(
      [
        firstName === user.firstName,
        lastName === user.lastName,
        phone === user.phone,
        avatar === user.avatar,
      ].every((x) => x)
    );
  }, [user, firstName, lastName, phone, avatar]);

  const [isSaving, setIsSaving] = useState(false);
  const save = () => {
    if (Object.values(errors).length === 0) {
      setIsSaving(true);
      updateUser(
        { firstName, lastName, phone, avatar },
        { onSettled: () => setIsSaving(false) }
      );
    }
  };

  return (
    <div className="hk-general-settings">
      <form>
        <Box mb={3} className={classes.profileThumb}>
          {/*
          <ContentLayout title="Your Profile Image">
            <Box width="100%" display="flex" alignItems="center">
              <Box pr={2} className="avatar-thumb">
                <Avatar src={avatar} alt="Remy Sharp" className={classes.large} />
              </Box>
              <Box width="100%">
                <Widget
                  disabled={isSaving}
                  imagesOnly={true}
                  publicKey={process.env.REACT_APP_UPLOADCARE_PUBLIC_KEY}
                  onFileSelect={p => p.done(({ cdnUrl }) => setAvatar(cdnUrl))}
                />
              </Box>
            </Box>
          </ContentLayout>
          */}
        </Box>
        <Box mb={3}>
          <ContentLayout title="First Name">
            <FormControl fullWidth>
              <TextField
                id="firstName"
                name="firstName"
                placeholder="First Name"
                className=""
                type="input"
                value={firstName}
                onChange={(evt) => setFirstName(evt.target.value)}
                onBlur={() => setTouched((t) => ({ ...t, firstName: true }))}
                error={touched.firstName && errors.firstName}
                helperText={
                  touched.firstName && errors.firstName && errors.firstName[0]
                }
                disabled={isSaving}
              />
            </FormControl>
          </ContentLayout>
        </Box>
        <Box mb={3}>
          <ContentLayout title="Last Name">
            <FormControl fullWidth>
              <TextField
                id="last-name"
                placeholder="Last Name"
                className=""
                type="input"
                value={lastName}
                onChange={(evt) => setLastName(evt.target.value)}
                onBlur={() => setTouched((t) => ({ ...t, lastName: true }))}
                error={touched.lastName && errors.lastName}
                helperText={
                  touched.lastName && errors.lastName && errors.lastName[0]
                }
                disabled={isSaving}
              />
            </FormControl>
          </ContentLayout>
        </Box>
        <Box mb={2}>
          <ContentLayout title="Email">
            <FormControl fullWidth>
              <TextField
                id="email"
                placeholder="Email"
                type="email"
                value={email}
                disabled={true}
              />
            </FormControl>
          </ContentLayout>
        </Box>
        <Box mb={2}>
          <ContentLayout title="Phone">
            <FormControl fullWidth>
              <TextField
                id="phone"
                placeholder="Phone"
                className=""
                type="input"
                value={phone}
                onChange={(evt) => setPhone(evt.target.value)}
                onBlur={() => setTouched((t) => ({ ...t, phone: true }))}
                error={touched.phone && errors.phone}
                helperText={touched.phone && errors.phone && errors.phone[0]}
                disabled={isSaving}
              />
            </FormControl>
          </ContentLayout>
        </Box>
        <Box mb={3}>
          <ContentLayout>
            <Box textAlign="right">
              <Button
                disabled={
                  noChanges || isSaving || Object.values(errors).length > 0
                }
                onClick={save}
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </Box>
          </ContentLayout>
        </Box>
      </form>
    </div>
  );
}
