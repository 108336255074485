import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from '@material-ui/core';
import useProjects from 'api/useProjects';
import ChipInput from 'material-ui-chip-input';
import React, { useEffect, useState } from 'react';

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image'],
    ['clean'],
    [{ align: [] }],
  ],
};
const formats = [
  'header',
  'font',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'align',
];

const BaseConfirmer = ({ type, data, onNext, onBack, children }) => {
  const projects = useProjects();

  const [selectedProject, setSelectedProject] = useState('');
  useEffect(() => {
    if (projects?.length && !selectedProject) {
      setSelectedProject(projects[0].slug);
    }
  }, [projects, selectedProject, setSelectedProject]);

  const { to, cc, bcc, subject, calendlyEvent, fileGroupId } = data;

  const owners = Object.values(
    to
      .flatMap((p) => p.owners || [])
      .reduce((acc, owner) => Object.assign({}, acc, { [owner.id]: owner }), {})
  );

  return (
    <Grid container justify="center">
      <Grid item xs={12} md={8}>
        <DialogContent>
          <Box mt={3} mb={3}>
            <ChipInput
              disabled
              fullWidth
              label={type === 'message' ? 'To' : 'Properties'}
              inputValue=""
              value={to.map((p) => `${p?.project}: Lot ${p?.lotNumber} ${p?.block ? p?.block : ''}`)}
            />
            {['message', 'event'].includes(type) && (
              <Box mb={1}>
                <TextField disabled fullWidth label="CC" value={cc} />
              </Box>
            )}
          </Box>
          {type === 'message' && (
            <>
              <Box mb={1}>
                <TextField disabled fullWidth label="BCC" value={bcc} />
              </Box>
              <Box mb={3}>
                <TextField disabled fullWidth label="Subject" value={subject} />
              </Box>
            </>
          )}
          {type === 'rsvps' && (
            <>
              <Box mb={1}>
                <TextField
                  disabled
                  fullWidth
                  label="Calendly event"
                  value={calendlyEvent}
                />
              </Box>
            </>
          )}
          {/* {!owners.length ? null : (
            <Box mb={3} bgcolor="#F0F0F0" padding={1}>
              <Grid container spacing={1} direction="row">
                <Grid item>
                  <InfoIcon />
                </Grid>
                <Grid item>
                  <Typography
                    style={{ marginBottom: "8px" }}
                    display="block"
                    variant="body2"
                  >
                    This message will be sent to the following{" "}
                    <strong>{owners.length}</strong>{" "}
                    {`${owners.length > 1 ? "people" : "person"}:`}
                  </Typography>
                  <Grid container direction="row">
                    <Grid item component="ul">
                      {owners.map((owner) => (
                        <li
                          key={owner.email}
                        >{`${owner.name} (${owner.email})`}</li>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          )} */}
          <Box height="100%">{children}</Box>
        </DialogContent>
      </Grid>
      <Grid item xs={12}>
        <DialogActions>
          <Box py={1} px={2}>
            <Box display="inline-block" mr={2}>
              <Button
                variant="outlined"
                className="primary-bg-btn"
                color="primary"
                onClick={onBack}
              >
                <Box
                  component="span"
                  fontSize="20px"
                  mr={1}
                  className="material-icons"
                >
                  edit
                </Box>
                Edit
              </Button>
            </Box>
            <Button
              variant="outlined"
              className="primary-bg-btn"
              color="primary"
              autoFocus
              onClick={onNext}
            >
              <Box
                component="span"
                fontSize="18px"
                mr={1}
                className="material-icons"
              >
                check
              </Box>
              Confirm
            </Button>
          </Box>
        </DialogActions>
      </Grid>
    </Grid>
  );
};

export default BaseConfirmer;
