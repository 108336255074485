/**
 * User Detail
 */
import { Box, Typography } from '@material-ui/core';
import DefaultAvatar from 'assets/Images/icon_house_transp-white.png';
import React from 'react';
import Navigation from './Navigation';

export default function UserDetail(property) {
  const address = property?.address;
  const backgroundImage =
    `https://cdn.homeownerportal.io/${property?.avatarUuid}/-/resize/680x/-/format/auto/-/quality/smart/-/progressive/yes/?token=${property?.avatarToken}` ||
    DefaultAvatar;
  const lotNumberWithBlock =
    property?.lotNumber && property?.block
      ? `${property?.lotNumber}${property?.block.toUpperCase()}`
      : property?.lotNumber
      ? `${property?.lotNumber}`
      : '';
  return (
    <div className="mod-profile-wrap">
      <Box pt={3} className="mod-profile">
        <div
          className="mod-profile-header"
          style={{
            backgroundColor: 'lightgrey',

            backgroundImage: `url(${backgroundImage})`,
            // backgroundSize: property?.avatar ? "cover" : "128px 128px",
            backgroundSize: 'contain',
            backgroundPosition: 'center center',
            backgroundColor: '#030a1d',
          }}
        ></div>
        <Box className="mod-profile-detail">
          <Box>
            <Box>
            <Typography variant="h6">{property.project === "North Shore" ? "Suite/Lot" : "Lot"} {lotNumberWithBlock}</Typography>
              {address?.street &&
                address?.city &&
                address?.province &&
                address?.postal && (
                  <div className="mod-profile-meta mod-profile-bio">
                    <ul>
                      <li>
                        <span>{`${address?.street}, ${address?.city}, ${address?.province} ${address?.postal}`}</span>
                      </li>
                    </ul>
                  </div>
                )}
            </Box>
            <Box marginTop="24px">
              <Navigation unreads={property.unreadMessages} />
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
}
