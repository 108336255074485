/**
 * Inbox Emails
 */
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { StyleSheet } from '@react-pdf/renderer';
import useProperty from 'api/useProperty';
import SelectedPropertyContext from 'contexts/selectedProperty';
import React, { useContext, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { Route, Switch } from 'react-router-dom';
// Components
import Listing from './DocumentListing';

const EmailPreview = ({ match }) => {
  const [slug] = useContext(SelectedPropertyContext);
  const property = useProperty(slug);
  const docs = property?.documents || [];
  const doc = docs.find((d) => d.id === match.params.id);

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Listing type="PDF" />
      </Grid>
      {doc?.type === 'PDF' ? (
        <Grid item xs={12} md={6}>
          <ImagePreview url={doc?.url} />
        </Grid>
      ) : (
        <Grid item xs={12} md={6}>
          <Box
            style={{
              width: '100%',
              height: '100vh',
              paddingTop: '10vh',
              backgroundColor: '#f1f5f9',
              overflow: 'scroll',
            }}
          >
            <Box width="90%" margin="auto">
              <Typography align="center">
                No preview available for this file type
              </Typography>
            </Box>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

const ImagePreview = ({ url }) => {
  const [pages, setPages] = useState(0);

  const pageStyles = StyleSheet.create({
    canvas: { height: 'auto', width: '100%' },
  });
  return (
    <Box
      style={{
        width: '100%',
        height: '100vh',
        paddingTop: '10vh',
        backgroundColor: '#f1f5f9',
        overflow: 'scroll',
      }}
    >
      <Box width="90%" margin="auto">
        <Document
          file={url}
          onLoadSuccess={({ numPages }) => setPages(numPages)}
        >
          {Array.from({ length: pages }, (_, idx) => (
            <>
              <Page key={idx + 1} pageNumber={idx + 1} />
              <Box height="32px" />
            </>
          ))}
        </Document>
      </Box>
    </Box>
  );
};

function Inbox(props) {
  const { match } = props;
  return (
    <Switch>
      <Route exact path={match.url}>
        <Listing type="OTHER" />
      </Route>
      <Route path={`${match.url}/:id`} component={EmailPreview} />
    </Switch>
  );
}

export default Inbox;
