import RequestContext from 'contexts/request';
import { gql } from 'graphql-request';
import { useContext } from 'react';
import { useQuery } from 'react-query';

const QUERY = gql`
  query ($slug: String!) {
    property(slug: $slug) {
      id
      created
      modified
      slug
      isSold
      isCondo
      isHighRise
      videoUrl
      lotNumber
      block
      project
      unreadMessages
      interactiveAbodeUrl
      projectVirtualStudio
      owners {
        id
        name
        email
        phone
      }
      avatar
      avatarCdnUrl
      avatarUuid
      avatarToken
      gallery {
        id
        title
        created
        url
        token
        cdnUrl
        uuid
      }
      photoGallery {
        id
        title
        created
        url
        cdnUrl
      }
      address {
        street
        city
        province
        postal
      }
      events {
        id
        calendarId
        lotNumber
        location
        description
        summary
        status
        start
        end
        attendees {
          email
          responseStatus
        }
      }
    }
  }
`;

const requestProperty = (client) => (variables) => async () => {
  const { property } = await client.request(QUERY, variables);
  return property;
};

const useProperty = (slug, shouldFetch = true) => {
  const { client } = useContext(RequestContext);
  return useQuery(['property', slug], requestProperty(client)({ slug }), {
    enabled: shouldFetch,
  });
};

export default useProperty;
