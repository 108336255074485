/**
 * Email Sidebar
 */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Hidden,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import DescriptionIcon from '@material-ui/icons/Description';
import EmailIcon from '@material-ui/icons/Email';
import PhotoIcon from '@material-ui/icons/Photo';
import VideocamIcon from '@material-ui/icons/Videocam';
import useProperties from 'api/useProperties';
import clsx from 'clsx';
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import { generatePath } from 'react-router';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import UnitCard from 'routes/Projects/Dashboard/UnitCard';
import IntlMessages from 'util/IntlMessages';
import routes from '../../../routes';

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image'],
    ['clean'],
    [{ align: [] }],
  ],
};

const formats = [
  'header',
  'font',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'align',
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingTop: '15px',
    paddingBottom: '15px',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  dialog: {
    width: '100%',
    maxWidth: '60vw',
    '@media (max-width:960px)': {
      maxWidth: '80vw',
    },
    '@media (max-width:600px)': {
      maxWidth: '95vw',
      marginLeft: 15,
      marginRight: 15,
    },
  },
  subheader: {
    color: theme.palette.text.primary,
    lineHeight: '40px',
    fontSize: theme.typography.body1.fontSize,
  },
  listItem: {
    padding: '14px 16px',
  },
  listIcon: {
    marginRight: '1em',
    minWidth: 30,
    '& span': {
      fontSize: '1.25rem',
    },
  },
  listActive: {
    backgroundColor: theme.palette.background.default,
    boxShadow: '0 0 3px 0 rgba(0, 0, 0, .12)',
    '& span': {
      color: theme.palette.primary.main,
    },
  },
  divider: {
    '&:First-child': {
      display: 'none',
    },
  },
}));

const EmailSidebar = (props) => {
  const { slug, entity, inboxType } = useParams();

  const folders = [
    {
      name: 'Messages',
      icon: <EmailIcon />,
      path: generatePath(routes.entity.inboxList, {
        entity: entity,
        slug: slug,
        inboxType: 'messages',
      }),
    },
    {
      name: 'Photos',
      icon: <PhotoIcon />,
      path: generatePath(routes.entity.inboxList, {
        entity: entity,
        slug: slug,
        inboxType: 'photos',
      }),
    },
    {
      name: 'Videos',
      icon: <VideocamIcon />,
      path: generatePath(routes.entity.inboxList, {
        entity: entity,
        slug: slug,
        inboxType: 'videos',
      }),
    },
    {
      name: 'Documents',
      icon: <DescriptionIcon />,
      path: generatePath(routes.entity.inboxList, {
        entity: entity,
        slug: slug,
        inboxType: 'pdfs',
      }),
    },
  ];

  const match = useRouteMatch('/properties/:slug/files/:type');
  const selected = folders.find(
    (f) => f.name.toLowerCase() === match?.params?.type
  );
  const setSelected = (path) => {
    history.push(path);
  };

  const properties = useProperties();
  const property = properties?.find((p) => p.slug === slug);

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const handleClickOpen = () => {
    if (match?.params?.slug) {
      const goto = generatePath(routes.entity.detail, {
        entity: entity,
        slug: slug,
        inboxType: inboxType,
      });
      history.push(goto);
    }
  };

  const translatedEntity =
    entity === 'properties'
      ? 'property'
      : entity === 'projects'
      ? 'project'
      : null;

  const handleClose = () => {
    setOpen(false);
  };

  const newGoto = `${generatePath(routes.composer.uploads, {
    fileType: inboxType,
  })}?${translatedEntity}=${slug}`;

  return (
    <div>
      <Box px={1} pt={4} pb={2} textAlign="center" bgcolor="back">
        <UnitCard {...props} imgHeight={165} />
      </Box>
      <Box px={3} pt={4} pb={2} textAlign="center" bgcolor="back">
        <Button
          size="large"
          startIcon={<AddIcon />}
          className={`${classes.button} primary-bg-btn compose-btn`}
          component={Link}
          href={newGoto}
          variant="outlined"
          color="primary"
        >
          New
        </Button>
      </Box>
      <Box>
        <Box mx={2} className={classes.divider}>
          <Divider />
        </Box>
        <List
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader
              className={classes.subheader}
              component="div"
              id="nested-list-subheader"
            >
              Folders
            </ListSubheader>
          }
          className={classes.root}
        >
          <Hidden mdUp implementation="css">
            {folders.map((folder, i) => (
              <ListItem
                className={clsx(classes.listItem, {
                  [classes.listActive]: folder?.name === selected?.name,
                })}
                button
                key={i}
                onClick={() => {
                  setSelected(folder?.path);
                  // props.closeEmailSidebar();
                }}
              >
                <ListItemIcon className={classes.listIcon}>
                  {folder.icon}
                </ListItemIcon>
                <ListItemText primary={<IntlMessages id={folder?.name} />} />
              </ListItem>
            ))}
          </Hidden>
          <Hidden smDown implementation="css">
            {folders.map((folder, i) => (
              <ListItem
                className={clsx(
                  classes.listItem,
                  {
                    [classes.listActive]: folder?.name === selected?.name,
                  },
                  'list-item'
                )}
                button
                key={i}
                onClick={() => setSelected(folder.path)}
              >
                <ListItemIcon className={classes.listIcon}>
                  <Box component="span" className="material-icons-outlined">
                    {folder.icon}
                  </Box>
                </ListItemIcon>
                <ListItemText primary={<IntlMessages id={folder.name} />} />
              </ListItem>
            ))}
          </Hidden>
        </List>
      </Box>
      {/* Compose Dialog Box */}
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        classes={{ paper: classes.dialog }}
        onClose={() => handleClose()}
      >
        <DialogTitle id="customized-dialog-title">
          <IntlMessages id="components.composer" />
        </DialogTitle>
        <DialogContent dividers>
          <Box mb={1}>
            <TextField fullWidth label="To" />
          </Box>
          <Box mb={1}>
            <TextField fullWidth label="CC" />
          </Box>
          <Box mb={1}>
            <TextField fullWidth label="BCC" />
          </Box>
          <Box mb={3}>
            <TextField fullWidth label="Subject" />
          </Box>
          <Box height="100%">
            <ReactQuill
              modules={modules}
              formats={formats}
              placeholder="Enter Your Message.."
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Box py={1} px={2}>
            <Box display="inline-block" mr={2}>
              <Button
                variant="outlined"
                className="primary-bg-btn"
                color="primary"
                onClick={() => handleClose()}
              >
                <Box
                  component="span"
                  fontSize="20px"
                  mr={1}
                  className="material-icons"
                >
                  cancel_schedule_send
                </Box>
                Cancel
              </Button>
            </Box>
            <Button
              variant="outlined"
              className="primary-bg-btn"
              color="primary"
              autoFocus
              onClick={() => handleClose()}
            >
              <Box
                component="span"
                fontSize="18px"
                mr={1}
                className="material-icons"
              >
                send
              </Box>
              Send
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EmailSidebar;
