import RequestContext from 'contexts/request';
import { gql } from 'graphql-request';
import { useContext } from 'react';
import { useQuery } from 'react-query';

const QUERY = gql`
  query {
    allProperties {
      id
      slug
      block
      lotNumber
      project 
      projectSlug
      avatar
      created
      modified
      isCondo
      isHighRise
      videoUrl
      owners {
        name
        email
        phone
      }
      gallery {
        url
        cdnUrl
        uuid
        token
      }
      address {
        street
        city
        province
        postal
      }
    }
  }
`;

const requestProperties = (client) => async () => {
  const data = await client.request(QUERY);
  return data.allProperties;
};

const useProperties = () => {
  const { client } = useContext(RequestContext);
  const response = useQuery('allProperties', requestProperties(client));
  return response.data || [];
};

export default useProperties;
