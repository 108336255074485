/**
 * Horizontal Timeline
 */
import { Box, Typography } from '@material-ui/core';
import { CustomCard } from 'components/GlobalComponents';
import moment from 'moment';
import React from 'react';

const HorizontalTimeline = ({ summary, start, description, attendees = [] }) => (
  <CustomCard title="Next Event" showDivider>
    <Box pt="24px" px={{ xs: '12px', lg: 0 }}>
      <div className="horizontal-timeline text-center">
        <div className="horizontal-timeline-list">
          <div className="horizontal-timeline-singular--item">
            <div className="horizontal-timeline-list--item-inner">
              <div className="horizontal-timeline--meta">
                <div>
                  <div className="meta-year">
                    {start.timeZone ? moment(start.dateTime).tz(start.timeZone).format('YYYY') : moment(start.dateTime).format('YYYY')}
                  </div>
                  <div>{start.timeZone ? moment(start.dateTime).tz(start.timeZone).format('MMM D') : moment(start.dateTime).format('MMM D')}</div>
                </div>
              </div>
              <Box maxWidth="480px" marginX="auto">
                <Typography variant="h6">{summary}</Typography>
                <Typography variant="body2">{description}</Typography>
                {attendees?.length > 0 && (
                  <Box marginTop={2}>
                    <Typography
                      variant="caption"
                      display="block"
                      style={{ fontWeight: 'bold' }}
                    >
                      Invitees:
                    </Typography>
                    <Typography variant="caption" display="block">
                      {attendees.map((u) => u.email).join(', ')}
                    </Typography>
                  </Box>
                )}
              </Box>
            </div>
          </div>
        </div>
      </div>
    </Box>
  </CustomCard>
);

export default HorizontalTimeline;
