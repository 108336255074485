import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import RingLoader from 'react-spinners/RingLoader';

export default function Loading() {
  return (
    <Grid
      spacing={0}
      alignItems="center"
      justify="center"
      style={{
        minWidth: '100%',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <RingLoader />
      <Typography variant="h3" style={{ color: '#a0a0a0' }}>
        Loading
      </Typography>
    </Grid>
  );
}
