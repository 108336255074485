import { Box, Grid, TextField, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import ReactPlayer from 'react-player';
import ReactQuill from 'react-quill';
import {
  generatePath,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import routes from '../../routes';
import BaseConfirmer from './BaseConfirmer.js';

const modules = {
  toolbar: [],
};
const formats = [
  'header',
  'font',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'align',
];

export const MessagePreview = ({ value }) => (
  <ReactQuill readOnly modules={modules} formats={formats} value={value} />
);

export const CalendlyPreview = (props) => {
  const { calendlyEvent, message } = props;
  return (
    <>
      <ReactQuill
        readOnly
        modules={modules}
        formats={formats}
        value={message}
      />
    </>
  );
};

export const PDFPreview = ({ url }) => (
  <Box padding={3} bgcolor="#F0F0F0" textAlign="center">
    <Document file={url}>
      <Page pageNumber={1} />
    </Document>
  </Box>
);

export const PhotoPreview = ({ url }) => (
  <Box padding={3} bgcolor="#F0F0F0" textAlign="center">
    <img height="320px" alt="preview" src={url} />
  </Box>
);

export const VideoPreview = ({ url }) => (
  <Box padding={3} bgcolor="#F0F0F0">
    <div className="player-wrapper">
      <ReactPlayer
        controls={true}
        url={url}
        className="react-player"
        width="540px"
        height="320px"
        style={{ margin: 'auto' }}
      />
    </div>
  </Box>
);

export const DocumentPreview = ({ fileInfo = { name: '', size: 0 } }) => {
  const [pages, setPages] = useState(0);
  const { mimeType } = fileInfo;
  const isPDF = mimeType.toLowerCase() === 'application/pdf';
  return isPDF ? (
    <Box maxHeight="600px" style={{ overflowY: 'scroll' }}>
      <Document
        file={fileInfo.cdnUrl}
        onLoadSuccess={({ numPages }) => setPages(numPages)}
      >
        {Array.from({ length: pages }, (_, idx) => (
          <>
            <Page key={idx + 1} pageNumber={idx + 1} />
            <Box height="32px" />
          </>
        ))}
      </Document>
    </Box>
  ) : (
    <Box padding={3}>
      <Typography variant="h6" align="center">
        Adding {fileInfo.name} ({Math.ceil(fileInfo.size / 1000)} KB) to
        selected properties.
      </Typography>
    </Box>
  );
};

export const RSVPPreview = ({ data }) => (
  <div>
    <Typography className="stepper-title" variant="h5">
      Event Information
    </Typography>
    <form noValidate>
      <Grid justify="center" alignItems="center" container spacing={2}>
        <Grid item xs={12} sm={12} lg={12}>
          <Box mb={2}>
            <TextField
              className="w-100"
              name="title"
              id="title"
              label="Title"
              value={data.title}
              disabled
            />
          </Box>
          <Box mb={2}>
            <TextField
              className="w-100"
              name="location"
              label="Location"
              id="location"
              value={data.location}
              disabled
            />
          </Box>
          <Box mb={2}>
            <TextField
              className="w-100"
              id="start"
              label="Start"
              name="start"
              type="datetime-local"
              value={moment(data.start).format('YYYY-MM-DDTHH:MM')}
              disabled
            />
          </Box>
          <Box mb={2}>
            <TextField
              className="w-100"
              id="end"
              label="End"
              name="end"
              type="datetime-local"
              value={moment(data.end).format('YYYY-MM-DDTHH:MM')}
              disabled
            />
          </Box>
          <Box mb={2}>
            <TextField
              className="w-100"
              multiline
              variant="filled"
              InputLabelProps={{ shrink: true }}
              rows={6}
              id="description"
              label="Description"
              name="description"
              value={data.description}
              disabled
            />
          </Box>
        </Grid>
      </Grid>
    </form>
  </div>
);

const Confirmer = (props) => {
  const match = useRouteMatch();
  const { fileType } = useParams();
  // debugger;
  return (
    <Switch>
      <Route
        path={generatePath(routes.composer.uploads, {
          fileType: 'messages',
        })}
      >
        <BaseConfirmer {...props} type="messages">
          <MessagePreview value={props?.data?.message} />
        </BaseConfirmer>
      </Route>

      <Route
        path={generatePath(routes.composer.uploads, {
          fileType: 'rsvps',
        })}
      >
        <BaseConfirmer {...props} type="rsvps">
          <CalendlyPreview
            message={props?.data?.message}
            calendlyEvent={props?.data?.calendlyEvent}
          />
        </BaseConfirmer>
      </Route>
      <Route
        path={generatePath(routes.composer.uploads, {
          fileType: 'events',
        })}
      >
        <BaseConfirmer {...props} type="events">
          <RSVPPreview data={props?.data?.eventInfo} />
        </BaseConfirmer>
      </Route>
      <Route
        path={generatePath(routes.composer.uploads, {
          fileType: 'photos',
        })}
      >
        <BaseConfirmer {...props} type="photos">
          <PhotoPreview url={props?.data?.fileInfo?.cdnUrl} />
        </BaseConfirmer>
      </Route>
      <Route
        path={generatePath(routes.composer.uploads, {
          fileType: 'videos',
        })}
      >
        <BaseConfirmer {...props} type="video">
          <VideoPreview url={props?.data?.fileInfo?.cdnUrl} />
        </BaseConfirmer>
      </Route>
      <Route
        path={generatePath(routes.composer.uploads, {
          fileType: 'files',
        })}
      >
        <BaseConfirmer {...props} type="files">
          <DocumentPreview fileInfo={props?.data?.fileInfo} />
        </BaseConfirmer>
      </Route>
    </Switch>
  );
};

export default Confirmer;
