/**
 * Modern Profile
 */
import { Box, Container, Grid } from '@material-ui/core';
import useProperty from 'api/useProperty';
import { CustomCard } from 'components/GlobalComponents';
import React from 'react';
import ReactPlayer from 'react-player';
import { useParams } from 'react-router-dom';
import Email from '../Profile/Components/emails';
import NextEvent from '../Profile/Components/event';
import ProfileGallery from './Components/ProfileGallery';
import RecentFollowers from './Components/RecentFollowes';
//Components
import UserDetail from './Components/UserDetail';
import moment from 'moment';

export default function Modern() {
  const { slug } = useParams();
  const { isLoading, isError, data, error } = useProperty(slug);
  const VIDEO = data?.videoUrl ? data?.videoUrl :
    process.env.REACT_APP_VIDEO ||
    'https://www.youtube.com/watch?v=ji5_-tV9pz0';

  return (
    <div className="hk-user-profile">
      <Container maxWidth="lg">
        <Box px={{ xs: '12px', lg: 0 }} className="page-space">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={8}>
              <Box mb={3}>
                <UserDetail {...data} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              {data?.photoGallery && (
                <Box>
                  <ProfileGallery imgs={data?.photoGallery} />
                </Box>
              )}
              <Box mb={3}>
                <RecentFollowers owners={data?.owners} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              <Box mb={3}>
                <CustomCard
                  showDivider={true}
                  title={data ? "Life at " + data?.project : ""}
                  cardClasses="recent-followers-wrap"
                >
                  <Box className={`checkbox-list-wrap`} pt={2}>
                    <ReactPlayer
                      url={VIDEO}
                      width="100%"
                      pip={true}
                      controls={true}
                    />
                  </Box>
                </CustomCard>
              </Box>
            </Grid>
            <Grid container spacing={3} style={{ margin: 0 }}>
              <Grid item xs={12} sm={6} md={4}>
                {/* <Documents documents={data?.documents} /> */}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Email slug={slug} />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                {data?.events?.length > 0 && (
                  <Box mb={3}>
                    <NextEvent {...data?.events ? data.events[0] : {}} />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}
