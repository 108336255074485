import RequestContext from 'contexts/request';
import { gql } from 'graphql-request';
import { useContext } from 'react';
import { useMutation } from 'react-query';

const MUTATION = gql`
  mutation ($slug: String!, $closing: String!) {
    updateClosingDate(slug: $slug, closing: $closing) {
      success
    }
  }
`;

const requestUploadClosingDate = (client) => async (variables) => {
  const data = await client.request(MUTATION, variables);
  return data.success;
};

const useUpdateClosingDate = () => {
  const { client } = useContext(RequestContext);
  const { mutate } = useMutation(requestUploadClosingDate(client));
  return mutate;
};

export default useUpdateClosingDate;
