import RequestContext from 'contexts/request';
import { gql } from 'graphql-request';
import { useContext } from 'react';
import { useMutation } from 'react-query';

const MUTATION = gql`
  mutation ($projectSlug: String!, $eventId: String!) {
    deleteGoogleCalendarEvent(projectSlug: $projectSlug, eventId: $eventId) {
      success
    }
  }
`;

const requestDeleteGoogleCalendarEvent = (client) => async (variables) => {
  const data = await client.request(MUTATION, variables);
  return data.success;
};

const useDeleteGoogleCalendarEvent = () => {
  const { client } = useContext(RequestContext);
  const { mutate } = useMutation(requestDeleteGoogleCalendarEvent(client));
  return mutate;
};

export default useDeleteGoogleCalendarEvent;
