/**
 * App Config File
 */

const NHRed = '#c6122f';

const AppConfig = {
  brandName: 'Hulk',
  copyRightText: 'Copyright © 2020 IRON Network, All Rights Reserved.',
  navCollapsed: true,
  mobileSidebarToggle: false,
  isDarkModeActive: false,
  isRtlActive: false,
  isHorizontalMenuActive: false,
  isMiniSidebarActive: false,
  selectedThemeColor: 'light-theme',
  locale: {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'usa.png',
  },
  // Default Theme Colors
  lightThemeColors: {
    secondary: NHRed,
    success: '#00d41a',
    info: '#00d0bd',
    warning: '#ffb70f',
    error: '#e53935',
    textPrimary: '#29303b',
    textSecondary: '#717275',
    divider: '#e0e0e0',
    icon: '#717275',
    bgPaper: '#FFFFFF',
    bgDefault: '#f3f7fa',
    white: '#ffffff',
    black: '#000000',
  },
  // Dark Theme Colors
  darkThemeColors: {
    secondary: NHRed,
    success: '#00d41a',
    info: '#00d0bd',
    warning: '#ffb70f',
    error: '#e53935',
    textPrimary: '#9d9daf',
    textSecondary: '#717275',
    divider: 'rgba(255,255,255,0.12)',
    icon: '#717275',
    bgPaper: '#27293c',
    bgDefault: '#1e1e2e',
    white: '#ffffff',
    black: '#000000',
  },
};

export default AppConfig;
