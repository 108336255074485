import RequestContext from 'contexts/request';
import { gql } from 'graphql-request';
import { useContext } from 'react';
import { useQuery } from 'react-query';

const QUERY = gql`
  query {
    allProjects {
      id
      slug
      name
      interactiveAbodeUrl
      avatar
      address {
        street
        city
        province
        postal
      }
    }
  }
`;

const requestProjects = (client) => async () => {
  const data = await client.request(QUERY);
  return data.allProjects;
};

const useProjects = () => {
  const { client } = useContext(RequestContext);
  const response = useQuery('allProjects', requestProjects(client));
  return response.data || [];
};

export default useProjects;
