import RequestContext from 'contexts/request';
import { gql } from 'graphql-request';
import { useContext } from 'react';
import { useQuery } from 'react-query';

const QUERY = gql`
  query ($id: String!) {
    messageThread(id: $id) {
      id
      subject
      cc
      bcc
      body
      sender {
        initials
        id
        email
        name
        firstName
        lastName
      }
      created
      sentAt
      readAt
      parent {
        id
      }
      property {
        id
        lotNumber
        groupInbox
        project
        slug
      }
      children
      level
      files {
        id
        files {
          baseUrl
          token
          mimeType
          secureUrl
          downloadUrl
          uuid
          isImage
          cdnUrl
          originalFileUrl
          originalFilename
        }
      }
    }
  }
`;

const requestThread = (client) => (id) => async () => {
  const { messageThread } = await client.request(QUERY, { id });
  return messageThread;
};

const useMessageThread = (id) => {
  const { client } = useContext(RequestContext);
  return useQuery(['messageThread', id], requestThread(client)(id));
};

export default useMessageThread;
