import RequestContext from 'contexts/request';
import { gql } from 'graphql-request';
import { useContext } from 'react';
import { useMutation } from 'react-query';

const MUTATION = gql`
  mutation replyToMessage(
    $body: String!
    $replyTo: String!
    $cc: [String]
    $bcc: [String]
  ) {
    replyToMessage(body: $body, replyTo: $replyTo, cc: $cc, bcc: $bcc) {
      success
    }
  }
`;

const requestReplyToMessage = (client) => async (variables) => {
  const data = await client.request(MUTATION, variables);
  return data.sent;
};

const useReplyToMessage = () => {
  const { client } = useContext(RequestContext);
  const { mutate } = useMutation(requestReplyToMessage(client));
  return mutate;
};

export default useReplyToMessage;
