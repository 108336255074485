/**
 * Profile Gallery
 */
import { Dialog, GridList, GridListTile } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// Component
import { CustomCard } from 'components/GlobalComponents';
import ImageViewer from 'components/ImageViewer';
import React, { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    '& p-0': {
      padding: 0,
    },
  },
}));

// XXX: We only really have room for at most 6 gallery images
export default function ProfileGallery({ imgs }) {
  const classes = useStyles();
  const [viewerOpen, setViewerOpen] = useState(false);

  return (
    <>
      <Dialog
        maxWidth="lg"
        open={viewerOpen}
        onClose={() => setViewerOpen(false)}
      >
        <ImageViewer images={imgs} />
      </Dialog>
      <div
        className={`profile-gallery ${classes.root}`}
        onClick={() => setViewerOpen(true)}
      >
        <CustomCard cardClasses="p-0">
          <GridList cellHeight={144} cols={3} style={{ height: 3 * 140 }}>
            {imgs.map((img) => (
              <GridListTile
                key={img.url}
                cols={1}
                style={{ width: '33.3333%', height: '33.3333%' }}
              >
                <img src={img.cdnUrl} alt="gallery-img" />
              </GridListTile>
            ))}
          </GridList>
        </CustomCard>
      </div>
    </>
  );
}
