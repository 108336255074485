/**
 * Modern Profile
 */
import {
  Avatar,
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  Link,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { ImCompass } from 'react-icons/im';
import { BiBed, BiBath, BiInfinite } from 'react-icons/bi';
import { BsBoundingBoxCircles } from 'react-icons/bs';
import { FaToilet } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';
import EventIcon from '@material-ui/icons/Event';
import FolderIcon from '@material-ui/icons/Folder';
import MailIcon from '@material-ui/icons/Mail';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
import { KeyboardDatePicker } from '@material-ui/pickers';
import useAllOwners from 'api/useAllOwners';
import useProject from 'api/useProject';
import useUpdateAddress from 'api/useUpdateAddress';
import useUpdateClosingDate from 'api/useUpdateClosingDate';
import useUpdateOwners from 'api/useUpdateOwners';
import useUser from 'api/useUser';
import { CustomCard } from 'components/GlobalComponents';
import MaterialTable from 'material-table';
import Image from 'material-ui-image';
import moment from 'moment';
import React, { useState } from 'react';
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import routes from '../../../routes';

export default function Modern() {
  const { entity, slug } = useParams();
  const { isLoading, isError, data, error } = useProject(slug);

  const { isLoading: ownersIsLoading, data: ownerData } = useAllOwners();

  const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTableCell-paddingNone': {
        padding: '0 16px',
      },
      '& .MuiTableCell-body': {
        lineHeight: 1,
      },
      '& .MuiToolbar-root': {
        paddingRight: 20,
        '& >div:first-child': {
          fontSize: '1.25rem',
          fontFamily: "'Roboto', sans-serif",
          fontWeight: 500,
        },
      },
    },
  }));
  const classes = useStyles();

  const addressOf = (property) => property.address || {};

  const address =
    data && data.address
      ? `${data.address.city}, ${data.address.province}`
      : data?.raw;

  const [selectedRow, setSelectedRow] = useState();

  const columns = [
    {
      title: data?.isCondo ? 'Unit' : 'Lot',
      field: 'lotNumber',
      type: 'numeric',
      defaultSort: 'asc',
      render: (rowData) => {
        return <Avatar>{rowData.lotNumber}</Avatar>;
      },
    },
    {
      title: 'Block',
      field: 'block',
      defaultSort: 'asc',
      render: (rowData) => {
        return rowData?.block && <Avatar>{rowData.block}</Avatar>;
      },
    },
    {
      title: 'Address',
      field: 'address',
      render: (rowData) =>
        addressOf(rowData).street?.length > 2
          ? addressOf(rowData).street
          : addressOf(rowData).raw,
    },
    {
      title: 'Owners',
      field: 'owners',
      render: (rowData) =>
        rowData.owners.map((owner) => (
          <Box
            mb={1}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <Chip
              avatar={<Avatar>{owner.name[0]}</Avatar>}
              label={owner.name}
              variant="outlined"
              size="small"
              style={{ marginBottom: 2 }}
            />
            {owner.login && (
              <Typography variant="subtitle2" style={{ paddingLeft: 8 }}>
                Last login: {owner.login}
              </Typography>
            )}
          </Box>
        )),
    },
    {
      title: data?.isCondo ? 'Occupancy Date' : 'Closing Date',
      field: 'closing',
      render: (rowData) => (
        <>
          {rowData.closing !== null ? (
            <>
              <Typography variant="caption">
                {moment(rowData.closing).format('MMM D, YYYY')}
              </Typography>
              {rowData.lotNumber === (selectedRow && selectedRow.lotNumber) ? (
                <span className="custom-table-arrow">
                  <i className="material-icons">arrow_forward_ios</i>
                </span>
              ) : null}
            </>
          ) : (
            <Typography variant="caption">N/A</Typography>
          )}
        </>
      ),
    },
  ];

  const user = useUser();
  const match = useRouteMatch('/projects/:slug');
  const defaultPropertySlug = data?.properties?.[0]?.slug; // XXX: this will change later
  const unreadMessages = (user && user.unreadMessages) || 0;
  const unreadEvents = (user && user.unreadEvents) || 0;
  const history = useHistory();
  const updateClosingDate = useUpdateClosingDate();
  const updateAddress = useUpdateAddress();
  const updateOwners = useUpdateOwners();
  const [editAddress, setEditAddress] = useState(false);
  const [editOwners, setEditOwners] = useState(false);
  const [editClosingDate, setEditClosingDate] = useState(false);
  const [street, setStreet] = useState();
  const [city, setCity] = useState();
  const [province, setProvince] = useState();
  const [closingDate, setClosingDate] = useState();
  const [owners, setOwners] = useState();

  return (
    <div className="hk-user-profile">
      <Container maxWidth="lg">
        <Box px={{ xs: '12px', lg: 0 }} className="page-space">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={8}>
              <Box mb={3}>
                <div className="mod-profile-wrap">
                  <Box pt={3} className="mod-profile">
                    {isLoading ? (
                      <Skeleton
                        animation="wave"
                        variant="rect"
                        width="100%"
                        height={398}
                        className={classes.media}
                      />
                    ) : (
                      <Image
                        aspectRatio={1.71}
                        imageStyle={{ height: '450px !important' }}
                        style={{ height: '450px !important' }}
                        src={`https://cdn.homeownerportal.io/${data?.avatarUuid}/-/resize/x450/-/format/auto/-/quality/smart/-/progressive/yes/?token=${data?.avatarToken}`}
                        title={data?.name}
                      />
                    )}
                    <Box className="mod-profile-detail">
                      <Box>
                        <Box>
                          <Typography variant="h6" style={{ height: 45 }}>
                            {data && data.name}
                          </Typography>
                          <div className="mod-profile-meta mod-profile-bio">
                            <ul>
                              <li>
                                <span>{address}</span>
                              </li>
                            </ul>
                          </div>
                        </Box>
                        <Box marginTop="24px">
                          <BottomNavigation
                            showLabels
                            onChange={(evt, val) =>
                              defaultPropertySlug && history.push(val)
                            }
                          >
                            <BottomNavigationAction
                              component={Link}
                              href={generatePath(routes.entity.inboxList, {
                                entity: entity,
                                slug: slug,
                                inboxType: 'messages',
                              })}
                              label="Messages"
                              icon={
                                <Badge
                                  badgeContent={unreadMessages}
                                  color="secondary"
                                >
                                  <MailIcon />
                                </Badge>
                              }
                            />
                            <BottomNavigationAction
                              component={Link}
                              href={generatePath(routes.entity.events, {
                                entity: entity,
                                slug: slug,
                                inboxType: 'events',
                              })}
                              label="Events"
                              icon={
                                <Badge
                                  badgeContent={unreadEvents}
                                  color="secondary"
                                >
                                  <EventIcon />
                                </Badge>
                              }
                            />
                            <BottomNavigationAction
                              component={Link}
                              href={generatePath(routes.entity.photos, {
                                entity: entity,
                                slug: slug,
                                inboxType: 'photos',
                              })}
                              label="Photos"
                              icon={<PhotoLibraryIcon />}
                            />
                            <BottomNavigationAction
                              component={Link}
                              // href={generatePath(routes.entity.inboxList, {
                              //   entity: entity,
                              //   slug: slug,
                              //   inboxType: "files",
                              // })}
                              label="Files"
                              icon={<FolderIcon />}
                            />
                          </BottomNavigation>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </div>
              </Box>
            </Grid>
            <Grid item xs={12}>
              {data && (
                <Grid container spacing={0} className="res-custom-table">
                  <Grid item xs={12} sm={12} md={8}>
                    <Box className={`custom-table-wrap ${classes.root}`}>
                      <MaterialTable
                        title="Properties"
                        columns={columns}
                        data={data.properties}
                        options={{
                          rowStyle: (rowData) => ({
                            backgroundColor:
                              selectedRow &&
                              selectedRow.lotNumber === rowData.lotNumber
                                ? '#f3f7fa'
                                : '#FFF',
                          }),
                          pageSize: 10,
                        }}
                        onRowClick={(evt, row) => {
                          setSelectedRow(row);
                          setClosingDate(row.closing);
                          setStreet(addressOf(row).street);
                          setCity(addressOf(row).city);
                          setProvince(addressOf(row).province);
                          setOwners(row?.owners);
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <CustomCard cardClasses="preview-panel">
                      {selectedRow ? (
                        <>
                          <Box mb={2} textAlign="center">
                            <img
                              height="200px"
                              style={{ backgroundColor: 'lightgrey' }}
                              src={`https://cdn.homeownerportal.io/${selectedRow?.avatarUuid}/-/resize/680x/-/format/auto/-/quality/smart/-/progressive/yes/?token=${selectedRow?.avatarToken}`}
                              alt="house"
                            />
                            <Box display="flex">
                              <Box
                                display="flex"
                                justifyContent="center"
                                flexDirection="column"
                                style={{ width: '100%' }}
                              >
                                <Box fontWeight={500}>
                                  {addressOf(selectedRow).street?.length > 2
                                    ? addressOf(selectedRow).street
                                    : addressOf(selectedRow).raw}
                                </Box>
                                <Box>
                                  <Typography variant="subtitle2">
                                    {addressOf(selectedRow).city},{' '}
                                    {addressOf(selectedRow).province}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          <Box mb={2} textAlign="center">
                            <Tooltip title="Edit property" placement="bottom">
                              <Link
                                href={`${generatePath(routes.composer.uploads, {
                                  fileType: 'messages',
                                })}#property=${selectedRow.slug}`}
                              >
                                <IconButton
                                  className="preview-icon-btn"
                                  variant="outlined"
                                  size="small"
                                >
                                  <i className="material-icons-outlined">
                                    send
                                  </i>
                                </IconButton>
                              </Link>
                            </Tooltip>
                            <Tooltip title="View Messages" placement="bottom">
                              <Link
                                href={generatePath(routes.entity.inboxList, {
                                  entity: 'properties',
                                  slug: selectedRow.slug,
                                  inboxType: 'messages',
                                })}
                              >
                                <IconButton
                                  className="preview-icon-btn"
                                  variant="outlined"
                                >
                                  <i className="material-icons-outlined">
                                    mail
                                  </i>
                                </IconButton>
                              </Link>
                            </Tooltip>
                            <Tooltip title="View Property" placement="bottom">
                              <Link
                                href={generatePath(routes.entity.detail, {
                                  entity: 'properties',
                                  slug: selectedRow.slug,
                                })}
                              >
                                <IconButton
                                  className="preview-icon-btn"
                                  variant="outlined"
                                >
                                  <i className="material-icons-outlined">
                                    house
                                  </i>
                                </IconButton>
                              </Link>
                            </Tooltip>
                            <Tooltip title="View Events" placement="bottom">
                              <Link
                                href={generatePath(routes.entity.events, {
                                  entity: 'properties',
                                  slug: selectedRow.slug,
                                  inboxType: 'events',
                                })}
                              >
                                <IconButton
                                  className="preview-icon-btn"
                                  variant="outlined"
                                >
                                  <i className="material-icons-outlined">
                                    event
                                  </i>
                                </IconButton>
                              </Link>
                            </Tooltip>
                            <Tooltip title="View Documents" placement="bottom">
                              <Link
                                href={generatePath(routes.entity.pdfs, {
                                  entity: 'properties',
                                  slug: selectedRow.slug,
                                  inboxType: 'documents',
                                })}
                              >
                                <IconButton
                                  className="preview-icon-btn"
                                  variant="outlined"
                                >
                                  <i className="material-icons-outlined">
                                    description
                                  </i>
                                </IconButton>
                              </Link>
                            </Tooltip>
                          </Box>
                          <Divider />
                          <Box mb={2}>
                            <Box display="flex" my={3} alignItems="center">
                              <Box flex={1} display="flex"></Box>
                              <Box flex={2} display="flex">
                                <Typography
                                  variant="body2"
                                  style={{ margin: 0 }}
                                >
                                  {data?.isCondo ? 'Unit :' : 'Lot :'}
                                </Typography>
                              </Box>
                              <Box flex={4} display="flex">
                                <Avatar>{selectedRow?.lotNumber}</Avatar>
                              </Box>
                            </Box>
                          </Box>
                          {selectedRow?.block && (
                            <Box mb={2}>
                              <Box display="flex" my={3} alignItems="center">
                                <Box flex={1} display="flex"></Box>
                                <Box flex={2} display="flex">
                                  <Typography
                                    variant="body2"
                                    style={{ margin: 0 }}
                                  >
                                    Block :
                                  </Typography>
                                </Box>
                                <Box flex={4} display="flex">
                                  <Avatar>{selectedRow?.block}</Avatar>
                                </Box>
                              </Box>
                            </Box>
                          )}

                          <Box mb={2}>
                            {data?.isCondo && (
                              <>
                                <Box display="flex" my={3} alignItems="center">
                                  <Box flex={1} display="flex"></Box>
                                  <Box
                                    flex={2}
                                    display="flex"
                                    flexDirection="column"
                                  >
                                    <Box display="flex">
                                      <BiBed size={24} />
                                    </Box>
                                    <Box>
                                      <Typography
                                        variant="body2"
                                        style={{ margin: 0 }}
                                      >
                                        Bedrooms:
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Box flex={4} display="flex">
                                    <Avatar>{selectedRow?.bedrooms}</Avatar>
                                  </Box>
                                </Box>
                                <Box display="flex" my={3} alignItems="center">
                                  <Box flex={1} display="flex"></Box>
                                  <Box
                                    flex={2}
                                    display="flex"
                                    flexDirection="column"
                                  >
                                    <Box display="flex">
                                      <BiBath size={24} />
                                    </Box>
                                    <Box>
                                      <Typography
                                        variant="body2"
                                        style={{ margin: 0 }}
                                      >
                                        Bathrooms:
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Box flex={4} display="flex">
                                    <Avatar>{selectedRow?.bathrooms}</Avatar>
                                  </Box>
                                </Box>
                                <Box display="flex" my={3} alignItems="center">
                                  <Box flex={1} display="flex"></Box>
                                  <Box
                                    flex={2}
                                    display="flex"
                                    flexDirection="column"
                                  >
                                    <Box display="flex">
                                      <BsBoundingBoxCircles size={24} />
                                    </Box>
                                    <Box>
                                      <Typography
                                        variant="body2"
                                        style={{ margin: 0 }}
                                      >
                                        Dens:
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Box flex={4} display="flex">
                                    <Avatar>{selectedRow?.dens}</Avatar>
                                  </Box>
                                </Box>
                                <Box display="flex" my={3} alignItems="center">
                                  <Box flex={1} display="flex"></Box>
                                  <Box
                                    flex={2}
                                    display="flex"
                                    flexDirection="column"
                                  >
                                    <Box display="flex">
                                      <BiInfinite size={24} />
                                    </Box>
                                    <Box>
                                      <Typography
                                        variant="body2"
                                        style={{ margin: 0 }}
                                      >
                                        Flex:
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Box flex={4} display="flex">
                                    <Avatar>{selectedRow?.flex}</Avatar>
                                  </Box>
                                </Box>
                                <Box display="flex" my={3} alignItems="center">
                                  <Box flex={1} display="flex"></Box>
                                  <Box
                                    flex={2}
                                    display="flex"
                                    flexDirection="column"
                                  >
                                    <Box display="flex">
                                      <FaToilet size={24} />
                                    </Box>
                                    <Box>
                                      <Typography
                                        variant="body2"
                                        style={{ margin: 0 }}
                                      >
                                        ½ Baths:
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Box flex={4} display="flex">
                                    <Avatar>
                                      {selectedRow?.halfBathrooms}
                                    </Avatar>
                                  </Box>
                                </Box>
                                <Box display="flex" my={3} alignItems="center">
                                  <Box flex={1} display="flex"></Box>
                                  <Box
                                    flex={2}
                                    display="flex"
                                    flexDirection="column"
                                  >
                                    <Box display="flex">
                                      <ImCompass size={24} />
                                    </Box>
                                    <Box>
                                      <Typography
                                        variant="body2"
                                        style={{ margin: 0 }}
                                      >
                                        Orientation:
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Box flex={4} display="flex">
                                    <Avatar>{selectedRow?.facing}</Avatar>
                                  </Box>
                                </Box>
                              </>
                            )}
                          </Box>
                          <Divider />

                          <Box mb={2}>
                            <Box my={3}>
                              <Box display="flex" my={1} alignItems="center">
                                <Box flex={1} display="flex">
                                  {!editAddress ? (
                                    <Tooltip
                                      title="Edit address"
                                      placement="bottom"
                                    >
                                      <IconButton
                                        variant="outlined"
                                        onClick={() => {
                                          setEditAddress(true);
                                        }}
                                        style={{
                                          padding: '4px !important',
                                          width: 30,
                                          height: 30,
                                          border: '1px solid grey',
                                          margin: '0px !important',
                                        }}
                                      >
                                        <i
                                          className="material-icons-outlined"
                                          style={{ fontSize: 16 }}
                                        >
                                          edit
                                        </i>
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <Tooltip
                                      title="Save address"
                                      placement="bottom"
                                    >
                                      <IconButton
                                        variant="outlined"
                                        onClick={() => {
                                          setEditAddress(false);
                                          updateAddress(
                                            {
                                              slug: selectedRow?.slug,
                                              street: street,
                                              city: city,
                                              province: province,
                                            },
                                            {
                                              onSuccess: () => {
                                                console.info('SUCCESS');
                                              },
                                              onError: () => {
                                                console.error('ERROR');
                                              },
                                            }
                                          );
                                        }}
                                        style={{
                                          padding: '4px !important',
                                          width: 30,
                                          height: 30,
                                          border: '1px solid grey',
                                          margin: '0px !important',
                                        }}
                                      >
                                        <i
                                          className="material-icons-outlined"
                                          style={{ fontSize: 16 }}
                                        >
                                          save
                                        </i>
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </Box>
                                <Box flex={2} display="flex">
                                  <Typography
                                    variant="body2"
                                    style={{ margin: 0 }}
                                  >
                                    Street :
                                  </Typography>
                                </Box>
                                <Box flex={4} display="flex">
                                  <TextField
                                    fullWidth
                                    InputProps={{
                                      style: { fontSize: 11 },
                                      disableUnderline: !editAddress,
                                    }}
                                    id="street"
                                    value={street}
                                    onChange={(event) => {
                                      setStreet(event.target.value);
                                    }}
                                  />
                                </Box>
                              </Box>
                              <Box display="flex" my={1}>
                                <Box flex={1} display="flex"></Box>
                                <Box flex={2} display="flex">
                                  <Typography
                                    variant="body2"
                                    style={{ margin: 0 }}
                                  >
                                    City :
                                  </Typography>
                                </Box>
                                <Box flex={4} display="flex">
                                  <TextField
                                    fullWidth
                                    InputProps={{
                                      style: { fontSize: 11 },
                                      disableUnderline: !editAddress,
                                    }}
                                    id="city"
                                    value={city}
                                    onChange={(event) => {
                                      setCity(event.target.value);
                                    }}
                                  />
                                </Box>
                              </Box>
                              <Box display="flex" my={1} alignItems="center">
                                <Box flex={1} display="flex"></Box>
                                <Box flex={2} display="flex">
                                  <Typography
                                    fullWidth
                                    variant="body2"
                                    style={{ margin: 0 }}
                                  >
                                    Province :
                                  </Typography>
                                </Box>
                                <Box flex={4} display="flex">
                                  <TextField
                                    fullWidth
                                    InputProps={{
                                      style: { fontSize: 11 },
                                      disableUnderline: !editAddress,
                                    }}
                                    id="province"
                                    value={province}
                                    onChange={(event) => {
                                      setProvince(event.target.value);
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Box>

                            <Box display="flex" my={3}>
                              <Box flex={1} display="flex">
                                {!editOwners ? (
                                  <Tooltip
                                    title="Edit owners"
                                    placement="bottom"
                                  >
                                    <IconButton
                                      variant="outlined"
                                      style={{
                                        padding: '4px !important',
                                        width: 30,
                                        height: 30,
                                        border: '1px solid grey',
                                        margin: '0px !important',
                                      }}
                                      onClick={() => {
                                        setEditOwners(true);
                                      }}
                                    >
                                      <i
                                        className="material-icons-outlined"
                                        style={{ fontSize: 16 }}
                                      >
                                        edit
                                      </i>
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    title="Save closing date"
                                    placement="bottom"
                                  >
                                    <IconButton
                                      variant="outlined"
                                      onClick={() => {
                                        setEditOwners(false);
                                        updateOwners(
                                          {
                                            slug: selectedRow.slug,
                                            owners: owners.map(
                                              (own) => own.email
                                            ),
                                          },
                                          {
                                            onSuccess: () => {
                                              console.info('SUCCESS');
                                            },
                                            onError: () => {
                                              console.error('ERROR');
                                            },
                                          }
                                        );
                                        // updateClosingDate(
                                        //   {
                                        //     slug: selectedRow?.slug,
                                        //     closing: closingDate,
                                        //   },
                                        //   {
                                        //     onSuccess: () => {
                                        //       console.info('SUCCESS');
                                        //     },
                                        //     onError: () => {
                                        //       console.error('ERROR');
                                        //     },
                                        //   }
                                        // );
                                      }}
                                      style={{
                                        padding: '4px !important',
                                        width: 30,
                                        height: 30,
                                        border: '1px solid grey',
                                        margin: '0px !important',
                                      }}
                                    >
                                      <i
                                        className="material-icons-outlined"
                                        style={{ fontSize: 16 }}
                                      >
                                        save
                                      </i>
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </Box>
                              <Box flex={2} display="flex" mt={1}>
                                <Typography
                                  variant="body2"
                                  style={{ margin: 0 }}
                                >
                                  Owners :
                                </Typography>
                              </Box>
                              <Box
                                flex={4}
                                display="flex"
                                flexDirection="column"
                                mt={1}
                              >
                                <Autocomplete
                                  multiple
                                  autoComplete
                                  disabled={!editOwners}
                                  options={ownerData}
                                  onChange={(event, reason) => {
                                    setOwners(reason);
                                  }}
                                  value={owners}
                                  defaultValue={[]}
                                  getOptionLabel={(option) => option.name}
                                  renderInput={(params) => {
                                    return (
                                      <TextField
                                        {...params}
                                        placeholder="Add owners"
                                      />
                                    );
                                  }}
                                />
                              </Box>
                            </Box>

                            <Box display="flex" my={3}>
                              <Box flex={1} display="flex">
                                {!editClosingDate ? (
                                  <Tooltip
                                    title="Edit closing"
                                    placement="bottom"
                                  >
                                    <IconButton
                                      variant="outlined"
                                      style={{
                                        padding: '4px !important',
                                        width: 30,
                                        height: 30,
                                        border: '1px solid grey',
                                        margin: '0px !important',
                                      }}
                                      onClick={() => {
                                        setEditClosingDate(true);
                                      }}
                                    >
                                      <i
                                        className="material-icons-outlined"
                                        style={{ fontSize: 16 }}
                                      >
                                        edit
                                      </i>
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    title="Save closing date"
                                    placement="bottom"
                                  >
                                    <IconButton
                                      variant="outlined"
                                      onClick={() => {
                                        setEditClosingDate(false);
                                        updateClosingDate(
                                          {
                                            slug: selectedRow?.slug,
                                            closing: closingDate,
                                          },
                                          {
                                            onSuccess: () => {
                                              console.info('SUCCESS');
                                            },
                                            onError: () => {
                                              console.error('ERROR');
                                            },
                                          }
                                        );
                                      }}
                                      style={{
                                        padding: '4px !important',
                                        width: 30,
                                        height: 30,
                                        border: '1px solid grey',
                                        margin: '0px !important',
                                      }}
                                    >
                                      <i
                                        className="material-icons-outlined"
                                        style={{ fontSize: 16 }}
                                      >
                                        save
                                      </i>
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </Box>
                              <Box flex={2} display="flex" mt={1}>
                                <Typography
                                  variant="body2"
                                  style={{ margin: 0 }}
                                >
                                  Closing :
                                </Typography>
                              </Box>
                              <Box
                                flex={4}
                                display="flex"
                                flexDirection="column"
                                mt={1}
                              >
                                <KeyboardDatePicker
                                  disableToolbar
                                  disabled={!editClosingDate}
                                  variant="inline"
                                  format="MM/DD/yyyy"
                                  id="date-picker-inline"
                                  value={closingDate}
                                  onChange={(date) => {
                                    setClosingDate(date);
                                  }}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change closing date',
                                  }}
                                />
                              </Box>
                            </Box>
                          </Box>
                        </>
                      ) : null}
                    </CustomCard>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                paddingLeft: '0px',
                paddingRight: '0px',
                paddingTop: '16px',
              }}
            >
              {/* <HorizontalTimeline /> */}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}
