import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AlarmOffIcon from '@material-ui/icons/AlarmOff';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CreateIcon from '@material-ui/icons/Create';
import EmailIcon from '@material-ui/icons/Email';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import HouseIcon from '@material-ui/icons/House';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import SubjectIcon from '@material-ui/icons/Subject';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { DateTimePicker } from '@material-ui/pickers';
import { Widget } from '@uploadcare/react-widget';
import useProperties from 'api/useProperties';
import useUser from 'api/useUser';
import ChipInput from 'material-ui-chip-input';
import * as QueryString from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { AiOutlineFileImage, AiOutlineFileText } from 'react-icons/ai';
import ReactQuill from 'react-quill';
import {
  generatePath,
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import * as yup from 'yup';
import routes from '../../routes';
import UnitList from './UnitList';

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link'],
    ['clean'],
    [{ align: [] }],
  ],
};
const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'align',
];

const slugify = (text) => {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

export const RSVPComposer = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { setCalendlyEvent, calendlyEvent, setMessage } = props;

  const location = useLocation();
  const qs = QueryString.parse(location.search);

  const user = useUser();

  const selectedProject = user?.projects.find(
    (x) =>
      x.slug === qs.project ||
      x.slug === qs.projects ||
      slugify(x.name) === qs.project
  );

  return (
    <>
      {selectedProject ? (
        <>
          <Box py={3}>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                Invite owners to book one of the following:
              </FormLabel>
              <RadioGroup
                aria-label="calendly event"
                name="calendly_event"
                value={1}
                onChange={(evt) => {
                  setCalendlyEvent(evt.target.value);
                }}
                style={{ marginTop: 10, marginLeft: 20 }}
              >
                {selectedProject.calendlyEvents?.map((evt, idx) => {
                  return (
                    <FormControlLabel
                      value={evt.schedulingUrl}
                      control={
                        <Radio checked={calendlyEvent === evt?.schedulingUrl} />
                      }
                      label={`${evt.name} (${evt.duration} min)`}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </Box>
          <Box mb={1}>
            <Typography variant="caption">
              Optional message that will be sent to owners in addition to the
              information already provided by the calendly event.
            </Typography>
          </Box>
          <ReactQuill
            modules={isMobile ? { toolbar: false } : modules}
            formats={formats}
            placeholder="Enter Your Message..."
            {...props}
            onChange={(evt) => {
              setMessage(evt);
            }}
          />
        </>
      ) : (
        <Box pt={2}>
          <Typography
            variant="h3"
            style={{ color: 'grey', fontSize: '1.4rem' }}
          >
            * Please select a project to begin
          </Typography>
        </Box>
      )}
    </>
  );
};

export const MessageComposer = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Typography variant="subtitle2">
        <CreateIcon /> Message
      </Typography>
      <ReactQuill
        modules={isMobile ? { toolbar: false } : modules}
        formats={formats}
        placeholder="Enter Your Message..."
        {...props}
      />
    </>
  );
};

export const EventForm = ({
  data,
  onChange,
  errors,
  setErrors,
  touched,
  setTouched,
}) => {
  const [title, setTitle] = useState(data?.title);
  const [description, setDescription] = useState(data?.description);
  const [eventLocation, setEventLocation] = useState(data?.eventLocation || '');
  const [start, setStart] = useState(data?.start || new Date());
  const [end, setEnd] = useState(data?.end || new Date());

  // const [touched, setTouched] = useState({});
  // const [errors, setErrors] = useState({});

  useEffect(() => {
    Promise.all([
      yup
        .object()
        .shape({ title: yup.string().required() })
        .validate({ title })
        .then(() => ({}))
        .catch((e) => ({ title: e.errors })),
      yup
        .object()
        .shape({ description: yup.string().required() })
        .validate({ description })
        .then(() => ({}))
        .catch((e) => ({ description: e.errors })),
    ])
      .then((errArray) => Object.assign({}, ...errArray))
      .then(setErrors);
  }, [title, description]);

  useEffect(() => {
    if (Object.values(errors).length === 0) {
      onChange({ title, description, location: eventLocation, start, end });
    }
  }, [title, description, eventLocation, start, end, errors]);

  return (
    <div>
      <Typography className="stepper-title" variant="h5">
        Event Information
      </Typography>
      <form noValidate>
        <Grid justify="center" alignItems="center" container spacing={2}>
          <Grid item xs={12} sm={12} lg={12}>
            <Box mb={2}>
              <Typography variant="subtitle2">
                <SubjectIcon />* Title
              </Typography>
              <TextField
                className="w-100"
                required
                variant="outlined"
                name="title"
                id="title"
                value={title}
                onChange={(evt) => {
                  setTitle(evt.target.value);
                }}
                autoFocus
                onBlur={() => setTouched((t) => ({ ...t, title: true }))}
                error={touched.title && errors.title && errors.title[0]}
                helperText={touched.title && errors.title && errors.title[0]}
              />
            </Box>
            <Box mb={2}>
              <Typography variant="subtitle2">
                <LocationOnIcon /> Location
              </Typography>
              <TextField
                autoFocus
                required
                className="w-100"
                name="location"
                variant="outlined"
                id="location"
                value={eventLocation}
                onChange={(evt) => setEventLocation(evt.target.value)}
                onBlur={() => {
                  setTouched((t) => ({ ...t, eventLocation: true }));
                }}
                error={
                  touched.eventLocation &&
                  errors.eventLocation &&
                  errors.eventLocation[0]
                }
                helperText={
                  touched.eventLocation &&
                  errors.eventLocation &&
                  errors.eventLocation[0]
                }
              />
            </Box>
            <Box mb={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2">
                    <AlarmOnIcon /> Start
                  </Typography>

                  <DateTimePicker
                    // disableToolbar
                    // disabled={!editClosingDate}
                    // format="MM/DD/yyyy"
                    autoOk
                    fullWidth
                    showTodayButton
                    inputVariant="outlined"
                    variant="inline"
                    id="date-picker-inline"
                    value={start}
                    onChange={(date) => {
                      setStart(date);
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change start date',
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2">
                    <AlarmOffIcon /> End
                  </Typography>
                  <DateTimePicker
                    // disableToolbar
                    // disabled={!editClosingDate}
                    // format="MM/DD/yyyy"
                    fullWidth
                    autoOk
                    showTodayButton
                    inputVariant="outlined"
                    variant="inline"
                    id="date-picker-inline"
                    value={end}
                    onChange={(date) => {
                      setEnd(date);
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change end date',
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mb={2}>
              <Typography variant="subtitle2">
                <CreateIcon />* Description
              </Typography>
              <TextField
                className="w-100"
                required
                multiline
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                rows={6}
                id="description"
                name="description"
                value={description}
                onChange={(evt) => setDescription(evt.target.value)}
                onBlur={() => setTouched((t) => ({ ...t, description: true }))}
                error={
                  touched.description &&
                  errors.description &&
                  errors.description[0]
                }
                helperText={
                  touched.description &&
                  errors.description &&
                  errors.description[0]
                }
              />
            </Box>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export const PhotoUploader = ({ onSelect }) => (
  <Box textAlign="center" paddingTop={2}>
    <Widget
      imagesOnly={true}
      publicKey={process.env.REACT_APP_UPLOADCARE_PUBLIC_KEY}
      onFileSelect={(p) => p.done(onSelect)}
    />
  </Box>
);

export const DocumentUploader = ({ onSelect }) => (
  <Box textAlign="center" paddingTop={5}>
    <Widget
      publicKey={process.env.REACT_APP_UPLOADCARE_PUBLIC_KEY}
      onFileSelect={(p) => p.done(onSelect)}
    />
  </Box>
);

export const PdfUploader = ({ onSelect }) => (
  <Box textAlign="center" paddingTop={2}>
    <Widget
      inputAcceptTypes={['application/pdf']}
      publicKey={process.env.REACT_APP_UPLOADCARE_PUBLIC_KEY}
      onFileSelect={(p) => p.done(onSelect)}
    />
  </Box>
);

export const VideoUploader = ({ onSelect }) => (
  <Box textAlign="center" paddingTop={2}>
    <Widget
      inputAcceptTypes={['video/*']}
      publicKey={process.env.REACT_APP_UPLOADCARE_PUBLIC_KEY}
      onFileSelect={(p) => p.done(onSelect)}
    />
  </Box>
);

const useDocTypeButtonStyles = makeStyles((theme) => ({
  root: {
    minWidth: '120px',
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  label: {
    flexDirection: 'column',
  },
}));

export const EventSelecter = () => {
  const match = useRouteMatch();
  const location = useLocation();
  const selection = location.pathname.split('/').slice(-1)[0];
  const buttonClasses = useDocTypeButtonStyles();
  const TypeButton = (props) => (
    <Button
      variant="outlined"
      className="primary-bg-btn"
      color="primary"
      classes={buttonClasses}
      {...props}
    />
  );
  return (
    <div>
      <Typography variant="h6" align="center">
        What type of event would you like to create?
      </Typography>
      <Grid container justify="center" spacing={3} style={{ padding: '16px' }}>
        <Grid item>
          <Box maxWidth="250px" textAlign="center">
            <Link to={match.url + '/bookings'}>
              <TypeButton
                disabled={selection === 'bookings'}
                classes={buttonClasses}
              >
                <EmailIcon />
                Booking
              </TypeButton>
            </Link>
            <Typography style={{ paddingTop: '8px' }} variant="subtitle1">
              Invite purchasers to book an appointment in your calendar
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box maxWidth="250px" textAlign="center">
            <Link to={match.url + '/event'}>
              <TypeButton
                disabled={selection === 'events'}
                classes={buttonClasses}
              >
                <EmailIcon />
                Event
              </TypeButton>
            </Link>
            <Typography style={{ paddingTop: '8px' }} variant="subtitle1">
              Request for purchasers to attend an event
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export const BookingSelecter = () => {
  const match = useRouteMatch();
  const location = useLocation();
  const selection = location.pathname.split('/').slice(-1)[0];
  const buttonClasses = useDocTypeButtonStyles();
  const TypeButton = (props) => (
    <Button
      variant="outlined"
      className="primary-bg-btn"
      color="primary"
      classes={buttonClasses}
      {...props}
    />
  );
  return (
    <div>
      <Typography variant="h6" align="center">
        What type of event would you like to create?
      </Typography>
      <Grid container justify="center" spacing={3} style={{ padding: '16px' }}>
        <Grid item xs={4}>
          <Box textAlign="center">
            <Link to={match.url + '/design-studio'}>
              <TypeButton
                disabled={selection === 'design-studio'}
                classes={buttonClasses}
              >
                <EmailIcon />
                Design Studio
              </TypeButton>
            </Link>
            <Typography style={{ paddingTop: '8px' }} variant="subtitle2">
              1h 30min
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box textAlign="center">
            <Link to={match.url + '/meeting'}>
              <TypeButton
                disabled={selection === 'meeting'}
                classes={buttonClasses}
              >
                <EmailIcon />
                Meeting
              </TypeButton>
            </Link>
            <Typography style={{ paddingTop: '8px' }} variant="subtitle2">
              30min
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box textAlign="center">
            <Link to={match.url + '/inspection'}>
              <TypeButton
                disabled={selection === 'inspection'}
                classes={buttonClasses}
              >
                <EmailIcon />
                Inspection
              </TypeButton>
            </Link>
            <Typography style={{ paddingTop: '8px' }} variant="subtitle2">
              2h 30min
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

const Composer = (props) => {
  const match = useRouteMatch();
  const [message, setMessage] = useState(props?.data?.message || '');
  const [fileInfo, setFileInfo] = useState(props?.data?.fileInfo || {});
  const [eventInfo, setEventInfo] = useState(props?.data?.eventInfo || {});
  const [calendlyEvent, setCalendlyEvent] = useState({});
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [eventLocation, setEventLocation] = useState();
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());

  const purchaserProperties = useProperties();

  const onNext = (meta) =>
    props.onNext({ message, fileInfo, eventInfo, calendlyEvent, ...meta });

  const { fileType } = useParams();

  const user = useUser();
  const projects = user?.projects;

  // const [selectedPropertySlug] = useContext(SelectedPropertyContext);
  // const initProperty = useProperty(selectedPropertySlug);

  const location = useLocation();
  const qs = QueryString.parse(location.search);

  const hashProject = qs.project;
  const hashProperty = qs.property;

  const [to, setTo] = useState(
    hashProperty
      ? [user?.properties?.find((prop) => prop.slug == hashProperty)]
      : []
  );

  let preselectedProject;
  if (hashProject && projects) {
    preselectedProject = projects?.find((x) => x.slug == hashProject);
  } else if (hashProperty && user?.properties) {
    const hashPropertyObj = user?.properties?.find(
      (prop) => prop.slug == hashProperty
    );
    preselectedProject = projects?.find(
      (x) => x.name == hashPropertyObj?.project
    );
  }

  const [selectedProject, setSelectedProject] = useState(preselectedProject);

  const [filteredProps, setFilteredProps] = useState([]);
  const [cc, setCc] = useState([]);
  const [bcc, setBcc] = useState([]);
  const [subject, setSubject] = useState('');
  const [files, setFiles] = useState([]);
  const [fileGroupId, setFileGroupId] = useState();

  const [touched, setTouched] = useState({});
  const [errors, setErrors] = useState({});
  const history = useHistory();

  const routeMatch = useRouteMatch(routes.composer.uploads);
  // const { fileType } = routeMatch.params;

  // init property
  // useEffect(() => {
  //   if (initProperty) {
  //     setTo([initProperty]);
  //   }
  // }, [initProperty, setTo]);

  useEffect(() => {
    if (Object.values(errors).length === 0) {
      setEventInfo({ title, description, location: eventLocation, start, end });
    }
  }, [title, description, eventLocation, start, end, errors]);

  useEffect(() => {
    Promise.all([
      yup
        .object()
        .shape({ to: yup.array().min(1).required() })
        .validate({ to })
        .then(() => ({}))
        .catch((e) => ({ to: e.errors })),
      ...(['messages', 'events'].includes(fileType)
        ? [
            yup
              .object()
              .shape({ cc: yup.array().of(yup.string().email()) })
              .validate({ cc })
              .then(() => ({}))
              .catch((e) => ({ cc: e.errors })),
          ]
        : []),
      ...(fileType === 'messages'
        ? [
            yup
              .object()
              .shape({ bcc: yup.array().of(yup.string().email()) })
              .validate({ bcc })
              .then(() => ({}))
              .catch((e) => ({ bcc: e.errors })),
            yup
              .object()
              .shape({ subject: yup.string().max(255).required() })
              .validate({ subject })
              .then(() => ({}))
              .catch((e) => ({ subject: e.errors })),
            yup
              .object()
              .shape({ message: yup.string().max(2000).required() })
              .validate({ message })
              .then(() => ({}))
              .catch((e) => ({ message: e.errors })),
          ]
        : []),
      ...(fileType === 'events'
        ? [
            yup
              .object()
              .shape({ title: yup.string().required() })
              .validate({ title })
              .then(() => ({}))
              .catch((e) => ({ title: e.errors })),
            yup
              .object()
              .shape({ description: yup.string().required() })
              .validate({ description })
              .then(() => ({}))
              .catch((e) => ({ description: e.errors })),
            yup
              .object()
              .shape({ eventLocation: yup.string().required() })
              .validate({ eventLocation })
              .then(() => ({}))
              .catch((e) => ({ eventLocation: e.errors })),
          ]
        : []),
    ])
      .then((errArray) => Object.assign({}, ...errArray))
      .then(setErrors);
  }, [
    to,
    cc,
    bcc,
    subject,
    message,
    setErrors,
    title,
    description,
    eventLocation,
  ]);

  // XXX: Deduplicating object arrays is hard in javascript, keep this memoized
  // const owners = useMemo(
  //   () =>
  //     Object.values(
  //       to
  //         .flatMap((p) => p.owners || [])
  //         .reduce(
  //           (acc, owner) => Object.assign({}, acc, { [owner.id]: owner }),
  //           {}
  //         )
  //     ),
  //   [to ? to.length : ""]
  // );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const ucWidget = useRef();

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8}>
          <DialogContent style={isMobile ? { padding: 0 } : {}}>
            {user.isStaff && (
              <Box mb={1}>
                <FormControl style={{ minWidth: '120px' }}>
                  <InputLabel id="select">Project</InputLabel>
                  {/* Projects */}
                  <Select
                    labelId="select"
                    value={selectedProject?.slug || ''}
                    onChange={(evt) => {
                      // set project
                      const proj = projects?.find(
                        (p) =>
                          p.slug === evt.target.value ||
                          slugify(p.name) === evt.target.value
                      );
                      setSelectedProject(proj);

                      history.replace(
                        `${location.pathname}?project=${proj.slug}`
                      );
                    }}
                  >
                    {projects?.map((proj) => (
                      <MenuItem key={proj.slug} value={proj.slug}>
                        {proj.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}
            {isMobile ? (
              <UnitList
                projectSlug={selectedProject?.slug}
                selectedProperties={to}
                onSelect={setTo}
              />
            ) : null}
            <Box mt={3} mb={3}>
              <Typography variant="subtitle2">
                <HouseIcon /> Properties
              </Typography>
              <ChipInput
                placeholder="Select properties on the right"
                required
                fullWidth
                variant="outlined"
                inputValue=""
                value={to.map(
                  (p) =>
                    `${p?.project}: ${p.isCondo ? 'Unit' : 'Lot'} ${
                      p?.lotNumber
                    } ${p?.block ? p?.block : ''}`
                )}
                onDelete={(chip, index) =>
                  setTo(to.filter((p) => p.slug !== to[index].slug))
                }
                onBlur={() => setTouched((t) => ({ ...t, to: true }))}
                error={touched.to && errors?.to?.[0]}
                helperText={
                  touched.to && errors?.to?.[0]
                    ? 'Recipients are required'
                    : !touched.to
                    ? isMobile
                      ? ''
                      : 'Select properties on the right'
                    : ''
                }
              />
            </Box>
            {['messages', 'events'].includes(fileType) && (
              <Box mb={3}>
                <Typography variant="subtitle2">
                  <GroupAddIcon /> CC
                </Typography>
                <ChipInput
                  placeholder="jane@gmail.com"
                  variant="outlined"
                  fullWidth
                  newChipKeys={['Enter', 'Spacebar', ' ', ',', 'Tab']}
                  value={cc}
                  onAdd={(val) => setCc([...cc, val])}
                  onDelete={(chip) => setCc(cc.filter((val) => val !== chip))}
                  onBlur={() => setTouched((t) => ({ ...t, cc: true }))}
                  error={errors?.cc?.[0]}
                  helperText={
                    errors?.cc?.[0] && 'Addresses must be valid emails'
                  }
                />
              </Box>
            )}
            {fileType == 'messages' ? (
              <>
                <Box mb={3}>
                  <Typography variant="subtitle2">
                    <VisibilityOffIcon /> BCC
                  </Typography>
                  <ChipInput
                    variant="outlined"
                    placeholder="john@gmail.com"
                    fullWidth
                    newChipKeys={['Enter', 'Spacebar', ' ', ',', 'Tab']}
                    value={bcc}
                    onAdd={(val) => setBcc([...bcc, val])}
                    onDelete={(chip, index) =>
                      setBcc(bcc.filter((val) => val !== chip))
                    }
                    onBlur={() => setTouched((t) => ({ ...t, bcc: true }))}
                    error={errors?.bcc?.[0]}
                    helperText={
                      errors?.bcc?.[0] && 'Addresses must be valid emails'
                    }
                  />
                </Box>
                <Box mb={4}>
                  <Typography variant="subtitle2">
                    <SubjectIcon /> Subject
                  </Typography>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="subject"
                    type="text"
                    value={subject}
                    onChange={(evt) => setSubject(evt.target.value)}
                    onBlur={() => setTouched((t) => ({ ...t, subject: true }))}
                    error={touched.subject && errors?.subject?.[0]}
                    helperText={
                      touched.subject &&
                      errors?.subject?.[0] &&
                      'Subject is required'
                    }
                  />
                </Box>
              </>
            ) : null}
            {/* {!owners.length ? null : (
            <Box mb={4} bgcolor="#F0F0F0" padding={1}>
              <Grid container spacing={1} direction="row">
                <Grid item>
                  <InfoIcon />
                </Grid>
                <Grid item>
                  <Typography
                    style={{ marginBottom: "8px" }}
                    display="block"
                    variant="body2"
                  >
                    This message will be sent to the following{" "}
                    <strong>{owners.length}</strong>{" "}
                    {owners.length > 1 ? "people" : "person"}:
                  </Typography>
                  <Grid container direction="row">
                    <Grid item component="ul">
                      {owners.map((owner) => (
                        <li
                          key={owner.email}
                        >{`${owner.name} (${owner.email})`}</li>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          )} */}
            <Box height="100%">
              <Switch>
                <Route
                  path={[
                    generatePath(routes.composer.uploads, {
                      fileType: 'messages',
                    }),
                  ]}
                >
                  <>
                    <Typography variant="subtitle2">
                      <CreateIcon />* Message
                    </Typography>

                    <Box style={{ height: 15 }}>
                      {!message && (
                        <Typography color="error">
                          {errors.message && errors.message[0]}
                        </Typography>
                      )}
                    </Box>

                    <ReactQuill
                      modules={isMobile ? { toolbar: false } : modules}
                      formats={formats}
                      placeholder="Enter Your Message..."
                      onChange={(evt) => {
                        setMessage(evt);
                      }}
                    />
                  </>
                </Route>
                <Route
                  path={generatePath(routes.composer.uploads, {
                    fileType: 'events',
                  })}
                >
                  {/* <EventForm
                    data={eventInfo}
                    errors={errors}
                    setErrors={setErrors}
                    touched={touched}
                    setTouched={setTouched}
                    onChange={setEventInfo}
                  /> */}
                  <Typography className="stepper-title" variant="h5">
                    Event Information
                  </Typography>
                  <form noValidate>
                    <Grid
                      justify="center"
                      alignItems="center"
                      container
                      spacing={2}
                    >
                      <Grid item xs={12} sm={12} lg={12}>
                        <Box mb={2}>
                          <Typography variant="subtitle2">
                            <SubjectIcon />* Title
                          </Typography>
                          <TextField
                            className="w-100"
                            required
                            variant="outlined"
                            name="title"
                            id="title"
                            value={title}
                            onChange={(evt) => {
                              setTitle(evt.target.value);
                            }}
                            autoFocus
                            onBlur={() =>
                              setTouched((t) => ({ ...t, title: true }))
                            }
                            error={
                              touched.title && errors.title && errors.title[0]
                            }
                            helperText={
                              touched.title && errors.title && errors.title[0]
                            }
                          />
                        </Box>
                        <Box mb={2}>
                          <Typography variant="subtitle2">
                            <LocationOnIcon /> Location
                          </Typography>
                          <TextField
                            className="w-100"
                            name="location"
                            required
                            variant="outlined"
                            id="location"
                            value={eventLocation}
                            onChange={(evt) =>
                              setEventLocation(evt.target.value)
                            }
                            onBlur={() => {
                              setTouched((t) => ({
                                ...t,
                                eventLocation: true,
                              }));
                            }}
                            error={
                              touched.eventLocation &&
                              errors.eventLocation &&
                              errors.eventLocation[0]
                            }
                            helperText={
                              touched.eventLocation &&
                              errors.eventLocation &&
                              errors.eventLocation[0]
                            }
                          />
                        </Box>
                        <Box mb={2}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                              <Typography variant="subtitle2">
                                <AlarmOnIcon /> Start
                              </Typography>

                              <DateTimePicker
                                // disableToolbar
                                // disabled={!editClosingDate}
                                // format="MM/DD/yyyy"
                                autoOk
                                fullWidth
                                showTodayButton
                                inputVariant="outlined"
                                variant="inline"
                                id="date-picker-inline"
                                value={start}
                                onChange={(date) => {
                                  setStart(date);
                                }}
                                KeyboardButtonProps={{
                                  'aria-label': 'change start date',
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography variant="subtitle2">
                                <AlarmOffIcon /> End
                              </Typography>
                              <DateTimePicker
                                // disableToolbar
                                // disabled={!editClosingDate}
                                // format="MM/DD/yyyy"
                                fullWidth
                                autoOk
                                showTodayButton
                                inputVariant="outlined"
                                variant="inline"
                                id="date-picker-inline"
                                value={end}
                                onChange={(date) => {
                                  setEnd(date);
                                }}
                                KeyboardButtonProps={{
                                  'aria-label': 'change end date',
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                        <Box mb={2}>
                          <Typography variant="subtitle2">
                            <CreateIcon />* Description
                          </Typography>
                          <TextField
                            className="w-100"
                            required
                            multiline
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            rows={6}
                            id="description"
                            name="description"
                            value={description}
                            onChange={(evt) => setDescription(evt.target.value)}
                            onBlur={() =>
                              setTouched((t) => ({ ...t, description: true }))
                            }
                            error={
                              touched.description &&
                              errors.description &&
                              errors.description[0]
                            }
                            helperText={
                              touched.description &&
                              errors.description &&
                              errors.description[0]
                            }
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </form>
                </Route>
                <Route
                  path={generatePath(routes.composer.uploads, {
                    fileType: 'photos',
                  })}
                >
                  <PhotoUploader onSelect={setFileInfo} />
                </Route>
                <Route
                  path={generatePath(routes.composer.uploads, {
                    fileType: 'rsvps',
                  })}
                >
                  <RSVPComposer
                    value={message}
                    setCalendlyEvent={setCalendlyEvent}
                    calendlyEvent={calendlyEvent}
                    setMessage={setMessage}
                  />
                </Route>
                <Route
                  path={generatePath(routes.composer.uploads, {
                    fileType: 'videos',
                  })}
                >
                  <VideoUploader onSelect={setFileInfo} />
                </Route>
                <Route
                  path={[
                    generatePath(routes.composer.uploads, {
                      fileType: 'files',
                    }),
                  ]}
                >
                  <DocumentUploader onSelect={setFileInfo} />
                </Route>
                <Route
                  path={[
                    generatePath(routes.composer.uploads, { fileType: 'pdfs' }),
                  ]}
                >
                  <Redirect
                    to={generatePath(routes.composer.uploads, {
                      fileType: 'files',
                    })}
                  />
                </Route>
              </Switch>
            </Box>
          </DialogContent>
        </Grid>
        <Grid item xs={12} md={4}>
          {isMobile ? null : (
            <UnitList
              projectSlug={selectedProject?.slug}
              selectedProperties={to}
              onSelect={setTo}
            />
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <Box m={3}>
            <div style={{ display: 'none' }}>
              <Widget
                ref={ucWidget}
                publicKey={process.env.REACT_APP_UPLOADCARE_PUBLIC_KEY}
                multiple={true}
                previewStep={true}
                clearable={true}
                crop=""
                onChange={(fileInfo) => {
                  // console.log('THIS IS ONCHANGE')
                  // console.log(fileInfo)
                  setFileGroupId(fileInfo.uuid);
                }}
                // onFileSelect={(objs) => {
                //   console.log(objs)
                //   console.log(objs.files())
                //   const files = objs.files()
                //   files.forEach((e) => e.done(e => {
                //     console.log(e)
                //     setFiles(...files, e)
                //     })
                //   )
                // }}
                onDialogClose={async (objs) => {
                  // console.log('DIAGLOGUE CLOSE')
                  // console.log(objs)
                  const files = await Promise.all(objs.files());
                  // console.log(files)
                  setFiles(files);
                }}
              />
            </div>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AttachFileIcon />}
              onClick={() => ucWidget?.current?.openDialog()}
              style={{ marginBottom: 16 }}
            >
              Upload files
            </Button>
            {files.length > 0 && (
              <>
                <Divider />
                <Box mt={2}>
                  <Button variant="outlined">{files.length} files</Button>
                </Box>
                <Box mt={1}>
                  {files.map((img) => {
                    if (img.isImage) {
                      return (
                        <Box display="flex" alignItems="center">
                          <AiOutlineFileImage
                            style={{ width: 60, height: 40 }}
                          />
                          <a
                            href={img.cdnUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {img.name}
                          </a>
                        </Box>
                      );
                    } else {
                      return (
                        <Box display="flex" alignItems="center">
                          <AiOutlineFileText
                            style={{ width: 60, height: 40 }}
                          />
                          <a
                            href={img.cdnUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {img.name}
                          </a>
                        </Box>
                      );
                    }
                  })}
                </Box>
              </>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <DialogActions>
            <Box py={1} px={2}>
              <Box display="inline-block" mr={2}>
                <Button
                  variant="outlined"
                  className="primary-bg-btn"
                  color="primary"
                  onClick={() => {
                    history.push(generatePath(routes.composer.base));
                  }}
                >
                  <Box
                    component="span"
                    fontSize="20px"
                    mr={1}
                    className="material-icons"
                  >
                    cancel_schedule_send
                  </Box>
                  Cancel
                </Button>
              </Box>
              <Button
                disabled={
                  Object.values(errors).length > 0 || fileType === 'events'
                    ? !title || !description || !eventLocation
                    : fileType === 'messages'
                    ? !message
                    : false
                }
                variant="outlined"
                className="primary-bg-btn"
                color="primary"
                autoFocus
                onClick={() => onNext({ to, cc, bcc, subject, fileGroupId })}
              >
                <Box
                  component="span"
                  fontSize="18px"
                  mr={1}
                  className="material-icons"
                >
                  send
                </Box>
                Next
              </Button>
            </Box>
          </DialogActions>
        </Grid>
      </Grid>
    </>
  );
};

export default Composer;
