import RequestContext from 'contexts/request';
import { gql } from 'graphql-request';
import { useContext } from 'react';
import { useMutation } from 'react-query';

const MUTATION = gql`
  mutation ($email: String!) {
    sendPasswordResetEmail(email: $email) {
      success
      errors
    }
  }
`;

const requestResetPassword = (client) => async (variables) => {
  const data = await client.request(MUTATION, variables);
  return data.success;
};

const useResetPassword = () => {
  const { client } = useContext(RequestContext);
  return useMutation(requestResetPassword(client));
};

export default useResetPassword;
