import Hidden from '@material-ui/core/Hidden';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useUser from 'api/useUser';
import React from 'react';
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';

const PropertySelect = () => {
  const myUser = useUser();
  const history = useHistory();
  const route =
    useRouteMatch('/:entity(properties|projects)/:slug?/:rest?') || null;

  let options;
  if (route?.params?.entity === 'projects') {
    options = myUser.projects.map((proj) => ({
      name: proj.name,
      slug: proj.slug,
    }));
  } else {
    const props = myUser?.properties?.map((x) => ({
      name: `${x.project}: Lot ${x.lotNumber}${
        x.block ? x.block?.toUpperCase() : ''
      }`,
      slug: x.slug,
      isCondo: x.isCondo,
      project: x.project,
      lotNumber: parseInt(x.lotNumber, 10),
      block: x.block,
    }));

    options = props
      ?.sort((a, b) => a.lotNumber - b.lotNumber)
      .sort((a, b) => a.project.localeCompare(b.project));
  }

  const label =
    route?.params?.entity?.charAt(0).toUpperCase() +
    route?.params?.entity?.slice(1).toLowerCase();

  const Desktop = () => {
    return (
      <Autocomplete
        id="grouped-properties"
        disableClearable
        options={options}
        groupBy={(property) => property?.project}
        getOptionLabel={(option) => option.name}
        style={{ width: 300 }}
        renderInput={(params) => (
          <TextField {...params} label={label} variant="outlined" />
        )}
        value={options?.find((opt) => opt.slug === route?.params?.slug)}
        onChange={(evt, val) => {
          const generated = generatePath(route?.path, {
            entity: route?.params?.entity,
            slug: val.slug,
            rest: route?.params?.rest,
          });
          history.push(generated);
        }}
      />
    );
  };

  const Mobile = () => (
    <Select
      style={{ width: '100%' }}
      value={options?.find((opt) => opt.slug === route?.params?.slug)}
      onChange={(evt) => {
        const generated = generatePath(route?.path, {
          entity: route?.params?.entity,
          slug: evt?.target?.value?.slug,
          rest: route?.params?.rest,
        });
        history.push(generated);
      }}
      variant="outlined"
    >
      {options.map((option) => (
        <MenuItem value={option}>{option.name}</MenuItem>
      ))}
    </Select>
  );

  return (
    <>
      {route && options && (
        <>
          <Hidden smDown>
            <Desktop />
          </Hidden>
          <Hidden mdUp>
            <Mobile />
          </Hidden>
        </>
      )}
    </>
  );
};

export default PropertySelect;
