const routes = {
  entity: {
    // Base Dashboard
    dashboard: '/:entity(properties|projects)',

    photos: '/:entity(properties|projects)/:slug/:inboxType(photos)',

    pdfs: '/:entity(properties|projects)/:slug/:inboxType(documents)',

    // Messages
    inboxList: '/:entity(properties|projects)/:slug/:inboxType(messages)',

    inboxDetail: '/:entity(properties|projects)/:slug/:inboxType(messages)/:id',

    // Events
    eventList: '/:entity(properties|projects)/:slug/:inboxType(events)',
    eventDetail: '/:entity(properties|projects)/:slug/:inboxType(events)/:id',

    // Detail page
    detail: '/:entity(properties|projects)/:slug',

    // Events - Has extra stuff at the top
    events: '/:entity(properties|projects)/:slug/:inboxType(events)',

    // Calendar
    calendar: '/:entity(properties|projects)/:slug/calendar',
  },

  // Composer
  composer: {
    base: '/composer',
    uploads:
      '/composer/:fileType(files|photos|videos|messages|events|rsvps|pdfs)',
  },

  // Auth
  auth: {
    // Login
    login: '/login',

    // Password
    password: {
      setNew: '/password/new/:token',
      reset: '/password/reset',
    },
  },
};

export default routes;
