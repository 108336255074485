/**
 * Listing
 */
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Hidden,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import DescriptionIcon from '@material-ui/icons/Description';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import SearchIcon from '@material-ui/icons/Search';
import VideocamIcon from '@material-ui/icons/Videocam';
import useProperty from 'api/useProperty';
import clsx from 'clsx';
import SelectedPropertyContext from 'contexts/selectedProperty';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useHistory, useRouteMatch } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: 0,
    backgroundColor: theme.palette.background.paper,
  },
  searchBox: {
    padding: '24px 48px 32px',
    [theme.breakpoints.down('sm')]: {
      padding: '5px 16px 12px 48px',
    },
    [theme.breakpoints.down('xs')]: {
      '& .pagination-wrap': {
        marginTop: '-10px',
      },
    },
  },
  selectWrap: {
    padding: '22px 16px',
    '& label > span:nth-child(2)': {
      fontSize: 16,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '6px 16px 5px 48px',
      '& label': {
        marginRight: 0,
        '& > span:nth-child(2)': {
          display: 'none',
        },
      },
    },
  },
  inline: {
    display: 'inline',
  },
  formItem: {
    fontSize: 24,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  mailItem: {
    borderTop: '1px solid #e0e0e0',
    paddingLeft: 0,
    cursor: 'pointer',
    transition: 'opacity 0.2s ease-out',
    '& >div:first-child': {
      transition: 'opacity 0.2s ease-out',
    },
    '&:hover': {
      '& >div:first-child': {
        opacity: 1,
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& >div:first-child': {
        opacity: 1,
        '& >span': {
          marginLeft: 'auto',
          marginRight: 'auto',
        },
      },
    },
  },
  active: {
    backgroundColor: theme.palette.background.paper,
    '& >div:first-child': {
      opacity: 1,
    },
  },
  check: {
    marginTop: 2,
    opacity: 0,
    minWidth: 50,
    [theme.breakpoints.down('sm')]: {
      minWidth: 42,
      marginTop: 1,
      '& svg': {
        width: 22,
        height: 22,
      },
    },
  },
  secBtn: {
    color: theme.palette.text.secondary,
    fontSize: '1rem',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
      '& span > span:nth-child(2)': {
        display: 'none',
      },
    },
  },
  mailIcon: {
    '@media (max-width:1560px)': {
      minWidth: 50,
      '& .material-icons-outlined': {
        fontSize: 27,
      },
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: 45,
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  desc: {
    width: 'calc(100%)',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% - 55px)',
      paddingRight: 10,
    },
  },
  detail: {
    [theme.breakpoints.down('xs')]: {
      width: '55px',
      fontSize: 12,
    },
  },
  rctScroll: {
    height: 'calc(100vh - 178px) !important',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 139px) !important',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100vh - 165px) !important',
    },
  },
}));

const TypeIcon = ({ type }) => {
  switch (type) {
    case 'VIDEO':
      return <VideocamIcon />;
    case 'PDF':
      return <PictureAsPdfIcon />;
    case 'OTHER':
    default:
      return <DescriptionIcon />;
  }
};

const typePath = (type) => {
  switch (type) {
    case 'VIDEO':
      return 'videos';
    case 'PDF':
      return 'documents';
    case 'OTHER':
    default:
      return 'documents';
  }
};

const DocumentListing = ({ type = 'VIDEO' }) => {
  const history = useHistory();
  const match = useRouteMatch();
  const classes = useStyles();

  const [selectedPropertySlug] = useContext(SelectedPropertyContext);
  const property = useProperty(selectedPropertySlug);

  const allDocuments =
    property?.documents?.filter((d) => d.type === type) || [];

  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [searchText, setSearchText] = useState('');
  const documents = allDocuments
    .filter((d) => !type || d.type === type)
    .filter(
      (d) => !searchText || d?.title?.toLowerCase()?.includes(searchText)
    );

  const viewDocument = (doc) =>
    history.push(
      `/properties/${selectedPropertySlug}/files/${typePath(type)}/${doc.id}`
    );

  const onSelectDocument = (e, doc) => {
    e.stopPropagation();
    if (!e.target.checked) {
      setSelectedDocuments((ds) => ds.filter((d) => d.id !== doc.id));
    } else {
      setSelectedDocuments((ds) => [...ds, doc]);
    }
  };

  const onSelectAllDocuments = (e) => {
    const selectAll = selectedDocuments.length < documents.length;
    if (selectAll) {
      setSelectedDocuments(documents);
    } else {
      setSelectedDocuments([]);
    }
  };

  return (
    <div>
      {selectedDocuments.length > 0 ? (
        <Box className={classes.selectWrap} bgcolor="background.default">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Box display="inline-flex" alignItems="center">
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        indeterminate={
                          selectedDocuments.length > 0 &&
                          selectedDocuments.length < documents.length
                        }
                        checked={selectedDocuments.length > 0}
                        onChange={(e) => onSelectAllDocuments(e)}
                        value="SelectMail"
                      />
                    }
                    label="Select All"
                  />
                </Box>
              </Box>
              <Box>
                <Box>
                  {selectedDocuments.length === documents.length
                    ? 'All'
                    : selectedDocuments.length}{' '}
                  Items Selected
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box className={`search-box ${classes.searchBox}`}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={7} md={7}>
              <FormControl fullWidth>
                <InputLabel
                  className={classes.formItem}
                  htmlFor="standard-adornment-password"
                >
                  Search
                </InputLabel>
                <Input
                  className={classes.formItem}
                  type="text"
                  name="search"
                  id="search-todo"
                  onChange={(e) => setSearchText(e.target.value)}
                  value={searchText}
                  endAdornment={
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      )}
      <Scrollbars className={`rct-scroll ${classes.rctScroll}`} autoHide>
        {documents?.length > 0 ? (
          <List className={`email-list-wrap ${classes.root}`}>
            {documents.map((doc, index) => {
              const isSelected = !!selectedDocuments.find(
                (d) => d.id === doc.id
              );
              return (
                <ListItem
                  className={clsx(classes.mailItem, {
                    [classes.active]: isSelected,
                  })}
                  alignItems="flex-start"
                  key={index}
                  onClick={() => viewDocument(doc)}
                >
                  <ListItemIcon className={classes.check}>
                    <Checkbox
                      checked={isSelected}
                      onClick={(e) => onSelectDocument(e, doc)}
                      id={'cb-' + index}
                      color="primary"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </ListItemIcon>
                  <ListItemIcon className={classes.mailIcon}>
                    <TypeIcon type={doc.type} />
                  </ListItemIcon>
                  <ListItemText style={{ marginRight: '24px' }}>
                    <Box display="flex" alignItems="center">
                      <Box className={`content-desc ${classes.desc}`} pr={3}>
                        <Box
                          className="list-desc"
                          fontSize="subtitle1.fontSize"
                          color="primary.main"
                          style={{ cursor: 'pointer' }}
                        >
                          <Hidden smDown implementation="css">
                            {doc.title || 'untitled'}
                          </Hidden>
                          <Hidden mdUp implementation="css">
                            {doc.title?.length > 38
                              ? doc.title?.substring(0, 38) + '...'
                              : doc.title || 'untitled'}
                          </Hidden>
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        justifyContent={{ xs: 'flex-end', md: 'flex-start' }}
                        alignItems="center"
                        className={`list-detail ${classes.detail}`}
                      >
                        <Box
                          fontSize="subtitle2.fontSize"
                          display={{ xs: 'none', sm: 'inline-block' }}
                          pr={{ sm: 1, md: 3 }}
                        >
                          {Math.floor(doc.bytes / 1000)}&nbsp;KB
                        </Box>
                        <Box
                          fontSize="subtitle2.fontSize"
                          pl={{ sm: 1, md: 3 }}
                          color="text.primary"
                        >
                          {moment(doc.created).format('DD/MM/YYYY')}
                        </Box>
                      </Box>
                    </Box>
                  </ListItemText>
                  <ListItemSecondaryAction>
                    <a href={doc?.downloadUrl} download={doc?.title}>
                      <IconButton
                        edge="end"
                        aria-label="download"
                        style={{ paddingTop: '0px' }}
                      >
                        <DownloadIcon />
                      </IconButton>
                    </a>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        ) : (
          <Box
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box fontSize="h5.fontSize" color="text.secondary">
              No Documents Found
            </Box>
          </Box>
        )}
      </Scrollbars>
    </div>
  );
};

export default DocumentListing;
