import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import useProperties from 'api/useProperties';
import React, { useState } from 'react';

const UnitList = ({ disabled, projectSlug, selectedProperties, onSelect }) => {
  const purchaserProperties = useProperties();

  const unsorted = projectSlug
    ? purchaserProperties.filter((x) => x.projectSlug === projectSlug)
    : purchaserProperties;

  const properties = unsorted.sort((a, b) => a.lotNumber - b.lotNumber);

  // properties search
  const [search, setSearch] = useState('');
  const manyFields = (term) => (property) =>
    [
      `${property?.isCondo ? 'Unit' : 'Lot'} ${property?.lotNumber}`
        .toLowerCase()
        .includes(term), // lot number
      property?.address?.street?.toLowerCase()?.includes(term), // street address
      property?.owners?.find((o) => o?.name?.toLowerCase()?.includes(term)), // owner's name
      property?.owners?.find((o) => o?.email?.toLowerCase()?.includes(term)), // owner's email
    ].some((x) => x);
  const filtered = search.length
    ? properties.filter(manyFields(search.toLowerCase()))
    : properties;

  const allSelected = selectedProperties?.length >= filtered?.length;

  const handleSelect = (evt, property) => {
    if (evt.target.checked) {
      // XXX: this may cause duplicates
      onSelect([...selectedProperties, property]);
    } else {
      onSelect(selectedProperties.filter((p) => p?.slug !== property.slug));
    }
  };

  return (
    <div>
      <div style={{ textAlign: 'right' }}>
        <Button
          variant="outlined"
          className="primary-bg-btn"
          color="primary"
          onClick={() => onSelect(allSelected ? [] : filtered)}
        >
          {allSelected ? 'Unselect' : 'Select'} All
        </Button>
      </div>
      <TextField
        style={{ display: 'block', marginLeft: '16px' }}
        label="Search"
        value={search}
        onChange={(evt) => setSearch(evt.target.value)}
      />
      <List style={{ maxHeight: '44em', overflow: 'auto' }}>
        {filtered.map((property) => (
          <ListItem>
            <ListItemIcon>
              <Checkbox
                disabled={disabled}
                style={{ paddingTop: 0, paddingBottom: 0 }}
                edge="start"
                disableRipple
                checked={
                  !!selectedProperties.find((p) => p?.slug === property.slug)
                }
                onChange={(evt) => handleSelect(evt, property)}
              />
            </ListItemIcon>
            <ListItemText>
              {property.project}: {property.isCondo ? 'Unit' : 'Lot'}{' '}
              {property.lotNumber}
              {property.block}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default UnitList;
