import RequestContext from 'contexts/request';
import { gql } from 'graphql-request';
import { useContext } from 'react';
import { useMutation } from 'react-query';

const MUTATION = gql`
  mutation ($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      token
    }
  }
`;

const requestTokenAuth = (client) => async (variables) => {
  const data = await client.request(MUTATION, variables);
  const auth = data.tokenAuth;
  return auth;
};

const useTokenAuth = () => {
  const { client, setAuth } = useContext(RequestContext);
  return useMutation(requestTokenAuth(client), {
    onSuccess: setAuth,
  });
};

export default useTokenAuth;
