import { Box, Button, FormHelperText, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import useSetNewPassword from 'api/useSetNewPassword';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import routes from '../../routes';

const baseBackground =
  process.env.REACT_APP_BACKGROUND_URL ||
  'https://ucarecdn.com/0dc54812-0181-4a42-89a5-f6603b1797f3';

const baseLogo =
  process.env.REACT_APP_BASELOGO_URL ||
  'https://ucarecdn.com/a1699ac1-3567-4bf8-9a72-d5549ef1f7c7';

function Login({ match }) {
  const token = match?.params?.token;
  const [formErrors, setFormErrors] = useState({
    missingPassword1: false,
    missingPassword2: false,
    passwordsDoNotMatch: false,
  });
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [requestFailure, setRequestFailure] = useState('');

  const history = useHistory();

  const setNewPassword = useSetNewPassword();
  /**
   * Function to login user using Firebase
   */
  const onUserLogin = async () => {
    let fieldValidationErrors = formErrors;
    fieldValidationErrors.missingPassword1 = password1 === '';
    fieldValidationErrors.missingPassword2 = password2 === '';
    fieldValidationErrors.passwordsDoNotMatch = password1 !== password2;
    setFormErrors({ ...fieldValidationErrors });
    if (Object.values(fieldValidationErrors).every((bool) => bool === false)) {
      setNewPassword(
        { password1, password2, token },
        {
          onSuccess: ({ success, errors }) => {
            if (success) {
              history.push(`${routes.auth.login}#resetSuccess=true`);
            } else {
              const messages = Object.values(errors)
                .flat()
                .map((e) => e?.message);
              // console.info(messages);
              setRequestFailure(
                messages[0] || 'Something went wrong. Please try again.'
              );
            }
          },
          onError: (err) => {
            // console.info(err);
            setRequestFailure('Something went wrong. Please try again.');
          },
        }
      );
    }
  };

  /**
   * Function is use for check the email validation.
   */
  const validateEmail = (email) => {
    let emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    return emailValid;
  };

  /**
   * On User Sign Up
   */
  const onForgotPassword = () => {
    history.push('/password/reset');
  };

  // main render function
  const { missingPassword1, missingPassword2, passwordsDoNotMatch } =
    formErrors;
  const isDarkModeActive = false;
  return (
    <div>
      <div className="session-wrapper">
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} sm={12} md={6} lg={3} className="login-wrap">
            <div className="login-wrapper text-center">
              <Box width="100%" maxWidth="480px">
                <div className="session-logo">
                  {isDarkModeActive ? (
                    <img
                      className="img-fluid"
                      alt="img"
                      width="300px"
                      src={`${baseLogo}/-/resize/300x/-/quality/smart/-/format/auto/-/progressive/yes/`}
                    />
                  ) : (
                    <img
                      className="img-fluid"
                      alt="img"
                      width="300px"
                      src={`${baseLogo}/-/resize/300x/-/quality/smart/-/format/auto/-/progressive/yes/`}
                    />
                  )}
                </div>
                <form className="login-form">
                  {requestFailure && (
                    <Box mb={3}>
                      <FormHelperText
                        error={true}
                        style={{ textAlign: 'center', fontSize: '14px' }}
                      >
                        {requestFailure}
                      </FormHelperText>
                    </Box>
                  )}
                  <Box mb={3}>
                    <TextField
                      required
                      fullWidth
                      id="password1"
                      type="password"
                      name="password1"
                      label="Password"
                      placeholder="Please enter a new password."
                      className=""
                      value={password1}
                      onChange={(event) => setPassword1(event.target.value)}
                      error={missingPassword1}
                    />
                    {missingPassword1 && (
                      <Box
                        component="span"
                        color="error.main"
                        textAlign="left"
                        display="block"
                        fontSize="subtitle2.fontSize"
                        pt={1}
                      >
                        This field cannot be empty
                      </Box>
                    )}
                  </Box>
                  <Box mb={3}>
                    <TextField
                      required
                      fullWidth
                      id="password2"
                      label="Confirm Password"
                      placeholder="Please enter the same password as above."
                      className=""
                      type="password"
                      value={password2}
                      error={missingPassword2}
                      onChange={(event) => setPassword2(event.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          onUserLogin();
                        }
                      }}
                    />
                    {missingPassword2 ? (
                      <Box
                        component="span"
                        color="error.main"
                        textAlign="left"
                        display="block"
                        fontSize="subtitle2.fontSize"
                        pt={1}
                      >
                        This field cannot be empty
                      </Box>
                    ) : passwordsDoNotMatch ? (
                      <Box
                        component="span"
                        color="error.main"
                        textAlign="left"
                        display="block"
                        fontSize="subtitle2.fontSize"
                        pt={1}
                      >
                        Passwords do not match
                      </Box>
                    ) : null}
                  </Box>
                  <Box mb="40px" pt="20px">
                    <Button
                      color="primary"
                      className="btn-block blockBtn w-100"
                      variant="contained"
                      size="large"
                      onClick={onUserLogin}
                    >
                      Set Password
                    </Button>
                  </Box>
                </form>
              </Box>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={9}
            style={{
              backgroundImage: `url(${baseBackground}/-/resize/1200x/-/quality/smart/-/format/auto/-/progressive/yes/)`,
              backgroundSize: 'cover',
              backgroundPosition: 'center right',
            }}
            className="img-session"
          ></Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Login;
