import { useAuth0 } from '@auth0/auth0-react';
import {
  Box,
  Button,
  FormHelperText,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutline';
import HelpIcon from '@material-ui/icons/Help';
import useTokenAuth from 'api/useTokenAuth';
import useUser from 'api/useUser';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import routes from '../../routes';

const baseBackground =
  process.env.REACT_APP_BACKGROUND_URL ||
  'https://ucarecdn.com/0dc54812-0181-4a42-89a5-f6603b1797f3';

const baseLogo =
  process.env.REACT_APP_BASELOGO_URL ||
  'https://ucarecdn.com/a1699ac1-3567-4bf8-9a72-d5549ef1f7c7';

const companyLogo = process.env.REACT_APP_COMPANYLOGO_URL || null;

function Login(props) {
  // constructor
  const [formErrors, setFormErrors] = useState({
    blankEmail: false,
    invalidEmail: false,
    blankPassword: false,
  });
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error] = useState('');
  const [loginFailure, setLoginFailure] = useState('');

  const history = useHistory();
  const user = useUser();
  const { loginWithRedirect } = useAuth0();
  useEffect(() => {
    if (user) {
      history.push('/');
    }
  }, [user, history]);

  const { show } = useIntercom();

  const hashDict = new URLSearchParams(window.location.hash.substr(1));

  const resetBanner = hashDict.get('resetSuccess') == 'true';

  const { mutate, isLoading } = useTokenAuth();
  /**
   * Function to login user using Firebase
   */
  const onUserLogin = async () => {
    const fieldValidationErrors = formErrors;
    fieldValidationErrors.blankEmail = email === '';
    fieldValidationErrors.blankPassword = password === '';
    fieldValidationErrors.invalidEmail = !validateEmail(email);
    await setFormErrors(fieldValidationErrors);
    const userDetails = { email, password };
    if (Object.values(fieldValidationErrors).every((bool) => bool === false)) {
      mutate(userDetails, {
        onSuccess: () => {
          history.push('/');
        },
        onError: () =>
          setLoginFailure('Login Failed. Email or password is incorrect.'),
      });
    }
    // loginWithRedirect();
  };

  /**
   * Function is use for check the email validation.
   */
  const validateEmail = (email) => {
    const emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    return emailValid;
  };

  /**
   * On User Sign Up
   */
  const onForgotPassword = () => {
    props.history.push(routes.password.reset);
  };

  // main render function
  const { blankEmail, blankPassword, invalidEmail } = formErrors;
  const isDarkModeActive = false;
  return (
    <div>
      <div className="session-wrapper">
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} sm={12} md={6} lg={3} className="login-wrap">
            <div className="login-wrapper text-center">
              <Box width="100%" maxWidth="480px">
                <div className="session-logo">
                  {isDarkModeActive ? (
                    <img
                      className="img-fluid"
                      alt="img"
                      width="300px"
                      src={`${baseLogo}/-/resize/300x/-/quality/smart/-/format/auto/-/progressive/yes/`}
                    />
                  ) : (
                    <img
                      className="img-fluid"
                      alt="img"
                      width="300px"
                      src={`${baseLogo}/-/resize/300x/-/quality/smart/-/format/auto/-/progressive/yes/`}
                    />
                  )}
                </div>
                <form className="login-form">
                  {loginFailure && (
                    <Box mb={3}>
                      <FormHelperText
                        error
                        style={{ textAlign: 'center', fontSize: '14px' }}
                      >
                        {loginFailure}
                      </FormHelperText>
                    </Box>
                  )}
                  {resetBanner && (
                    <Box p={2}>
                      <CheckCircleIcon color="success" />
                      <Typography color="success">
                        Reset successful - please login with your new password
                      </Typography>
                    </Box>
                  )}
                  <Box mb={3}>
                    <TextField
                      required
                      fullWidth
                      disabled={isLoading}
                      id="username"
                      type="email"
                      name="email"
                      label="Email Address"
                      placeholder="Please enter your email address."
                      className=""
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                      error={!!(blankEmail || invalidEmail || error)}
                    />
                    {blankEmail && (
                      <Box
                        component="span"
                        color="error.main"
                        textAlign="left"
                        display="block"
                        fontSize="subtitle2.fontSize"
                        pt={1}
                      >
                        Email cannot be empty.
                      </Box>
                    )}
                    {!blankEmail && invalidEmail && (
                      <Box
                        component="span"
                        color="error.main"
                        textAlign="left"
                        display="block"
                        fontSize="subtitle2.fontSize"
                        pt={1}
                      >
                        The email address is badly formatted.
                      </Box>
                    )}
                  </Box>
                  <Box mb={3}>
                    <TextField
                      required
                      fullWidth
                      id="login-password"
                      label="Password"
                      placeholder="Please enter your login password."
                      disabled={isLoading}
                      className=""
                      type="password"
                      value={password}
                      error={!!(blankPassword || error)}
                      onChange={(event) => setPassword(event.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          onUserLogin();
                        }
                      }}
                    />
                    {blankPassword && (
                      <Box
                        component="span"
                        color="error.main"
                        textAlign="left"
                        display="block"
                        fontSize="subtitle2.fontSize"
                        pt={1}
                      >
                        Password cannot be empty
                      </Box>
                    )}
                  </Box>
                  <Box mb="40px" pt="20px">
                    <Button
                      color="primary"
                      className="btn-block blockBtn w-100"
                      variant="contained"
                      size="large"
                      onClick={onUserLogin}
                    >
                      {!isLoading ? 'Sign In' : 'Loading'}
                    </Button>
                  </Box>
                  <Box fontSize="subtitle2.fontSize">
                    <Box
                      style={{ cursor: 'pointer' }}
                      component={Link}
                      href={routes.auth.password.reset}
                      color="primary.main"
                    >
                      Forgot password?
                    </Box>
                  </Box>
                  <Box mt={2}>
                    <Button
                      color="secondary"
                      variant="contained"
                      size="large"
                      onClick={show}
                      startIcon={<HelpIcon />}
                    >
                      Support
                    </Button>
                  </Box>
                  {companyLogo && (
                    <Box mt={4}>
                      <img
                        style={{ width: '100%' }}
                        src={companyLogo}
                        alt="Developer Logo"
                      />
                    </Box>
                  )}
                  {/* <div className="social-login-wrapper">
											<Typography variant="body2">Sign in with</Typography>
											<div className="social-list">
												<Fab size="small" variant="round" className="text-white facebook-color"
													onClick={() => dispatch(signinUserWithFacebook(props.history))}
												>
													<i className="fab fa-facebook-f"></i>
												</Fab>
												<Fab size="small" variant="round" className="text-white google-color"
													onClick={() => dispatch(signinUserWithGoogle(props.history))}
												>
													<i className="fab fa-google-plus-g"></i>
												</Fab>
												<Fab size="small" variant="round" className="text-white twitter-color"
													onClick={() => dispatch(signinUserWithTwitter(props.history))}
												>
													<i className="fab fa-twitter"></i>
												</Fab>
												<Fab size="small" className="text-white github-color"
													onClick={() => dispatch(signinUserWithGithub(props.history))}
												>
													<i className="fab fa-github-alt"></i>
												</Fab>
											</div>
										</div>

                  <br></br>
                  <div className="or-sign-up">
                    <Typography variant="body2">
                      Don't have an account?
                    </Typography>
                    <Button
                      variant="outlined"
                      size="large"
                      color="primary"
                      onClick={() => onUserSignUp()}
                    >
                      Sign Up
                    </Button>
								  </div>
								  */}
                </form>
              </Box>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={9}
            style={{
              backgroundImage: `url(${baseBackground}/-/resize/1200x1000/-/quality/smart/-/format/auto/-/progressive/yes/)`,
              backgroundSize: 'cover',
              backgroundPosition: 'center right',
            }}
            className="img-session"
          />
        </Grid>
      </div>
    </div>
  );
}

export default Login;
