import RequestContext from 'contexts/request';
import { gql } from 'graphql-request';
import { useContext } from 'react';
import { useMutation } from 'react-query';

const MUTATION = gql`
  mutation ($token: String!, $password1: String!, $password2: String!) {
    passwordReset(
      token: $token
      newPassword1: $password1
      newPassword2: $password2
    ) {
      success
      errors
    }
  }
`;

const requestSetNewPassword = (client) => async (variables) => {
  const data = await client.request(MUTATION, variables);
  return data.passwordReset;
};

const useSetNewPassword = () => {
  const { client } = useContext(RequestContext);
  const { mutate } = useMutation(requestSetNewPassword(client));
  return mutate;
};

export default useSetNewPassword;
