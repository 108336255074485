import {
  Box,
  Button,
  Divider,
  Fade,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import green from '@material-ui/core/colors/green';
import lightBlue from '@material-ui/core/colors/lightBlue';
import red from '@material-ui/core/colors/red';
import { makeStyles } from '@material-ui/core/styles';
import ReplyIcon from '@material-ui/icons/Reply';
import { Widget } from '@uploadcare/react-widget';
import { useMessageSend } from 'api/useSendMessage';
import { CustomCard } from 'components/GlobalComponents';
import React, { useRef, useState } from 'react';
import {
  AiOutlineCloudDownload,
  AiOutlineFileImage,
  AiOutlineFileText,
} from 'react-icons/ai';
import { FiEye } from 'react-icons/fi';
import Moment from 'react-moment';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    '@media (max-width:1560px)': {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
  },
  editor: {
    '& >div': {
      '&:after, &:before': {
        display: 'none',
      },
    },
  },
  pad0: {
    padding: 0,
  },
  pad10: {
    padding: 10,
  },
  sendBtn: {
    border: '3px solid',
    borderColor: theme.palette.primary.main,
    padding: 10,
  },
  authorThumb: {
    width: 90,
    '@media (max-width:1560px)': {
      width: 70,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  authorDesc: {
    width: 'calc(100% - 90px)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

const Message = (props) => {
  const ucWidget = useRef();
  const { email, dataDict } = props;
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const [body, setBody] = useState('');
  const [files, setFiles] = useState([]);
  const [fileGroupId, setFileGroupId] = useState('');
  const [subject, setSubject] = useState(`RE: ${email?.subject}`);
  const [sent, setSent] = useState(false);

  const properties = [email?.property?.slug];

  const images = email?.files?.files?.filter((x) =>
    x.mimeType.includes('image')
  );
  /* NOTE: PDF's are classified as "isImage: true" */
  const otherFiles = email?.files?.files?.filter(
    (x) => !x.mimeType.includes('image')
  );

  const { isLoading, isError, isSuccess, mutate } = useMessageSend();

  return (
    <Box
      className={classes.root}
      style={{
        width: '100%',
      }}
    >
      <Grid container>
        <Grid md={9} item>
          <Box
            p={{ xs: 2, md: 3 }}
            ml={email?.level * 4}
            style={{ borderLeft: `1px dashed #e0e0e0` }}
          >
            <Typography variant="h5">"{email?.subject}"</Typography>
            <Box
              display={{ xs: 'block', md: 'flex' }}
              alignItems="center"
              mb="20px"
              mt={2}
            >
              <Box
                pr={3}
                mb={{ xs: 2, md: 0 }}
                className={`author-thumb ${classes.authorThumb}`}
              >
                <Avatar className={classes.large}>
                  {email?.sender?.initials}
                </Avatar>
              </Box>
              <Box
                display={{ xs: 'block', sm: 'flex' }}
                alignItems="center"
                justifyContent="space-between"
                className={classes.authorDesc}
              >
                <Box>
                  <Box>
                    {email?.sender?.name}
                    <Box
                      pl={1}
                      component="span"
                      fontSize="subtitle2.fontSize"
                      color="text.secondary"
                    >
                      &lt;{email?.sender?.email}&gt;
                    </Box>
                  </Box>
                </Box>
                <Box display="flex" alignItems="center">
                  <Box
                    pr={2}
                    component="span"
                    fontSize="subtitle2.fontSize"
                    color="text.secondary"
                  >
                    <Moment
                      date={email?.sentAt || email?.created}
                      format="MMM DD YYYY"
                    />
                    <br />
                    <Moment
                      date={email?.sentAt || email?.created}
                      format="hh:mm A"
                      style={{ fontWeight: 500, color: 'grey' }}
                    />
                  </Box>
                  <Tooltip title="Reply" placement="bottom">
                    <IconButton
                      size="small"
                      onClick={() => {
                        setOpen(!open);
                      }}
                    >
                      <ReplyIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
            <Box mb={3} ml={5}>
              {/* {email?.body} */}
              <div
                dangerouslySetInnerHTML={{
                  __html: email?.body,
                }}
              />
            </Box>
            <Box mb={4} display="inline-flex" alignItems="center">
              <Box mr={2} display="inline-block" className="reply-btn">
                <Button
                  color="primary"
                  variant="outlined"
                  className="primary-bg-btn"
                  onClick={() => {
                    setOpen(!open);
                  }}
                >
                  <Box
                    component="span"
                    fontSize="22px"
                    mr={1}
                    className="material-icons"
                  >
                    reply
                  </Box>
                  Reply
                </Button>
              </Box>
              {/* <Box display="inline-block">
                <Button
                  color="primary"
                  variant="outlined"
                  className="primary-bg-btn"
                >
                  <Box
                    component="span"
                    fontSize="22px"
                    mr={1}
                    className="material-icons"
                  >
                    fast_forward
                  </Box>
                  Forward
                </Button>
              </Box> */}
            </Box>
            {open && (
              <Fade in>
                <>
                  <Box ml={4}>
                    <Box
                      display={{ xs: 'block', md: 'flex' }}
                      alignItems="start"
                      mb={4}
                    >
                      <Box
                        pr={3}
                        mb={{ xs: 3, md: 0 }}
                        className={`author-thumb ${classes.authorThumb}`}
                      >
                        <Avatar
                          className={classes.large}
                          alt={`${email?.sender?.email} profile`}
                        >
                          YOU
                        </Avatar>
                      </Box>
                      <Box className={classes.authorDesc}>
                        <CustomCard cardClasses={classes.pad0}>
                          <Box py={2} px={2}>
                            <Box display="flex" alignItems="center">
                              <Box
                                style={{ width: '65px' }}
                                display="flex"
                                alignItems="center"
                              >
                                <IconButton size="small">
                                  <Box
                                    component="span"
                                    color="text.primary"
                                    fontSize="22px"
                                    className="material-icons"
                                  >
                                    reply
                                  </Box>
                                </IconButton>
                                <Box
                                  pl="12px"
                                  component="span"
                                  fontSize="subtitle2.fontSize"
                                  color="text.primary"
                                >
                                  To
                                </Box>
                              </Box>
                              <Box style={{ width: 'calc(100% - 65px)' }}>
                                <TextField
                                  fullWidth
                                  disabled
                                  id="standard-basic"
                                  value={`Lot ${email?.property?.lotNumber} Owners, ${email?.property?.project} Staff`}
                                />
                              </Box>
                            </Box>
                            <br />
                            <Box display="flex" alignItems="center">
                              <Box
                                style={{ width: '65px' }}
                                display="flex"
                                alignItems="center"
                              >
                                <Box
                                  pl="12px"
                                  component="span"
                                  fontSize="subtitle2.fontSize"
                                  color="text.primary"
                                >
                                  Subject
                                </Box>
                              </Box>
                              <Box style={{ width: 'calc(100% - 65px)' }}>
                                <TextField
                                  fullWidth
                                  id="standard-basic"
                                  value={subject}
                                  disabled={sent}
                                  onChange={(evt) =>
                                    setSubject(evt.target.value)
                                  }
                                />
                              </Box>
                            </Box>
                            <Box pt={1}>
                              <TextField
                                className={classes.editor}
                                id="standard-multiline-static"
                                disabled={sent}
                                multiline
                                fullWidth
                                rows="7"
                                value={body}
                                onChange={(evt) => setBody(evt.target.value)}
                              />
                            </Box>
                          </Box>
                          <Divider />
                          <Box
                            py={1}
                            pl={2}
                            pr={1}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Box display="flex" alignItems="center">
                              {isSuccess ? (
                                <Tooltip
                                  title="Sucessfully Sent"
                                  placement="bottom"
                                >
                                  <IconButton
                                    className={classes.sendBtn}
                                    style={{
                                      height: 44,
                                      width: 44,
                                      borderColor: green[600],
                                    }}
                                  >
                                    <Box
                                      component="span"
                                      style={{ color: green[600] }}
                                      fontSize="18px"
                                      className="fas fa-check"
                                    ></Box>
                                  </IconButton>
                                </Tooltip>
                              ) : isError ? (
                                <Tooltip
                                  title="Sending Error"
                                  placement="bottom"
                                >
                                  <IconButton
                                    className={classes.sendBtn}
                                    style={{
                                      height: 44,
                                      width: 44,
                                      borderColor: red[600],
                                    }}
                                  >
                                    <Box
                                      component="span"
                                      style={{ color: red[600] }}
                                      fontSize="18px"
                                      className="fas fa-times"
                                    ></Box>
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <>
                                  <Tooltip title="Send Mail" placement="bottom">
                                    <IconButton
                                      className={classes.sendBtn}
                                      style={{
                                        height: 44,
                                        width: 44,
                                        borderColor: lightBlue[600],
                                      }}
                                      disabled={isLoading || sent}
                                      onClick={() => {
                                        setSent(true);
                                        mutate(
                                          {
                                            body: body,
                                            properties: properties,
                                            subject: subject,
                                            fileGroupId: fileGroupId,
                                            parent: email?.id,
                                          },
                                          {
                                            onError: () => {
                                              console.error('ERORR');
                                            },
                                            onSuccess: () => {
                                              console.info('SUCCESS');
                                            },
                                            onSettled: () => {
                                              console.log('SETTLED');
                                            },
                                          }
                                        );
                                      }}
                                    >
                                      <Box
                                        component="span"
                                        fontSize="18px"
                                        style={{ color: lightBlue[600] }}
                                        className="fas fa-paper-plane"
                                      ></Box>
                                    </IconButton>
                                  </Tooltip>
                                </>
                              )}
                              <div style={{ display: 'none' }}>
                                <Widget
                                  ref={ucWidget}
                                  publicKey={
                                    process.env.REACT_APP_UPLOADCARE_PUBLIC_KEY
                                  }
                                  multiple={true}
                                  previewStep={true}
                                  clearable={true}
                                  crop=""
                                  onChange={(fileInfo) => {
                                    // console.log('THIS IS ONCHANGE')
                                    // console.log(fileInfo)
                                    setFileGroupId(fileInfo.uuid);
                                  }}
                                  // onFileSelect={(objs) => {
                                  //   console.log(objs)
                                  //   console.log(objs.files())
                                  //   const files = objs.files()
                                  //   files.forEach((e) => e.done(e => {
                                  //     console.log(e)
                                  //     setFiles(...files, e)
                                  //     })
                                  //   )
                                  // }}
                                  onDialogClose={async (objs) => {
                                    // console.log('DIAGLOGUE CLOSE')
                                    // console.log(objs)
                                    const files = await Promise.all(
                                      objs.files()
                                    );
                                    // console.log(files)
                                    setFiles(files);
                                  }}
                                />
                              </div>
                              {!isSuccess && !isError && (
                                <Tooltip title="Attachment" placement="bottom">
                                  <IconButton
                                    className={classes.pad10}
                                    onClick={() =>
                                      ucWidget?.current?.openDialog()
                                    }
                                  >
                                    <Box
                                      component="span"
                                      fontSize="18px"
                                      color="text.secondary"
                                      className="fas fa-paperclip"
                                    ></Box>
                                  </IconButton>
                                </Tooltip>
                              )}
                              {isSuccess && (
                                <Box ml={2}>
                                  <Typography
                                    color={green[600]}
                                    variant="caption"
                                  >
                                    Message sent!
                                  </Typography>
                                </Box>
                              )}
                              {isError && (
                                <Box ml={2}>
                                  <Typography
                                    color={red[600]}
                                    variant="caption"
                                  >
                                    Error sending message!
                                  </Typography>
                                </Box>
                              )}
                            </Box>

                            {!sent && (
                              <Box
                                borderLeft={1}
                                pl="5px"
                                className="border-left"
                              >
                                <Tooltip title="Delete" placement="bottom">
                                  <IconButton
                                    className={classes.pad10}
                                    onClick={() => {
                                      setFiles([]);
                                      setFileGroupId('');
                                      setBody('');
                                      setSubject(`RE: ${email?.subject}`);
                                    }}
                                  >
                                    <Box
                                      component="span"
                                      fontSize="18px"
                                      className="far fa-trash-alt"
                                    ></Box>
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            )}
                          </Box>
                        </CustomCard>
                        {files.length > 0 && (
                          <>
                            <Box mt={2} mb={2}>
                              <Button variant="outlined">
                                {files.length} files
                              </Button>
                            </Box>
                            <Box mt={1} mb={2}>
                              {files.map((obj) => {
                                if (obj.isImage) {
                                  return (
                                    <Box display="flex" alignItems="center">
                                      <AiOutlineFileImage
                                        style={{ width: 60, height: 40 }}
                                      />
                                      <a
                                        href={obj.downloadUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {obj.name}
                                      </a>
                                    </Box>
                                  );
                                } else {
                                  return (
                                    <Box display="flex" alignItems="center">
                                      <AiOutlineFileText
                                        style={{ width: 60, height: 40 }}
                                      />
                                      <a
                                        href={obj.downloadUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {obj.name}
                                      </a>
                                    </Box>
                                  );
                                }
                              })}
                            </Box>
                            <Divider />
                          </>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </>
              </Fade>
            )}
          </Box>
        </Grid>
        <Grid md={3} item>
          {images?.length > 0 && (
            <Box p={{ xs: 2, md: 3 }}>
              <>
                <Typography variant="subtitle1">Images</Typography>
                <Box mt={2}>
                  {images?.map((img) => {
                    return (
                      <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                      >
                        <Box>
                          <a
                            href={img.secureUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Paper elevation={3}>
                              <img
                                alt={img.originalFilename}
                                src={`https://${img.baseUrl}/${img.uuid}/-/resize/x200/?token=${img.token}`}
                                style={{ width: 200 }}
                              />
                            </Paper>
                          </a>
                        </Box>
                        <Box display="flex">
                          <Box m={1}>
                            <a href={img.secureUrl} target="blank">
                              <FiEye size="2em" />
                            </a>
                          </Box>
                          <Box m={1}>
                            <a href={img.downloadUrl} target="blank">
                              <AiOutlineCloudDownload size="2em" />
                            </a>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </>
            </Box>
          )}
          {otherFiles?.length > 0 && (
            <Box p={{ xs: 2, md: 3 }}>
              <>
                <Typography variant="subtitle1">Files</Typography>
                <Box mt={2}>
                  {otherFiles?.map((obj) => {
                    return (
                      <Box display="flex" alignItems="center">
                        <a
                          href={obj.secureUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <AiOutlineFileText
                            style={{ width: 60, height: 40 }}
                          />
                        </a>
                        <a
                          href={obj.secureUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {obj.originalFilename}
                        </a>
                      </Box>
                    );
                  })}
                </Box>
              </>
            </Box>
          )}
        </Grid>
        <Box width="100%" ml={email?.level + 1 * 4}>
          <Divider />
        </Box>
        {email?.children.length > 0 && (
          <>
            {email?.children.map((child) => {
              return (
                <Message
                  key={child.id}
                  email={dataDict[child]}
                  dataDict={dataDict}
                />
              );
            })}
          </>
        )}
      </Grid>
    </Box>
  );
};

export default Message;
