import RequestContext from 'contexts/request';
import { gql } from 'graphql-request';
import { useContext } from 'react';
import { useQuery } from 'react-query';

const QUERY = gql`
  query {
    allOwners {
      id
      email
      name
    }
  }
`;

const requestOwners = (client) => () => async () => {
  const { allOwners } = await client.request(QUERY);
  return allOwners;
};

const useAllOwners = () => {
  const { client } = useContext(RequestContext);
  return useQuery(['allOwners'], requestOwners(client)());
};

export default useAllOwners;
