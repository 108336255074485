import RequestContext from 'contexts/request';
import { gql } from 'graphql-request';
import { useContext } from 'react';
import { useQuery } from 'react-query';

const EVENTS_QUERY = gql`
  query (
    $projectSlug: String
    $propertySlug: String
    $startString: String
    $endString: String
  ) {
    allEvents(
      projectSlug: $projectSlug
      propertySlug: $propertySlug
      startString: $startString
      endString: $endString
    ) {
      id
      calendarId
      lotNumber
      location
      description
      summary
      status
      start
      end
      attendees {
        email
        responseStatus
      }
    }
  }
`;

const requestEvents = (client) => (variables) => async () => {
  const { allEvents } = await client.request(EVENTS_QUERY, variables);
  return allEvents;
};

const useEvents = (
  projectSlug = null,
  propertySlug = null,
  startString = null,
  endString = null
) => {
  const { client } = useContext(RequestContext);
  return useQuery(
    ['events', projectSlug, propertySlug, startString, endString],
    requestEvents(client)({
      projectSlug,
      propertySlug,
      startString,
      endString,
    })
  );
};

export default useEvents;
