/**
 * Horizontal Timeline
 */
import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import AlarmOffIcon from '@material-ui/icons/AlarmOff';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import CreateIcon from '@material-ui/icons/Create';
import EventIcon from '@material-ui/icons/Event';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PeopleIcon from '@material-ui/icons/People';
import { DateTimePicker } from '@material-ui/pickers';
import useDeleteGoogleCalendarEvent from 'api/useDeleteGoogleCalendarEvent';
import useEvents from 'api/useEvents';
import useProject from 'api/useProject';
import useProperty from 'api/useProperty';
import useUpdateGoogleCalendarEvent from 'api/useUpdateGoogleCalendarEvent';
import { useUserQuery } from 'api/useUser';
import { CustomCard } from 'components/GlobalComponents';
import moment from 'moment';
import React, { useEffect, useState, useCallback } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { useQueryClient } from 'react-query';
import { Scrollbars } from 'react-custom-scrollbars';
import { generatePath, useParams } from 'react-router-dom';
import routes from '../../routes';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
}));

const localizer = momentLocalizer(moment);

const DetailsDialog = ({ event, onClose }) => {
  // if (event) {
  //   debugger;
  // }
  const endDtString = `${moment(event?.end).format(
    'MMM DD, YYYY [at] hh:mm A'
  )}`;
  const startDtString = `${moment(event?.start).format(
    'MMM DD, YYYY [at] hh:mm A'
  )}`;

  const updateGoogleCalendarEvent = useUpdateGoogleCalendarEvent();
  const deleteGoogleCalendarEvent = useDeleteGoogleCalendarEvent();

  const [start, setStart] = useState(event?.start);
  const [end, setEnd] = useState(event?.end);
  const [description, setDescription] = useState(event?.description);
  const [location, setLocation] = useState(event?.location);
  const { data: user } = useUserQuery();

  useEffect(() => {
    setStart(event?.start);
    setEnd(event?.end);
    setDescription(event?.description);
    setLocation(event?.location);
  }, [event]);

  const { slug } = useParams();

  return (
    <Dialog open={!!event} onClose={onClose}>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box mr={2}>
            {event?.lotNumber && <Avatar>{event?.lotNumber}</Avatar>}
          </Box>
          <Box>
            <Typography variant="subtitle1">{event?.title}</Typography>
          </Box>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box m={2}>
          <>
            <Typography variant="subtitle2">
              <LocationOnIcon />
              Location
            </Typography>
            <TextField
              disabled={!user?.isStaff}
              required
              id="location"
              value={location}
              variant={user?.isStaff ? 'outlined' : 'standard'}
              fullWidth
              onChange={(event) => {
                setLocation(event.target.value);
              }}
            />
          </>
        </Box>

        <Box m={2}>
          <Grid container spacing={3}>
            <Grid item md={6}>
              <Typography variant="subtitle2">
                <AlarmOnIcon /> Start
              </Typography>
              <DateTimePicker
                // disableToolbar
                // disabled={!editClosingDate}
                // format="MM/DD/yyyy"
                disabled={!user?.isStaff}
                autoOk
                fullWidth
                showTodayButton
                inputVariant={user?.isStaff ? 'outlined' : 'standard'}
                variant={user?.isStaff ? 'outlined' : 'standard'}
                id="date-picker-inline"
                value={start}
                onChange={(date) => {
                  setStart(date);
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change start date',
                }}
              />
            </Grid>
            <Grid item md={6}>
              <Typography variant="subtitle2">
                <AlarmOffIcon /> End
              </Typography>
              <DateTimePicker
                // disableToolbar
                // disabled={!editClosingDate}
                // format="MM/DD/yyyy"
                disabled={!user?.isStaff}
                fullWidth
                autoOk
                showTodayButton
                inputVariant={user?.isStaff ? 'outlined' : 'standard'}
                variant={user?.isStaff ? 'outlined' : 'standard'}
                id="date-picker-inline"
                value={end}
                onChange={(date) => {
                  setEnd(date);
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change end date',
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <Box m={2}>
          <>
            <Typography variant="subtitle2">
              <CreateIcon /> Description
            </Typography>
            <TextField
              disabled={!user?.isStaff}
              required
              multiline
              id="description"
              value={description}
              variant={user?.isStaff ? 'outlined' : 'standard'}
              fullWidth
              onChange={(event) => {
                setDescription(event.target.value);
              }}
            />
          </>
        </Box>
        <Box m={2}>
          {event?.attendees && (
            <>
              <Typography variant="subtitle2">
                <PeopleIcon /> Guests
              </Typography>
              <Box marginBottom={2} padding={1} bgcolor="#F0F0F0">
                <ul style={{ margin: 0, color: '#717275' }}>
                  {event?.attendees?.map((a) => (
                    <li key={a.slug}>
                      <Typography variant="subtitle2">{a.email}</Typography>
                    </li>
                  ))}
                </ul>
              </Box>
            </>
          )}
        </Box>
        <Divider />

        <Box m={2} spacing={2} display="flex" justifyContent="flex-end">
          {user?.isStaff ? (
            <>
              <Box style={{ marginRight: 'auto' }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    console.log('DELETE');
                    deleteGoogleCalendarEvent(
                      {
                        projectSlug: slug,
                        eventId: event?.id,
                      },
                      {
                        onSuccess: () => {
                          console.info('SUCCESS');
                          onClose();
                        },
                        onError: () => {
                          console.error('ERROR');
                        },
                      }
                    );
                  }}
                >
                  Delete
                </Button>
              </Box>
              <Box ml={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    updateGoogleCalendarEvent(
                      {
                        projectSlug: slug,
                        eventId: event?.id,
                        start: start,
                        end: end,
                        summary: event?.title,
                        description: description,
                        location: location,
                      },
                      {
                        onSuccess: () => {
                          console.info('SUCCESS');
                          onClose();
                        },
                        onError: () => {
                          console.error('ERROR');
                        },
                      }
                    );
                  }}
                >
                  Save
                </Button>
              </Box>
              <Box ml={2}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Box ml={2}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    onClose();
                  }}
                >
                  OK
                </Button>
              </Box>
            </>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

// const Event = ({ title, date, description, location, attendees = [] }) => (
//   <div className="horizontal-timeline-list--item">
//     <div className="horizontal-timeline-list--item-inner">
//       <div className="horizontal-timeline--meta">
//         <div>
//           <div className="meta-year">{moment(date).format("YYYY")}</div>
//           <div>{moment(date).format("MMM D")}</div>
//         </div>
//       </div>
//       <div className="horizontal-timeline--content">
//         <Typography variant="h6">{title}</Typography>
//         <Typography variant="subtitle2">{location}</Typography>
//         <div style={{ fontSize: "12px", height: "6em", overflow: "hidden" }}>
//           <Typography
//             variant="body2"
//             style={{
//               fontSize: "12px",
//               overflow: "hidden",
//               textOverflow: "ellipsis",
//               display: "-webkit-box",
//               "-webkit-line-clamp": "4",
//               "-webkit-box-orient": "vertical",
//             }}
//           >
//             {description}
//           </Typography>
//         </div>
//         {attendees.length > 0 && (
//           <Box marginTop={2}>
//             <Typography
//               variant="caption"
//               display="block"
//               style={{ fontWeight: "bold" }}
//             >
//               Invitees:
//             </Typography>
//             <Typography variant="caption" display="block">
//               {attendees.map((u) => u.email).join(", ")}
//             </Typography>
//           </Box>
//         )}
//       </div>
//     </div>
//   </div>
// );

function ExportedCalendar() {
  const classes = useStyles();

  const { slug, entity } = useParams();
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [events, setEvents] = useState([]);
  const { data: user } = useUserQuery();

  const [dateRange, setDateRange] = useState({
    start: moment().startOf('month').subtract(1, 'week').toISOString(),
    end: moment().endOf('month').add(1, 'week').toISOString(),
  });

  const { data: projectData } = useProject(slug, entity === 'projects');

  const { data: propertyData } = useProperty(slug, entity === 'properties');

  const entityData = projectData || propertyData || null;

  const { data, status } = useEvents(
    // projectSlug if this is a project
    entity === 'projects' ? slug : null,
    // propertySlug if this is a property
    entity === 'properties' ? slug : null,
    dateRange.start || '',
    dateRange.end || ''
  );

  useEffect(() => {
    if (status === 'success') {
      const eventList = data
        ? data.map((evt) => {
            return {
              title: evt.summary,
              ...evt,
              start: moment(evt.start).toDate(),
              end: moment(evt.end).toDate(),
            };
          })
        : [];

      setEvents(eventList);
    }
  }, [status, data]);

  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries([
      'events',
      // projectSlug if this is a project
      entity === 'projects' ? slug : null,
      // propertySlug if this is a property
      entity === 'properties' ? slug : null,
      dateRange.start || '',
      dateRange.end || '',
    ]);
  }, [dateRange]);

  const heading =
    entityData && entity === 'properties'
      ? `${entityData?.project} - ${entityData.isCondo ? 'Unit' : 'Lot'} ${
          entityData?.lotNumber} ${entityData?.block ? entityData?.block : ''}`
      : entityData?.name;

  const translatedEntity =
    entity === 'properties'
      ? 'property'
      : entity === 'projects'
      ? 'project'
      : null;

    const onRangeChange = useCallback((range) => {
      let momentStart;
      let momentEnd
      if (!range.start && range.length === 1) {
        momentStart = moment(range.start || range[0]);
        const end = new Date();
        end.setDate(range[range.length - 1].getDate() + 1);
        momentEnd = moment(range.end || range[range.length - 1].setDate(range[range.length - 1].getDate() + 1));
      } else {
        momentStart = moment(range.start || range[0]);
        momentEnd = moment(range.end || range[range.length - 1]);
      }
      setDateRange({
        start: momentStart.toISOString(),
        end: momentEnd.toISOString(),
      });
    }, []);

  return (
    <div className={classes.root}>
      <div className="page-space">
        <Container>
          <Box px={{ xs: '12px', lg: 0 }}>
            <Grid container>
              <Grid item md={6}>
                {' '}
                <Typography variant="h2">{heading}</Typography>
              </Grid>
              {user?.isStaff && (
                <>
                  <Grid item md={6} style={{ textAlign: 'end' }}>
                    <Button
                      color="primary"
                      variant="contained"
                      href={`${generatePath(routes.composer.uploads, {
                        fileType: 'events',
                      })}?${translatedEntity}=${slug}`}
                      startIcon={<EventIcon />}
                      style={{ marginRight: 10 }}
                    >
                      New Calendar Event
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      href={`${generatePath(routes.composer.uploads, {
                        fileType: 'rsvps',
                      })}?${translatedEntity}=${slug}`}
                      startIcon={<GroupAddIcon />}
                    >
                      New Calendly Invitation
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>

            {/* {data && (
              <div className="horizontal-timeline text-center">
                <div
                  className="horizontal-timeline-list"
                  style={{ justifyContent: "center" }}
                >
                  {data.map((evt) => (
                    <Event
                      title={evt.data.title}
                      date={evt.data.start}
                      description={evt.data.description}
                      attendees={evt.data.attendees}
                    />
                  ))}
                </div>
              </div>
            )} */}
            <div className="hk-calendar-wrap">
              <Scrollbars
                className="rct-scroll"
                autoHide
                style={{ height: 'calc(100vh - 64px)' }}
              >
                <Box p={3}>
                  <DetailsDialog
                    event={selectedEvent}
                    onClose={() => setSelectedEvent(null)}
                  />
                  <CustomCard>
                    <Calendar
                      onRangeChange={onRangeChange}
                      events={events}
                      selected={selectedEvent}
                      onSelectEvent={setSelectedEvent}
                      startAccessor="start"
                      endAccessor="end"
                      defaultDate={new Date()}
                      localizer={localizer}
                    />
                  </CustomCard>
                </Box>
              </Scrollbars>
            </div>
          </Box>
        </Container>
      </div>
    </div>
  );
}

export default ExportedCalendar;
