import RequestContext from 'contexts/request';
import { gql } from 'graphql-request';
import { useContext } from 'react';
import { useQuery } from 'react-query';

const QUERY = gql`
  query ($slug: String!) {
    project(slug: $slug) {
      id
      slug
      name
      avatar
      avatarCdnUrl
      avatarUuid
      avatarToken
      interactiveAbodeUrl
      isCondo
      properties {
        block
        id
        created
        modified
        slug
        isSold
        closing
        tentativeClosing
        firmClosing
        lotNumber
        facing
        bedrooms
        bathrooms
        dens
        flex
        halfBathrooms
        facing
        owners {
          id
          name
          email
          phone
          login
        }
        avatar
        avatarCdnUrl
        avatarUuid
        avatarToken
        address {
          street
          city
          province
          postal
          raw
        }
      }
    }
  }
`;

const requestProject = (client) => (variables) => async () => {
  const { project } = await client.request(QUERY, variables);
  return project;
};

const useProject = (slug, shouldFetch = true) => {
  const { client } = useContext(RequestContext);
  return useQuery(['project', slug], requestProject(client)({ slug }), {
    enabled: shouldFetch,
  });
};

export default useProject;
