import RequestContext from 'contexts/request';
import { gql } from 'graphql-request';
import { useContext } from 'react';
import { useMutation } from 'react-query';

const MUTATION = gql`
  mutation (
    $body: String!
    $properties: [String!]!
    $cc: [String]
    $bcc: [String]
    $subject: String!
    $fileGroupId: String
    $parent: String
  ) {
    sendMessage(
      body: $body
      properties: $properties
      cc: $cc
      bcc: $bcc
      subject: $subject
      fileGroupId: $fileGroupId
      parent: $parent
    ) {
      success
    }
  }
`;

const requestSendMessage = (client) => async (variables) => {
  const data = await client.request(MUTATION, variables);
  return data.success;
};

const useSendMessage = () => {
  const { client } = useContext(RequestContext);
  const { mutate } = useMutation(requestSendMessage(client));
  return mutate;
};

export const useMessageSend = () => {
  const { client } = useContext(RequestContext);
  return useMutation(requestSendMessage(client));
};

export default useSendMessage;
