/**
 * Listing
 */

import { Box, Typography } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import DraftsIcon from '@material-ui/icons/Drafts';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import useMessages from 'api/useMessages';
import React, { useState } from 'react';
import Moment from 'react-moment';
import { generatePath } from 'react-router';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import LoadingSpinner from '../../../components/Loading';
import routes from '../../../routes';
import useAllStaff from '../../../api/useAllStaff';
import useAssignStaffToMessage from '../../../api/useAssignStaffToMessage';
import Avatar from '@material-ui/core/Avatar';
import { FormControl } from '@material-ui/core';
import { useUserQuery } from 'api/useUser';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: 0,
    backgroundColor: theme.palette.background.paper,
  },
  table: {
    '& .MuiDataGrid-row': {
      border: '1px solid lightgrey',
      cursor: 'pointer',
    },
  },
  searchBox: {
    padding: '24px 48px 32px',
    [theme.breakpoints.down('sm')]: {
      padding: '5px 16px 12px 48px',
    },
    [theme.breakpoints.down('xs')]: {
      '& .pagination-wrap': {
        marginTop: '-10px',
      },
    },
  },

  media: {
    minHeight: 200,
  },
  selectWrap: {
    padding: '22px 16px',
    '& label > span:nth-child(2)': {
      fontSize: 16,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '6px 16px 5px 48px',
      '& label': {
        marginRight: 0,
        '& > span:nth-child(2)': {
          display: 'none',
        },
      },
    },
  },
  inline: {
    display: 'inline',
  },
  formItem: {
    fontSize: 24,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  mailItem: {
    borderTop: '1px solid #e0e0e0',
    paddingLeft: 0,
    cursor: 'pointer',
    transition: 'opacity 0.2s ease-out',
    '& >div:first-child': {
      transition: 'opacity 0.2s ease-out',
    },
    '&:hover': {
      '& >div:first-child': {
        opacity: 1,
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& >div:first-child': {
        opacity: 1,
        '& >span': {
          marginLeft: 'auto',
          marginRight: 'auto',
        },
      },
    },
  },
  active: {
    backgroundColor: theme.palette.background.paper,
    '& >div:first-child': {
      opacity: 1,
    },
  },
  check: {
    marginTop: 2,
    opacity: 0,
    minWidth: 50,
    [theme.breakpoints.down('sm')]: {
      minWidth: 42,
      marginTop: 1,
      '& svg': {
        width: 22,
        height: 22,
      },
    },
  },
  secBtn: {
    color: theme.palette.text.secondary,
    fontSize: '1rem',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
      '& span > span:nth-child(2)': {
        display: 'none',
      },
    },
  },
  mailIcon: {
    '@media (max-width:1560px)': {
      minWidth: 50,
      '& .material-icons-outlined': {
        fontSize: 27,
      },
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: 45,
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  desc: {
    width: 'calc(100%)',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% - 55px)',
      paddingRight: 10,
    },
  },
  detail: {
    [theme.breakpoints.down('xs')]: {
      width: '55px',
      fontSize: 12,
    },
  },
  rctScroll: {
    height: 'calc(100vh - 178px) !important',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 139px) !important',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100vh - 165px) !important',
    },
  },
}));

const InboxListing = () => {
  const history = useHistory();
  const match = useRouteMatch(routes.entity.inboxList);
  const { slug, entity, inboxType } = useParams();
  const { data: staffData } = useAllStaff();
  const classes = useStyles();
  const mutate = useAssignStaffToMessage();
  const [staff, setStaff] = useState({});
  const { data: user } = useUserQuery();

  // To deal with the rules of hooks, we can treat the fetch as a dependant query within react-query by passing in a shouldFetch to our custom hooks

  // We the conditionals on the itemType just like we do for entity, thereby allowing us to re-use this component for every item type

  // console.log("INBOX TYPE IS");
  // console.log(inboxType);

  // Message
  const { isLoading, data } = useMessages(
    match.url.includes('projects') ? match.params.slug : null,
    match.url.includes('properties') ? match.params.slug : null,
    inboxType === 'messages'
  );

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const columns = [
    {
      field: 'readAt',
      flex: 1,
      headerName: 'Status',
      renderCell: (params) => (
        <Box display="flex" flexDirection="column">
          {params?.row?.readAt ? (
            <DraftsIcon />
          ) : (
            <MailOutlineIcon style={{ color: blue[600] }} />
          )}
        </Box>
      ),
    },
    {
      field: 'staff',
      flex: 2,
      headerName: 'Staff',
      renderCell: (params) => (
        <Box display="flex" flexDirection="column">
          <FormControl style={{ width: '100%' }}>
            {user.isStaff ? (
              <Select
                style={{ width: '100%' }}
                labelId={`${params.row.id}-selection`}
                id={`${params.row.id}-selection`}
                defaultValue=""
                value={params.row.staff?.id || staff[params.row.id || '']}
                name="staff"
                label="staff"
                onChange={(evt) => {
                  evt.preventDefault();
                  mutate({
                    messageId: params.row.id,
                    staffId: evt.target.value,
                  });
                  const key = params.row.id;
                  setStaff({ ...staff, key: evt.target.value });
                }}
              >
                {staffData?.map((staff) => (
                  <MenuItem key={`${params.id}-${staff.id}`} value={staff.id}>
                    <Avatar style={{ marginRight: 4 }}>
                      {staff.firstName[0]}
                      {staff.lastName[0]}
                    </Avatar>
                    {staff.firstName} {staff.lastName}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <Avatar style={{ marginRight: 4 }}>CS</Avatar>
            )}
          </FormControl>
        </Box>
      ),
    },
    { field: 'id', headerName: 'ID', hide: true },
    {
      field: 'lotNumber',
      flex: 2,
      headerName: '#',
      renderCell: (params) => (
        <Box display="flex" flexDirection="column">
          <Typography
            variant="subtitle1"
            style={!params?.row?.readAt ? { color: blue[600] } : null}
          >
            {params?.row?.lotNumber} {params?.row?.block ? params?.row?.block : ""}
          </Typography>
        </Box>
      ),
    },
    {
      flex: 5,
      field: 'subject',
      headerName: 'Subject',
      // valueFormatter: (params) => params?.row??.latestMessage?.subject,
      renderCell: (params) => (
        <Box display="flex" flexDirection="column">
          <Box mb={1}>
            <Typography
              variant="subtitle1"
              style={!params?.row?.readAt ? { color: blue[600] } : null}
            >
              {params?.row?.subject}
            </Typography>
          </Box>
          <Typography variant="caption">{params?.row?.cleanedBody}</Typography>
        </Box>
      ),
    },
    {
      flex: 2,
      field: 'sender',
      headerName: 'From',
      renderCell: (params) => (
        <Box display="flex" flexDirection="column">
          <Box mb={1}>
            <Typography
              variant="subtitle1"
              style={!params?.row?.readAt ? { color: blue[600] } : null}
            >
              {params?.row?.sender.name}
            </Typography>
          </Box>
          <Typography variant="caption">{params?.row?.sender.email}</Typography>
        </Box>
      ),
    },
    {
      flex: 2,
      field: 'sentAt',
      headerName: 'Sent At',
      renderCell: (params) => (
        <Box display="flex" flexDirection="column">
          <Box mb={1}>
            <Typography variant="subtitle1">
              <Moment
                style={!params?.row?.readAt ? { color: blue[600] } : null}
                date={params?.row?.created}
                format="MMM DD YYYY"
              />
            </Typography>
          </Box>
          <Typography variant="caption">
            <Moment
              style={!params?.row?.readAt ? { color: blue[600] } : null}
              date={params?.row?.created}
              format="hh:mm A"
            />
          </Typography>
        </Box>
      ),
    },
  ];

  return (
    <Box mb={2}>
      {data && (
        <DataGrid
          className={classes.table}
          rows={data}
          columns={columns}
          rowHeight={60}
          autoHeight
          onCellClick={(params) => {
            if (params.field !== 'staff') {
              const goto = generatePath(routes.entity.inboxDetail, {
                entity,
                slug,
                inboxType: 'messages',
                id: params.row.rootId,
              });
              history.push(goto);
            }
          }}
        />
      )}
    </Box>
  );
};

export default InboxListing;
