/**
 * Rct Theme Provider
 */
import { ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { useSelector } from 'react-redux';
// App locale
import AppLocale from '../lang';
// themes
import darkTheme from '../themes/DarkTheme';
import lightTheme from '../themes/LightTheme';

function HulkThemeProvider(props) {
  const settings = useSelector((state) => state.settings);
  const { children } = props;
  const { locale, isDarkModeActive, isRtlActive } = settings;
  const currentAppLocale = AppLocale[locale.locale];
  let theme = '';
  if (isDarkModeActive) {
    theme = darkTheme;
  } else {
    theme = lightTheme;
  }

  if (isRtlActive) {
    document.getElementsByTagName('BODY')[0].setAttribute('dir', 'rtl');
    theme.direction = 'rtl';
  } else {
    document.getElementsByTagName('BODY')[0].setAttribute('dir', 'ltr');
    // document.getElementsByTagName("BODY")[0].removeAttribute("dir");
    theme.direction = 'ltr';
  }
  return (
    <ThemeProvider theme={theme}>
      {/* <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      > */}
      <>{children}</>
      {/* </IntlProvider> */}
    </ThemeProvider>
  );
}

export default HulkThemeProvider;
