import RequestContext from 'contexts/request';
import { gql } from 'graphql-request';
import { useContext } from 'react';
import { useMutation } from 'react-query';

const MUTATION = gql`
  mutation assignStaff($messageId: String!, $staffId: String!) {
    assignStaff(messageId: $messageId, staffId: $staffId) {
      success
    }
  }
`;

const assignStaffToMessage = (client) => async (variables) => {
  const data = await client.request(MUTATION, variables);
  return data.success;
};

const useAssignStaffToMessage = () => {
  const { client } = useContext(RequestContext);
  const { mutate } = useMutation(assignStaffToMessage(client));
  return mutate;
};

export default useAssignStaffToMessage;
