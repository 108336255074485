import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import AddCommentIcon from '@material-ui/icons/AddComment';
import BrushIcon from '@material-ui/icons/Brush';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DescriptionIcon from '@material-ui/icons/Description';
import EventIcon from '@material-ui/icons/Event';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HouseIcon from '@material-ui/icons/House';
import ImageIcon from '@material-ui/icons/Image';
import LanguageIcon from '@material-ui/icons/Language';
import MailIcon from '@material-ui/icons/Mail';
import useProjects from 'api/useProjects';
import { useUserQuery } from 'api/useUser';
import React from 'react';
import { generatePath, Link, useRouteMatch } from 'react-router-dom';
import routes from '../../../routes';

const useAccordionStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));
const useListItemTextStyles = makeStyles((theme) => ({
  primary: {
    color: 'white',
  },
}));
const useListItemIconStyles = makeStyles((theme) => ({
  root: {
    color: 'white',
    minWidth: '40px',
  },
}));

const FlatNavItem = ({ icon, label, to, disabled, ...rest }) => {
  const textClasses = useListItemTextStyles();
  const iconClasses = useListItemIconStyles();
  const DynLink = (props) =>
    to && to.match(/^http/) ? (
      <a disabled href={to} {...props} />
    ) : (
      <Link disabled to={to} {...props} />
    );
  return (
    <DynLink>
      <ListItem disableRipple button {...rest}>
        <ListItemIcon classes={iconClasses}>{icon}</ListItemIcon>
        <ListItemText classes={textClasses}>{label}</ListItemText>
      </ListItem>
    </DynLink>
  );
};

const AccordionNavItem = ({ icon, label, children }) => {
  const textClasses = useListItemTextStyles();
  const iconClasses = useListItemIconStyles();
  const accordionClasses = useAccordionStyles();
  return (
    <ListItem disableRipple>
      <Accordion
        square
        classes={accordionClasses}
        style={{ boxShadow: 'none' }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          style={{ padding: 0 }}
        >
          {icon && <ListItemIcon classes={iconClasses}>{icon}</ListItemIcon>}
          <ListItemText classes={textClasses}>{label}</ListItemText>
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0 }}>{children}</AccordionDetails>
      </Accordion>
    </ListItem>
  );
};

const ProjectsNav = () => {
  const projects = useProjects();
  return (
    <AccordionNavItem icon={<DashboardIcon />} label="Projects">
      <List style={{ padding: 0 }}>
        {projects?.map(({ name, slug }) => (
          <FlatNavItem
            disableGutters
            dense
            label={name}
            to={`/projects/${slug}`}
          />
        ))}
      </List>
    </AccordionNavItem>
  );
};

const DesignStudioNav = (props) => {
  const { url } = props;
  return (
    <FlatNavItem
      disabled={!url}
      to={url}
      style={{ height: '72px' }}
      icon={<BrushIcon />}
      label="Virtual Design Studio"
    />
  );
};

const TarionNav = () => {
  return (
    <FlatNavItem
      to="https://myhome.tarion.com/hop"
      style={{ height: '72px' }}
      icon={<LanguageIcon />}
      label="Tarion"
    />
  );
};

const SidebarContent = () => {
  const route = useRouteMatch('/:entity(properties|projects)/:slug/:rest(.*)?');
  const slug = route ? route.params.slug : null;
  const entity = route ? route.params.entity : null;
  const { data: user, error, isLoading, isError } = useUserQuery();
  const iconClasses = useListItemIconStyles();
  const textClasses = useListItemTextStyles();

  let derivedEntity;
  let derivedSlug;

  if (slug && entity) {
    derivedEntity = entity;
    derivedSlug = slug;
  } else if (user) {
    if (user.isStaff) {
      derivedEntity = 'projects';
      derivedSlug = user.projects[0].slug;
    } else {
      derivedEntity = 'properties';
      derivedSlug = user.properties[0].slug;
    }
  }

  let dsUrl;
  if (derivedEntity == 'projects') {
    const idx = user.projects?.map((x) => x.slug).indexOf(slug);
    dsUrl = idx > -1 ? user.projects[idx].interactiveAbodeUrl : null;
  } else if (derivedEntity == 'properties') {
    const idx = user.properties?.map((x) => x.slug).indexOf(slug);
    dsUrl = idx > -1 ? user.properties[idx].interactiveAbodeUrl : null;
  }

  // debugger;

  return (
    <div>
      <List className="menu-wrap" style={{ padding: 0 }}>
        {user.isStaff ? (
          <ProjectsNav />
        ) : (
          <AccordionNavItem icon={<HouseIcon />} label="Properties">
            <List style={{ padding: 0 }}>
              {user.properties?.map((prop) => (
                <FlatNavItem
                  key={prop.slug}
                  disableGutters
                  dense
                  label={`${prop?.project}: ${prop?.isCondo ? 'Unit' : 'Lot'} ${
                    prop?.lotNumber
                  }`}
                  to={generatePath(routes.entity.detail, {
                    entity: 'properties',
                    slug: derivedSlug,
                  })}
                />
              ))}
            </List>
          </AccordionNavItem>
        )}

        {derivedSlug ? (
          <>
            {/* Composer */}
            <FlatNavItem
              style={{ height: '72px' }}
              icon={<AddCommentIcon />}
              label="Composer"
              to={generatePath(routes.composer.base)}
            />

            {/* Messages */}
            <FlatNavItem
              style={{ height: '72px' }}
              icon={<MailIcon />}
              label="Messages"
              to={generatePath(routes.entity.inboxList, {
                entity: derivedEntity,
                slug: derivedSlug,
                inboxType: 'messages',
              })}
            />

            {/* Photos */}
            <FlatNavItem
              style={{ height: '72px' }}
              icon={<ImageIcon />}
              label="Photos"
              to={generatePath(routes.entity.photos, {
                entity: derivedEntity,
                slug: derivedSlug,
                inboxType: 'photos',
              })}
            />

            {/* Documents */}
            <FlatNavItem
              style={{ height: '72px' }}
              icon={<DescriptionIcon />}
              label="Documents"
              to={generatePath(routes.entity.pdfs, {
                entity: derivedEntity,
                slug: derivedSlug,
                inboxType: 'documents',
              })}
            />

            {/* Events */}
            <FlatNavItem
              style={{ height: '72px' }}
              icon={<EventIcon />}
              label="Events"
              to={generatePath(routes.entity.events, {
                entity: derivedEntity,
                slug: derivedSlug,
                inboxType: 'events',
              })}
            />
          </>
        ) : null}

        {/* Design Studio */}
        {dsUrl && <DesignStudioNav url={dsUrl} />}

        {/* Tarion */}
        <TarionNav />
      </List>
    </div>
  );
};

export default SidebarContent;
