/**
 *  App.js :: contains main layout of APP.
 */
import { CssBaseline } from '@material-ui/core';
import { useUserQuery } from 'api/useUser';
import HulkAppLayout from 'components/HulkAppLayout';
import EntitySelect from 'components/SelectProject';
import React, { useEffect, useState } from 'react';
import { NotificationContainer } from 'react-notifications';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import Composer from 'routes/ComposeMessage';
import Files from 'routes/Documents';
import InboxDetail from 'routes/Documents/components/InboxDetail';
import InboxListing from 'routes/Documents/components/MessageListing';
import PdfTable from 'routes/Documents/components/PdfTable';
import PhotoGrid from 'routes/Documents/components/PhotoGrid';
import Events from 'routes/Events';
import ProjectDashboard from 'routes/Projects/Modern';
import PropertyDashboard from 'routes/Properties/Modern';
import Settings from 'routes/UserSettings';
import routes from '../routes';
import RecoverPassword from './ForgotPassword2';
import HulkThemeProvider from './HulkThemeProvider';
// APP signin and signup imports
import Login from './Login';
import NewPassword from './NewPassword';

const AuthRoutes = ({ user, children }) => {
  // console.log(user);
  const { boot, shutdown, hide, show, update } = useIntercom();
  if (user) {
    const userData = {
      name: `${user?.firstName} ${user?.lastName}`,
      email: user?.email,
      user_id: user?.id,
      customAttributes: {
        phone: user?.phone,
      },
    };
    update(userData);
  }
  return <Route>{user ? children : <Redirect to={routes.auth.login} />}</Route>;
};

function App(props) {
  const { isLoading, data } = useUserQuery();

  const [ready, setReady] = useState(false);
  useEffect(() => {
    if (!isLoading) {
      setReady(true);
    }
  }, [isLoading]);

  if (!ready) {
    return null;
  }

  const user = data;
  // console.log(routes);
  // debugger;
  return (
    <HulkThemeProvider>
      <CssBaseline />
      <NotificationContainer />
      <Switch>
        {/* Login */}
        <Route path={[routes.auth.login]} component={Login} />

        {/* Set Password */}
        <Route
          // exact
          path={[routes.auth.password.setNew]}
          component={NewPassword}
        />

        {/* Reset Password */}
        <Route
          // exact
          path={[routes.auth.password.reset]}
          component={RecoverPassword}
        />

        <AuthRoutes user={user}>
          <HulkAppLayout>
            <Switch>
              {/* Composer */}
              <Route
                exact
                path={[routes.composer.uploads, routes.composer.base]}
                component={Composer}
              />

              {/* Entity Routes */}
              <Route path={Object.values(routes.entity)}>
                {/* Events */}
                <Route
                  path={[routes.entity.eventList, routes.entity.eventDetail]}
                  component={Events}
                />

                <Route
                  exact
                  path={[routes.entity.photos]}
                  component={PhotoGrid}
                />

                <Route exact path={[routes.entity.pdfs]} component={PdfTable} />

                {/* Files */}
                <Route
                  path={[routes.entity.fileList, routes.entity.fileDetail]}
                  component={Files}
                />

                {/* Messages */}
                <Route
                  exact
                  path={[routes.entity.inboxDetail]}
                  component={InboxDetail}
                />

                <Route
                  exact
                  path={[routes.entity.inboxList]}
                  component={InboxListing}
                />

                {/* Entity Dashboards */}
                <Route
                  exact
                  path={[routes.entity.dashboard]}
                  component={EntitySelect}
                ></Route>

                {/* Entity Details */}
                <Route
                  exact
                  path={routes.entity.detail}
                  render={(props) => {
                    const { match } = props;
                    if (match.params.entity === 'projects') {
                      return <ProjectDashboard />;
                    } else {
                      return <PropertyDashboard />;
                    }
                  }}
                ></Route>
              </Route>

              {/* Redirect to the respective pages for staff and purchasers on login */}
              <Route path="/">
                <Redirect to={user?.isStaff ? '/projects' : '/properties'} />
              </Route>

              <Route path="/settings" component={Settings} />
            </Switch>
          </HulkAppLayout>
        </AuthRoutes>
      </Switch>
    </HulkThemeProvider>
  );
}

export default App;
