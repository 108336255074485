import RequestContext from 'contexts/request';
import { gql } from 'graphql-request';
import { useContext } from 'react';
import { useQuery } from 'react-query';

// TODO: need fields for unread messages and events

const QUERY = gql`
  query {
    myUser {
      id
      name
      firstName
      lastName
      isStaff
      email
      phone
      properties {
        isCondo
        id
        slug
        project
        lotNumber
        block
        interactiveAbodeUrl
        avatarCdnUrl
      }
      projects {
        id
        slug
        name
        avatarCdnUrl
        avatarUuid
        avatarToken
        interactiveAbodeUrl
        calendlyEvents {
          name
          active
          duration
          schedulingUrl
        }
      }
    }
  }
`;

const requestUser = (client) => async () => {
  const data = await client.request(QUERY);
  return data.myUser;
};

const useUser = () => {
  const { client, auth } = useContext(RequestContext);
  const response = useQuery(['myUser', auth?.token], requestUser(client));
  return response.data;
};

export const useUserQuery = () => {
  const { client, auth } = useContext(RequestContext);
  const response = useQuery(['myUser', auth?.token], requestUser(client), {
    retry: false,
  });
  return response;
};

export default useUser;
