import RequestContext from 'contexts/request';
import { gql } from 'graphql-request';
import { useContext } from 'react';
import { useMutation } from 'react-query';

const MUTATION = gql`
  mutation (
    $type: String!
    $bytes: Int!
    $title: String!
    $url: String!
    $properties: [String!]!
  ) {
    uploadDocument(
      type: $type
      bytes: $bytes
      title: $title
      cdnUrl: $url
      properties: $properties
    ) {
      success
    }
  }
`;

const requestUploadDocument = (type) => (client) => async (variables) => {
  const data = await client.request(MUTATION, { type, ...variables });
  return data.success;
};

export const useUploadDocument = () => {
  const { client } = useContext(RequestContext);
  const { mutate } = useMutation(requestUploadDocument('OTHER')(client));
  return mutate;
};

export const useUploadVideo = () => {
  const { client } = useContext(RequestContext);
  const { mutate } = useMutation(requestUploadDocument('VIDEO')(client));
  return mutate;
};

export const useUploadPdf = () => {
  const { client } = useContext(RequestContext);
  const { mutate } = useMutation(requestUploadDocument('PDF')(client));
  return mutate;
};

export default useUploadDocument;
