/**
 * Forget Password
 */
import { Box, Button, Link, TextField, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import useResetPassword from 'api/useResetPassword';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import routes from '../../routes';

const baseBackground =
  process.env.REACT_APP_BACKGROUND_URL ||
  'https://ucarecdn.com/0dc54812-0181-4a42-89a5-f6603b1797f3';

const baseLogo =
  process.env.REACT_APP_BASELOGO_URL ||
  'https://ucarecdn.com/a1699ac1-3567-4bf8-9a72-d5549ef1f7c7';

function ForgotPassword2(props) {
  const { mutate } = useResetPassword();
  const [email, setEmail] = useState('');
  const [sent, setSent] = useState(false);
  const sendResetRequest = () => {
    mutate({ email }, { onSettled: () => setSent(true) });
  };
  const settings = useSelector((state) => state.settings);
  const { isDarkModeActive } = settings;
  return (
    <div>
      <div className="session-wrapper">
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} sm={12} md={6} lg={3} className="login-wrap">
            <div className="login-wrapper">
              <Box width="100%" maxWidth="480px">
                <div className="session-logo text-center">
                  {isDarkModeActive ? (
                    <img
                      className="img-fluid"
                      alt="img"
                      width="300"
                      src={`${baseLogo}/`}
                    />
                  ) : (
                    <img
                      className="img-fluid"
                      alt="img"
                      width="300"
                      src={`${baseLogo}/`}
                    />
                  )}
                </div>
                <div className="session-title">
                  {!sent ? (
                    <>
                      <Typography align="center" variant="h5">
                        Forgot Your Password?
                      </Typography>
                      <Typography align="center" variant="subtitle2">
                        Not to worry, we got you! let's get you a new password
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography align="center" variant="subtitle2">
                        A password reset email will be sent to this address for
                        any associated accounts.
                      </Typography>
                    </>
                  )}
                </div>
                <form className="login-form">
                  <Box mb={2}>
                    <TextField
                      disabled={sent}
                      required
                      fullWidth
                      id="username"
                      type="email"
                      name="email"
                      label="Email Address"
                      placeholder="Please enter your email address."
                      className=""
                      value={email}
                      onChange={(ev) => setEmail(ev.target.value)}
                    />
                  </Box>
                  <Box py={3}>
                    {!sent ? (
                      <Button
                        color="primary"
                        className="btn-block blockBtn w-100"
                        variant="contained"
                        size="large"
                        onClick={sendResetRequest}
                      >
                        Reset My Password
                      </Button>
                    ) : (
                      <Button
                        color="secondary"
                        className="btn-block blockBtn w-100"
                        variant="contained"
                        size="large"
                        component={Link}
                        href={routes.auth.login}
                      >
                        Back to Login
                      </Button>
                    )}
                  </Box>
                </form>
              </Box>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={9}
            style={{
              backgroundImage: `url(${baseBackground}/-/resize/1200x/-/quality/smart/-/format/auto/-/progressive/yes/)`,
              backgroundSize: 'cover',
              backgroundPosition: 'center right',
            }}
            className="img-session"
          />
        </Grid>
      </div>
    </div>
  );
}

export default ForgotPassword2;
