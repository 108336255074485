import {
  Box,
  Card,
  CardMedia,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import usePhotos from 'api/usePhotos';
import React from 'react';
import { AiOutlineCloudDownload } from 'react-icons/ai';
import { FiEye } from 'react-icons/fi';
import { useParams } from 'react-router-dom';

const PhotoGrid = ({ type }) => {
  const { slug, entity } = useParams();
  const { data, isLoading, isError } = usePhotos(
    'photos',
    entity === 'projects' ? slug : null,
    entity === 'properties' ? slug : null
  );

  const images = data?.filter((x) => x.isImage);
  return (
    <>
      <Box m={2} ml={0}>
        <Box m={2}>
          <Typography variant="h1" style={{ textTransform: 'uppercase' }}>
            Photos
          </Typography>
        </Box>
        <Divider />
        <Box my={2} display="flex">
          <Grid container>
            {images?.map((img) => {
              return (
                <Grid item sm={3}>
                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                    mx={2}
                  >
                    <Box>
                      <Card>
                        <a
                          href={img.secureUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <CardMedia
                            image={`${img.secureUrl}`}
                            style={{ width: 200, minHeight: 200 }}
                            title={img.originalFilename}
                          />
                        </a>
                      </Card>
                    </Box>
                    <Box display="flex">
                      <Box m={1}>
                        <a href={img.secureUrl} target="blank">
                          <FiEye size="2em" />
                        </a>
                      </Box>
                      <Box m={1}>
                        <a href={img.downloadUrl} target="blank">
                          <AiOutlineCloudDownload size="2em" />
                        </a>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default PhotoGrid;
