import RequestContext from 'contexts/request';
import { gql } from 'graphql-request';
import { useContext } from 'react';
import { useMutation } from 'react-query';

const MUTATION = gql`
  mutation (
    $title: String!
    $description: String!
    $location: String!
    $start: DateTime!
    $end: DateTime!
    $properties: [String!]!
    $cc: [String!]!
  ) {
    createEvent(
      title: $title
      description: $description
      location: $location
      start: $start
      end: $end
      properties: $properties
      cc: $cc
    ) {
      success
    }
  }
`;

const requestCreateEvent = (client) => async (variables) => {
  const data = await client.request(MUTATION, variables);
  return data.succeses;
};

const useCreateEvent = () => {
  const { client } = useContext(RequestContext);
  const { mutate } = useMutation(requestCreateEvent(client));
  return mutate;
};

export default useCreateEvent;
