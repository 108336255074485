import RequestContext from 'contexts/request';
import { gql } from 'graphql-request';
import { useContext } from 'react';
import { useMutation } from 'react-query';

const MUTATION = gql`
  mutation ($slug: String!, $owners: [String]!) {
    updateOwners(slug: $slug, owners: $owners) {
      success
    }
  }
`;

const requestUpdateOwners = (client) => async (variables) => {
  const data = await client.request(MUTATION, variables);
  return data.success;
};

const useUpdateOwners = () => {
  const { client } = useContext(RequestContext);
  const { mutate } = useMutation(requestUpdateOwners(client));
  return mutate;
};

export default useUpdateOwners;
