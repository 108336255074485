import { useContext } from 'react';
import { useMutation } from 'react-query';
import { gql } from 'graphql-request';
import RequestContext from 'contexts/request';

const MUTATION = gql`
  mutation ($url: String!, $properties: [String!]!, $title: String!) {
    uploadPhoto(cdnUrl: $url, properties: $properties, title: $title) {
      success
    }
  }
`;

const requestUploadPhoto = (client) => async (variables) => {
  const data = await client.request(MUTATION, variables);
  return data.success;
};

const useUploadPhoto = () => {
  const { client } = useContext(RequestContext);
  const { mutate } = useMutation(requestUploadPhoto(client));
  return mutate;
};

export default useUploadPhoto;
