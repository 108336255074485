import RequestContext from 'contexts/request';
import { gql } from 'graphql-request';
import { useContext } from 'react';
import { useMutation } from 'react-query';

const MUTATION = gql`
  mutation ($id: String!) {
    archiveMessage(id: $id) {
      success
    }
  }
`;

const requestArchiveMessage = (client) => async (variables) => {
  const data = await client.request(MUTATION, variables);
  return data.success;
};

const useArchiveMessage = () => {
  const { client } = useContext(RequestContext);
  return useMutation(requestArchiveMessage(client));
};

export default useArchiveMessage;
