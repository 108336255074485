/* eslint-disable no-use-before-define */
/**
 * Hulk App Layout
 */
import { Box, Drawer, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// Actions
import {
  collapsedSidebarAction,
  darkModeAction,
  horizontalMenuAction,
  miniSidebarAction,
  rtlAction,
} from 'actions';
import clsx from 'clsx';
// Components
import Header from 'components/Header/Header';
import IconSidebar from 'components/IconSidebar';
import Sidebar from 'components/Sidebar';
import React, { useEffect, useRef, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import RingLoader from 'react-spinners/RingLoader';

const drawerWidth = 260;
const baseLogo = 'https://ucarecdn.com/a1699ac1-3567-4bf8-9a72-d5549ef1f7c7';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
  },
  content: {
    paddingTop: 80,
    flexGrow: 1,
    marginLeft: -drawerWidth,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('xs')]: {
      paddingTop: 80,
    },
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  menuButton: {
    color: 'red',
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  bgColor: {
    backgroundColor: theme.palette.primary.main,
    borderRight: '0',
    overflowY: 'hidden',
  },
  drawerPaper: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    borderRight: '0',
    overflowY: 'hidden',
    [theme.breakpoints.up('md')]: {
      position: 'relative',
    },
  },
}));

function HulkAppLayout(props) {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const settings = useSelector((state) => state.settings);
  const dispatch = useDispatch();
  const scrollUp = useRef();
  const location = useLocation();
  const renderPage = () => {
    const { children } = props;
    const { pathname } = location;
    if (
      pathname === '/app/chat' ||
      pathname.startsWith('/app/email') ||
      pathname === '/app/todo' ||
      pathname === '/app/calendar'
    ) {
      return <div className="hulk-page-content">{children}</div>;
    }
    if (settings.isMiniSidebarActive) {
      return <div className="hulk-page-content">{children}</div>;
    }
    return (
      <Scrollbars
        className="hulk-scroll main-content"
        autoHide
        autoHideDuration={100}
        ref={scrollUp}
        style={getScrollBarStyle()}
      >
        <div className="hulk-page-content">{children}</div>
      </Scrollbars>
    );
  };
  useEffect(() => {
    setTimeout(() => {
      if (loading === false && props.location.pathname === '/') {
        document
          .getElementsByClassName('hulk-page-content')[0]
          .classList.add('fadeInUpShorter');
        setTimeout(() => {
          document
            .getElementsByClassName('hulk-page-content')[0]
            .classList.remove('fadeInUpShorter');
        }, 1500);
      }
    }, 2500);
    const {
      isDarkModeActive,
      isHorizontalMenuActive,
      isMiniSidebarActive,
      selectedThemeColor,
    } = settings;
    if (
      location.search === '?darkmode=true&horizontalmenu=true' ||
      location.search === '?horizontalmenu=true&darkmode=true'
    ) {
      dispatch(onToggleHorizontalMenu(true));
      dispatch(onToggleDarkMode(true));
    }
    if (location.search === '?horizontalmenu=true' || isHorizontalMenuActive) {
      dispatch(onToggleHorizontalMenu(true));
    }
    if (location.search === '?darkmode=true' || isDarkModeActive) {
      dispatch(onToggleDarkMode(true));
    }
    if (location.search === '?rtl=true' || isRtlActive) {
      dispatch(onToggleRtl(true));
    }
    if (location.search === '?minisidebar=true' || isMiniSidebarActive) {
      dispatch(onToggleMiniSidebar(true));
    }
    if (selectedThemeColor) {
      // dispatch(chooseTheme(selectedThemeColor));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (loading === true) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.counter]);

  // Scrollbar height
  const getScrollBarStyle = () => {
    if (settings.isHorizontalMenuActive) {
      return {
        height: 'calc(100vh - 115px)',
      };
    }
    return {
      height: 'calc(100vh - 64px)',
    };
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // Function to change the state of collapsed sidebar
  const onToggleNavCollapsed = (isActive) => {
    const val = !settings.navCollapsed;
    dispatch(collapsedSidebarAction(val));
  };

  const onToggleDarkMode = (isTrue) => {
    if (isTrue) {
      document.body.classList.remove('light-theme');
      document.body.classList.add('dark-theme');
    } else {
      document.body.classList.remove('dark-theme');
      document.body.classList.add('light-theme');
    }
    dispatch(darkModeAction(isTrue));
  };

  const onToggleRtl = (isTrue) => {
    if (isTrue) {
      document.body.classList.add('rtl-layout');
    } else {
      document.body.classList.remove('rtl-layout');
    }
    dispatch(rtlAction(isTrue));
  };

  const onToggleMiniSidebar = (isTrue) => {
    if (isTrue) {
      document.body.classList.add('icon-layout-wrap');
      document.body.classList.remove('horizontal-layout');
      dispatch(horizontalMenuAction(false));
      dispatch(miniSidebarAction(true));
      dispatch(collapsedSidebarAction(true));
    } else {
      document.body.classList.remove('icon-layout-wrap');
      dispatch(miniSidebarAction(false));
      if (settings.isHorizontalMenuActive === false) {
        dispatch(collapsedSidebarAction(true));
      }
    }
  };

  const onToggleHorizontalMenu = (isActive) => {
    if (isActive) {
      document.body.classList.add('horizontal-layout');
      onToggleMiniSidebar(false);
      dispatch(horizontalMenuAction(true));
      dispatch(collapsedSidebarAction(false));
    } else {
      if (document.body.classList.contains('horizontal-layout')) {
        document.body.classList.remove('horizontal-layout');
        dispatch(horizontalMenuAction(false));
      }
      if (settings.isMiniSidebarActive === false) {
        dispatch(collapsedSidebarAction(true));
      }
    }
  };

  const {
    navCollapsed,
    isDarkModeActive,
    isMiniSidebarActive,
    isRtlActive,
    isHorizontalMenuActive,
  } = settings;
  return (
    <div>
      {loading === true ? (
        <div id="loading-bg" className="hk-full-loader">
          <div className="text-center">
            <Box mb={3}>
              {isDarkModeActive ? (
                <img
                  alt="site-logo"
                  width="110"
                  height="30"
                  src={`${baseLogo}/-/resize/300x/-/quality/smart/-/format/auto/-/progressive/yes/`}
                />
              ) : (
                <img
                  alt="site-logo"
                  width="110"
                  height="30"
                  src={`${baseLogo}/-/resize/300x/-/quality/smart/-/format/auto/-/progressive/yes/`}
                />
              )}
            </Box>
            <RingLoader />
          </div>
        </div>
      ) : (
        <>
          {isMiniSidebarActive === false ? (
            // Layout One
            <div className={`hk-app-layout ${classes.root}`}>
              <Header
                toggleResponsiveSidebar={handleDrawerToggle}
                open={navCollapsed}
                toggleSidebar={(e) => onToggleNavCollapsed(e)}
                openHorizontal={isHorizontalMenuActive}
              />
              <nav aria-label="menu-sidebar">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden lgUp implementation="css">
                  <Drawer
                    variant="temporary"
                    anchor="left"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    classes={{
                      paper: `${classes.bgColor} ${classes.drawer}`,
                    }}
                    ModalProps={{
                      keepMounted: true, // Better open performance on mobile.
                    }}
                  >
                    <div>
                      <Sidebar closeSidebar={handleDrawerToggle} />
                    </div>
                  </Drawer>
                </Hidden>
                <Hidden
                  mdDown
                  implementation="css"
                  className={clsx(classes.drawer, {
                    [`rtl-sidebar`]: !navCollapsed,
                  })}
                >
                  <Drawer
                    variant="persistent"
                    anchor="left"
                    open={navCollapsed}
                    classes={{
                      paper: ` ${classes.drawerPaper}`,
                    }}
                  >
                    <Sidebar />
                  </Drawer>
                </Hidden>
              </nav>
              <main
                className={clsx(
                  classes.content,
                  {
                    [classes.contentShift]: navCollapsed,
                  },
                  'hk-main'
                )}
              >
                <div className="hk-page">{renderPage()}</div>
              </main>
            </div>
          ) : (
            <div className={`hk-icon-layout ${classes.root}`}>
              <>
                <nav aria-label="menu-sidebar" className="icon-sidebar">
                  {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                  <Hidden lgUp implementation="css">
                    <Drawer
                      variant="temporary"
                      anchor="left"
                      open={mobileOpen}
                      onClose={handleDrawerToggle}
                      classes={{
                        paper: `${classes.bgColor} ${classes.drawer}`,
                      }}
                      ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                      }}
                    >
                      <div>
                        <Sidebar closeSidebar={handleDrawerToggle} />
                      </div>
                    </Drawer>
                  </Hidden>
                  <Hidden
                    mdDown
                    implementation="css"
                    className={`icon-drawer ${classes.drawer}`}
                  >
                    <Drawer
                      variant="persistent"
                      anchor="left"
                      open={navCollapsed}
                      classes={{ paper: classes.drawerPaper }}
                    >
                      <IconSidebar />
                    </Drawer>
                  </Hidden>
                </nav>
                <main
                  className={clsx(
                    classes.content,
                    {
                      [classes.contentShift]: navCollapsed,
                    },
                    'hk-main'
                  )}
                >
                  <Box className="icon-header-layout">
                    <Header
                      toggleResponsiveSidebar={handleDrawerToggle}
                      open={navCollapsed}
                      toggleSidebar={(e) => onToggleNavCollapsed(e)}
                      openHorizontal={isHorizontalMenuActive}
                    />
                  </Box>
                  <div className="hk-page">{renderPage()}</div>
                </main>
              </>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default HulkAppLayout;
