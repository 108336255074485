import {
  Avatar,
  Box,
  Card,
  CardContent,
  Fab,
  Icon,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import React from 'react';
import { generatePath, Link, useParams } from 'react-router-dom';
import routes from '../../../routes';

const useStyles = makeStyles((theme) => ({
  button: {
    fontSize: '1.125rem',
  },
  thumbWrap: {
    lineHeight: 1,
    position: 'relative',
    '& img': {
      height: ({ imgHeight }) => imgHeight,
      objectFit: 'cover',
      width: '100%',
    },
  },
  overlayContent: {
    position: 'absolute',
    top: 19,
    right: 16,
  },
  avatar: {
    width: 45,
    height: 45,
  },
  icon: {
    color: theme.palette.common.white,
    transform: 'rotateY(180deg)',
  },
}));

const UnitCard = (props) => {
  // XXX: our DB has a lot of incomplete addresses, and we didn't plan for that.
  // console.log(avatar);

  const { address, avatarCdnUrl, avatarToken, avatarUuid, owners } = props;

  const { slug, entity } = useParams();

  const displayName =
    entity == 'properties' ? `Lot ${props.lotNumber}` : `${props.name}`;

  const addressLine = address
    ? `${
        address.street && address.street !== ' ' ? address.street + ', ' : ''
      }${address.city ? address.city + ', ' : ''}${
        address.province ? address.province + ', ' : ''
      }${address.postal || ''}`
    : '';
  const classes = useStyles();
  const path = generatePath(routes.entity.detail, {
    entity: entity,
    slug: slug,
  });

  return (
    <Card className="blog-grid-item">
      <Box className={classes.thumbWrap}>
        <Link to={path}>
          <img
            className="img-fluid"
            alt="img"
            height="250"
            style={{ backgroundColor: 'lightgrey' }}
            src={`https://${avatarCdnUrl}/${avatarUuid}/-/resize/680x/-/format/auto/-/quality/smart/-/progressive/yes/?token=${avatarToken}`}
          />
        </Link>
        <Box className={`icon-wrap ${classes.overlayContent}`}>
          <Fab color="primary" component={Link} size="small" to={path}>
            <Icon className={classes.icon}>reply</Icon>
          </Fab>
        </Box>
      </Box>
      <Box>
        <CardContent>
          <Box
            mb={1}
            component={Link}
            to={path}
            fontSize="h6.fontSize"
            fontWeight="500"
          >
            {displayName}
          </Box>
          <Typography variant="body2">
            {addressLine.length > 180
              ? addressLine.substring(0, 80) + '...'
              : addressLine}
          </Typography>
          <Box pt={2} display="flex" alignItems="center">
            <Box>
              <AvatarGroup>
                {owners?.map((owner) => (
                  <Avatar
                    key={owner.name}
                    className={classes.avatar}
                    alt={owner.name}
                    src={owner.avatar}
                  />
                ))}
              </AvatarGroup>
            </Box>
          </Box>
        </CardContent>
      </Box>
    </Card>
  );
};
export default UnitCard;
