import { Box, Divider, IconButton } from '@material-ui/core';
import useMessageThread from 'api/useMessageThread';
import React from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import LoadingSpinner from '../../../components/Loading';
import routes from '../../../routes';
import Message from '../components/message';

const InboxDetail = () => {
  const history = useHistory();
  const { id, slug, entity } = useParams();

  const { data, isLoading } = useMessageThread(id);

  const dataDict = data?.reduce(
    (obj, item) => ((obj[item.id] = item), obj),
    {}
  );

  const topLevel = data?.filter((x) => x.parent == null).map((x) => x.id);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  // if (topLevel) {
  //   debugger;
  // }
  return (
    <>
      <Box pl={{ xs: 6, md: 3 }} pr={3} py={2} display="inline-flex">
        <Box mr={1}>
          <IconButton
            onClick={() => {
              const goto = generatePath(routes.entity.inboxList, {
                entity: entity,
                slug: slug,
                inboxType: 'messages',
              });
              history.push(goto);
            }}
          >
            <Box component="span" className="material-icons">
              arrow_back
            </Box>
          </IconButton>
        </Box>
        {/* <Box mr={1}>
          <IconButton>
            <Box component="span" className="material-icons">
              folder_open
            </Box>
          </IconButton>
        </Box>
        <Box mr={1}>
          <IconButton>
            <Box component="span" className="material-icons">
              error_outline
            </Box>
          </IconButton>
        </Box>
        <Box>
          <IconButton>
            <Box component="span" className="material-icons-outlined">
              delete
            </Box>
          </IconButton>
        </Box> */}
      </Box>
      <Divider />
      {!isLoading &&
        dataDict &&
        topLevel.map((id) => {
          return (
            <>
              <Message key={id} email={dataDict[id]} dataDict={dataDict} />
            </>
          );
        })}
    </>
  );
};

export default InboxDetail;
