import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SidebarContent from 'components/simple/SidebarContent';
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { Link } from 'react-router-dom';

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.primary.main,
    borderRight: '0',
    overflowY: 'hidden',
  },
  drawerHeader: {
    display: 'flex',
    borderBottom: '1px solid #404854',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  dFlex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  smallBtn: {
    padding: '2px 5px',
    fontSize: '0.8rem',
  },
}));

export default function Sidebar(props) {
  const classes = useStyles();
  const { closeSidebar } = props;

  const logo =
    process.env.REACT_APP_LOGO ||
    'https://ucarecdn.com/532f3f27-e33a-432d-8fe0-38cdb6fc9950/nationalhomeslogo.jpg';

  return (
    <div>
      <div className="sidebar-wrap h-100">
        <div className={classes.drawerHeader} style={{ minHeight: '80px' }}>
          <Box className="site-logo" display="inline-flex" alignItems="center">
            <Box
              component={Link}
              to="/"
              className="logo-mini"
              lineHeight={0.8}
              style={{ background: 'white' }}
              p={1}
            >
              <img src={logo} alt="site-logo" height="48" />
            </Box>
          </Box>
        </div>
        <Scrollbars
          className="hulk-scroll"
          autoHide
          autoHideDuration={100}
          style={{ height: '100vh' }}
        >
          <SidebarContent closeSidebar={closeSidebar} />
        </Scrollbars>
      </div>
    </div>
  );
}
