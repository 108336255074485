export default [
  {
    viewBy: 'View by Status',
    links: [
      {
        id: 1,
        name: 'module.inbox',
        icon: 'inbox',
        path: 'inbox',
      },
      {
        id: 2,
        name: 'module.drafts',
        icon: 'insert_drive_file',
        path: 'drafts',
      },
      {
        id: 3,
        name: 'module.sent',
        icon: 'send',
        path: 'sent',
      },
    ],
  },
];
