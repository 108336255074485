import React, { useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

const SelectedPropertyContext = React.createContext([null, () => null]);

export const SelectedPropertyProvider = (props) => {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch('/properties/:slug?');
  const [slug, setSlug] = useState(match?.params?.slug);

  // useEffect(() => {
  //   if (slug && match && slug !== match?.params?.slug) {
  //     history.push(
  //       location?.pathname?.replace(match?.url, `/properties/${slug}`)
  //     );
  //   }
  // }, [slug, match]);

  return (
    <SelectedPropertyContext.Provider value={[slug, setSlug]} {...props} />
  );
};

export default SelectedPropertyContext;
